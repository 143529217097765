import React, { useEffect, useRef, useState, useContext } from 'react'
import hostlink from '../Hostlink/hostlink';
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import Modal from 'react-modal';
import Loading from './Loading';
import { UserLoginDetails } from '../Hostlink/UserLoginDetails';
const Purchase = () => {
    const today = new Date();
    const [isLoading, setIsLoading] = useState(false);
    const roomCodeRef = useRef(null);
    const [isEditMode, setisEditMode] = useState(false);
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const [LastPurcrate, setLastPurcrate] = useState(0);
    const ITG_rawmeterial = appState?.[0]?.fgcode || 0;

    const usershopidBatchNo = appState?.[0]?.showbatchinpurchase || 0;
    const usershopdeptcode = appState?.[0]?.deptcode || 0;
    const usershopidref = appState?.[0]?.hotelshopid || 0;
    const queryCondition = usershopidref == 0
    ? `${usershopid}`
    : `${usershopidref}`;
    const scrollRef = useRef(null);
    const [itemdetailGridRefresh, setitemdetailGridRefresh] = useState(true)
    const [editDeleteAPi, seteditDeleteAPi] = useState('');
    const [query, setQuery] = useState('');
    const [editblno, seteditblno] = useState(0);
    const [data, setData] = useState([]);
    const [accdata, setaccdata] = useState([])
    const [deptdata, setdeptdata] = useState([])
    const [srvdata, setsrvData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [listVisible, setListVisible] = useState(false);
    const inputRef = useRef(null);
    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [messagedata, setMessagedata] = useState({
        deleteid: "",
        deletemsg: ""
    })
    const { deleteid, deletemsg } = messagedata;


    const handleOpenModal = (e, flname) => {
        setMessagedata({ ...messagedata, deleteid: e, deletemsg: flname })
        setIsModalOpen(true);
    };
    const handleCloseModal = () => { setIsModalOpen(false); };
    const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); };
    const queryConditionItem = usershopidref !== 0 ? `${usershopdeptcode}/storeitem/${ITG_rawmeterial}` : `item/${ITG_rawmeterial}`;
    const fetchData = async () => {
        // const response = await fetch(`${hostlink}/${usershopid}/item`);
        // console.log(`${hostlink}/StockReportNew/${usershopid}/${queryCondition}/${usershopdeptcode}/${today.toISOString().split('T')[0]}/${today.toISOString().split('T')[0]}/0/0`)
        // console.log(`${hostlink}/${queryCondition}/${usershopdeptcode}/storeitem`)
        if(usershopid!=0){
        const response = await fetch(`${hostlink}/${queryCondition}/${queryConditionItem}`);
        // const response = await fetch(`${hostlink}/StockReportNew/${usershopid}/${queryCondition}/${usershopdeptcode}/${today.toISOString().split('T')[0]}/${today.toISOString().split('T')[0]}/0/0`);
        const jsonData = await response.json();
        setData(jsonData);
    }

    };

    const printdata = async (e) => {
        try {
            const response = await axios.get(`${hostlink}/getchehinroomservicePrint/${usershopid}/${e}`);
            const printWindow = window.open('', '_blank');
            if (response && response.data && response.data.length > 0) {
                const printContent = generatePrintContent(response.data);
                printWindow.document.write(printContent);
                printWindow.document.close();
            } else {
                console.error("Empty or invalid response received.");
                return;
            } printWindow.print();
        } catch (error) {
            console.error("Error fetching or printing data:", error);
        }
    };

    const generatePrintContent = (data) => {
        console.log(data)
        const tableInfo = data.length > 0 && data[0] !== null ? `<span style="text-align: end; width:50%;"><p1 style="text-align: end; width:50%;">Room Name : ${data[0][0]}</p1></span>` : '';
        return `<style>*{margin: 0;padding: 0;box-sizing: border-box;}div{width: 3in;}hr{width: 3in;}</style><div style="font-family: 'Bahnschrift Condensed'; width: 3in;"><p1 style="display: block; margin: 0 auto;text-align: center;"> Purchase </p1>
        <img style="display: block; margin: 0 auto;" src="./logo${usershopid}.png" alt="" />
        <h1 style="text-align: center;">${appState?.[0]?.cname || '-'}</h1><hr style="borderTop: 1px solid black; width: 3in;" /><span style="display:flex;"><p1 style="text-align: start; width:50%;">Ord No : ${data.length > 0 ? data[0][13] : ''}</p1>${tableInfo}</p1></span><span style="display:flex; width: 3in;"><p1 style="text-align: start; width:50%;">Ord Date : ${data[0][7] ? new Date(data[0][7]).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"}</p1><p1 style="text-align: end; width:50%;">Ord Time : ${data[0][8] ? new Date(`2022-01-01T${data[0][8]}`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) : "-"}</p1></span><table style="border-Top: 1px solid black; width: 3in;"><tr style="border-bottom: 1px solid black;"><td style="width: 10%;border-bottom: 1px solid black;">SN</td><td style="width: 80%;border-bottom: 1px solid black;">Item Name</td><td style="width: 10%;border-bottom: 1px solid black; text-align: end;">Qty</td></tr>
          ${data.map((x, index) => `<tr style="width: 100%"><td style="width: 10%">${index + 1}</td><td style="width: 80%">${x[1]}</td><td style="width: 10%; text-align: end;">${x[2].toFixed(2)}</td></tr>`).join('')}</table><hr style="borderTop: 1px solid black; width: 3in;" /><span style="display:flex;"><p1 style="text-align: end; width:100%;">Total Qty : ${Number(data.reduce((total, resp) => total + parseFloat(resp[2]), 0)).toFixed(2)}</p1></span><hr style="borderTop: 1px solid black; width: 3in;" />      
        </div>`;
    };
    useEffect(() => {
        fetchData();
    }, [usershopid,queryCondition,usershopdeptcode]);

    const cmdEdit = async (shopvno) => {
        let x = document.getElementById("viewform")
        x.style.display = "None"
        let y = document.getElementById("newform")
        y.style.display = "block"
        setisEditMode(true);
        seteditblno(shopvno)
        await axios.get(`${hostlink}/purcmas/${usershopid}/${shopvno}`)
            .then((resp) => {
                console.log(resp.data[0])
                setitemdetailGrid(resp.data)
                setitemdetail(resp.data[0])
            })
    }
    const cmdDelete = (shopvno) => {

        axios.delete(`${hostlink}/purcmas/${usershopid}/${shopvno}`)
            .then((resp) => {
                toast.success("Delete Sucessfull")
                setTimeout(() => {
                    setitemdetailGridRefresh(true)
                    setIsModalOpen(false);
                }, 1000);
            })
            .catch((resp) => {
                console.log("Something Else")
            })
    }

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setQuery(inputValue);
        filterData(inputValue);
        setListVisible(true);
        setSelectedIndex(-1);
    };

    const filterData = (query) => {
        if (!query) {
            setFilteredData([]);
            return;
        }

        const filtered = data.filter(item => {
            return item.itname && item.itname.toLowerCase().includes(query.toLowerCase());
        }).slice(0, 10); // Limit to first 5 records after filtering

        setFilteredData(filtered);
    };
    const saveroomservice = async (isprint) => {
        document.getElementById("cmdsave").disabled = true
        if (partybldate == "") {
            toast.error("Please Enter Bill Date...")
            document.getElementById("cmdsave").disabled = false
            document.getElementById("partybldate").focus(); return false
            
        }
        if (partyblno == "") {
            toast.error("Please Enter Bill No...")
            document.getElementById("cmdsave").disabled = false
            document.getElementById("partyblno").focus(); return false
        }
        if (deptcode == 0) {
            toast.error("Please Select Department...")
            document.getElementById("cmdsave").disabled = false
            document.getElementById("deptcode").focus(); return false
        }
        if (acccode == 0) {
            toast.error("Please Select Party...")
            document.getElementById("cmdsave").disabled = false
            document.getElementById("acccode").focus(); return false
        }

        if (itemdetailGrid.length === 0) {
            toast.error("Nothing to Save. Add Item...")
            document.getElementById("cmdsave").disabled = false
            document.getElementById("cmdsave").disabled = false
            return false
        }
        setIsLoading(true);
        for (let i = 0; i < itemdetailGrid.length; i++) {
            itemdetailGrid[i].id = '';
            itemdetailGrid[i].shopid = usershopid;
            itemdetailGrid[i].gstamt = (((itemdetailGrid[i].qty * itemdetailGrid[i].rate) - ((itemdetailGrid[i].qty * itemdetailGrid[i].rate) * itemdetailGrid[i].discperc / 100)) * itemdetailGrid[i].gst / 100).toFixed(2);
            itemdetailGrid[i].ittotal = Number((((itemdetailGrid[i].qty * itemdetailGrid[i].rate)) - ((itemdetailGrid[i].qty * itemdetailGrid[i].rate) * itemdetailGrid[i].discperc / 100)) + ((((itemdetailGrid[i].qty * itemdetailGrid[i].rate) - ((itemdetailGrid[i].qty * itemdetailGrid[i].rate) * itemdetailGrid[i].discperc / 100)) * itemdetailGrid[i].gst / 100))).toFixed(2);
            itemdetailGrid[i].totqty = itemdetailGrid.reduce((total, resp) => total + parseFloat(resp.qty), 0);
            itemdetailGrid[i].totgst = Number(itemdetailGrid.reduce((total, resp) => total + parseFloat((((resp.qty * resp.rate) - ((resp.qty * resp.rate) * resp.discperc / 100)) * resp.gst / 100)), 0)).toFixed(2);
            itemdetailGrid[i].discperc = itemdetailGrid[i].discperc;
            itemdetailGrid[i].discamt = (Number(itemdetailGrid[i].qty) * Number(itemdetailGrid[i].rate)) * Number(itemdetailGrid[i].discperc) / 100;
            itemdetailGrid[i].totdiscamt = Number(itemdetailGrid.reduce((total, resp) => total + parseFloat(((((resp.qty * resp.rate) * resp.discperc / 100)))), 0)).toFixed(2);
            itemdetailGrid[i].roundoff = Number((Math.round(itemdetailGrid.reduce((total, resp) => total + parseFloat((Number(((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))) * Number(resp.gst) / 100) + Number((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))))), 0))) - (itemdetailGrid.reduce((total, resp) => total + parseFloat((Number(((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))) * Number(resp.gst) / 100) + Number((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))))), 0))).toFixed(2);
            itemdetailGrid[i].billamt = Math.round(itemdetailGrid.reduce((total, resp) => total + parseFloat((Number(((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))) * Number(resp.gst) / 100) + Number((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))))), 0));
            itemdetailGrid[i].partybldate = partybldate
            itemdetailGrid[i].partyblno = partyblno
            itemdetailGrid[i].acccode = acccode
            itemdetailGrid[i].deptcode = deptcode
        }

        if (isEditMode == true) {
            console.log(`${hostlink}/purcmasedit/${usershopid}/${editblno}`)
            await axios.post(`${hostlink}/purcmasedit/${usershopid}/${editblno}`, itemdetailGrid)
                .then((resp) => {
                    toast.success("Save Sucess..");
                    setInterval(() => {
                        window.location.assign("/Purchase")
                        setIsLoading(false);
                    }, 1000);
                }).catch((resp) => { console.log("Data Not Save..");setIsLoading(false); })
        } else {
            await axios.post(`${hostlink}/purcmas`, itemdetailGrid)
                .then((resp) => {
                    toast.success("Save Sucess..");
                    setInterval(() => {
                        window.location.assign("/Purchase")
                        setIsLoading(false);
                    }, 1000);
                }).catch((resp) => { console.log("Data Not Save..");document.getElementById("cmdsave").disabled = false; setIsLoading(false); })

        }
    }
    
    const handleSelectItem = (item, index) => {
        setSelectedItem(item);
        setQuery(item.itname);
        setListVisible(false);
        setSelectedIndex(index);
    };
    const [itemdetail, setitemdetail] = useState({ partybldate: today.toISOString().split('T')[0], itembatchno: "", itemexp: "", partyblno: "", rawcode: 0, rawname: "", barcode: "", qty: 1, acccode: 0, deptcode: usershopdeptcode, rate: 0, discperc: 0, taxable: 0, gst: 0, gstamt: 0, ittotal: 0, totqty: 0, totgst: 0, totordamt: 0, rcode: "", roomnoview: "" });
    const { rawcode, rawname, barcode, itembatchno, itemexp, qty, rate, discperc, taxable, gst, gstamt, ittotal, partybldate, partyblno, acccode, deptcode } = itemdetail;
    const [itemdetailGrid, setitemdetailGrid] = useState([]);
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    }, [itemdetailGrid]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true)
                const sqlQuery = `SELECT purcrate,  (SELECT rate FROM purcmas p  WHERE p.rawcode = ${rawcode}  AND p.shopid IN (${queryCondition})  ORDER BY partybldate DESC  LIMIT 1)  FROM itemmas  WHERE id = ${rawcode}  AND shopid IN (${queryCondition})`;
                const billResponse = await axios.post(`${hostlink}/api/executeQueryNew`, { sqlQuery });
                
                if (billResponse.data && billResponse.data.length > 0 && Array.isArray(billResponse.data[0])) {
                    const [first, second] = billResponse.data[0]; // Destructure values safely
                    const selectedRate = second === null || second === 0 ? first : second;
                    setLastPurcrate(selectedRate);
                    setitemdetail({ ...itemdetail,rate:Number(selectedRate) });
                    
                    setIsLoading(false)
                } else {
                    setLastPurcrate(0);
                    setitemdetail({ ...itemdetail,rate:0 });
                    
                    setIsLoading(false)
                }
                            } catch (error) {
                                
                setitemdetail({ ...itemdetail,rate:0 });
                console.error("Data Not Found...", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [rawcode, queryCondition, hostlink]); // Ensure dependencies are properly handled
    
    

    useEffect(() => {
        if (usershopid !== 0) {
            console.log("Fetching Data...");
            console.log("usershopdeptcode:", usershopdeptcode);
    
            axios.get(`${hostlink}/${usershopid}/accmas`)
                .then((Response) => setaccdata(Response.data));
    
            axios.get(`${hostlink}/${usershopid}/deptmas`)
                .then((Response) => setdeptdata(Response.data));
    
            axios.get(`${hostlink}/purcmas/${usershopid}/${fromdate}/${todate}`)
                .then((Response) => {
                    setsrvData(Response.data);
                    setitemdetailGridRefresh(false);
    
                    // Update itemdetail AFTER fetching data
                    setitemdetail(prevState => ({
                        ...prevState,
                        deptcode: usershopdeptcode
                    }));
                    
                    console.log("Updated itemdetail:", itemdetail);
                })
                .catch(() => {
                    console.log("Data Not Fetched");
                });
        }
    }, [itemdetailGridRefresh, usershopid, usershopdeptcode]);
    
    
    const handleItemClick = (index) => {
        setSelectedIndex(index);
        handleSelectItem(filteredData[index], index);
        setQuery(filteredData[index].itname);
        setitemdetail({
            ...itemdetail,
            rawcode: filteredData[index].id,
            rawname: filteredData[index].itname,
            barcode: filteredData[index].barcode,
            qty: 1,
            
            discperc: filteredData[index].discperc,
            gst: filteredData[index].gst,
        });
        if(usershopidBatchNo === 0) {
            document.getElementById("qty").focus();
        }else{
            document.getElementById("itembatchno").focus();
        }

    };
    const handleKeyDown = (event) => {
        if (document.getElementById("inputname1111").value == "") {
            return false
        }
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            setSelectedIndex(prevIndex => (prevIndex < filteredData.length - 1 ? prevIndex + 1 : prevIndex));
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            setSelectedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
        } else if (event.key === 'Enter' && selectedIndex !== -1) {
            handleSelectItem(filteredData[selectedIndex], selectedIndex);
            setQuery(filteredData[selectedIndex].itname);
            setitemdetail({
                ...itemdetail,
                rawcode: filteredData[selectedIndex].id,
                rawname: filteredData[selectedIndex].itname,
                barcode: filteredData[selectedIndex].barcode,
                discperc: filteredData[selectedIndex].discperc,
                qty: 1,
                
                gst: filteredData[selectedIndex].gst,
            });
            if(usershopidBatchNo === 0) {
                document.getElementById("qty").focus();
            }else{
                document.getElementById("itembatchno").focus();
            }
            
        }
    };

    const handleFocus = (e) => {
        // setTimeout(() => {
        //     e.target.select(); // Select the input value when it receives focus
        // }, 0);
    };
    const handleKeyDownnext = (event) => {
        if (event.keyCode === 39) {
            event.preventDefault(); // Prevent cursor from moving to the end
            event.target.setSelectionRange(0, event.target.value.length); // Select the input value
        }
        if (event.keyCode === 13 || event.keyCode === 9) { // Check if the Enter key or Tab key is pressed
            event.preventDefault(); // Prevent default Tab behavior
            const interactiveElements = document.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
            const currentIndex = Array.from(interactiveElements).indexOf(event.target); // Get the index of the current interactive element
            if (currentIndex < interactiveElements.length - 1) {
                interactiveElements[currentIndex + 1].focus(); // Focus on the next interactive element
            } else if (event.keyCode === 9) {
                interactiveElements[0].focus();
            }
        }
    };


    const [frmdata, setfrmdata] = useState({
        fromdate: today.toISOString().split('T')[0],
        todate: today.toISOString().split('T')[0],
        status: 3
    });
    const { fromdate, todate, status } = frmdata;
    const handleKeyDownFindItem = (event) => {

        const existingItem = data.find(item => item.barcode === barcode);
        if (existingItem) {
            // console.log("Item found:", existingItem);

            setQuery(existingItem.itname)
            setitemdetail({
                ...itemdetail,
                rawcode: existingItem.id,
                rawname: existingItem.itname,
                barcode: existingItem.barcode,
                discperc: existingItem.discperc,
                qty: 1,
                
                gst: existingItem.gst,
            });
        } else {
            setitemdetail({
                ...itemdetail,
                id: 0,
            });
            return false
        }


        if (event.keyCode === 13 || event.keyCode === 9) {
            const interactiveElements = document.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
            const currentIndex = Array.from(interactiveElements).indexOf(event.target);

            if (currentIndex < interactiveElements.length - 1) {
                interactiveElements[currentIndex + 1].focus();
                console.log("1")

            } else if (event.keyCode === 9) {
                interactiveElements[0].focus();
                console.log("2")
            }
        }
    };
    const [guestdata, setguestdata] = useState({ c_Gname: "", c_GMob: "", c_GAdd: "", c_Grcode: "", c_Grcodename: "", c_GCHID: "" });
    const { c_Gname, c_GMob, c_GAdd, c_Grcode, c_Grcodename, c_GCHID, } = guestdata;
    const onChangevalueRoom = (e, f) => {
        const existingItem = Room.find(item => item[0] === f);
        if (existingItem) {
            console.log("find")

            const updatedItDetails = Room.map(roomdata => {
                if (roomdata[0] === f) {
                    setguestdata({ ...guestdata, c_GCHID: roomdata[1], c_Grcodename: f, c_Gname: roomdata[2], c_GMob: roomdata[3], c_GAdd: roomdata[4], c_Grcode: roomdata[5] })
                    toast.success("Conform Guest Detail..");
                    document.getElementById("inputname1111").focus();
                }
            });

        } else {
            setguestdata({ ...guestdata, c_Gname: "", c_GMob: "", c_GAdd: "", c_Grcode: "" })
            toast.error("Select Room");
        }
    }

    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,<>?=]/g, '');
        setitemdetail({ ...itemdetail, [name]: sanitizedValue });
    }
    const onChangevalueloaddata = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setfrmdata({ ...frmdata, [name]: sanitizedValue });
        setitemdetailGridRefresh(true)
    }
    const [isVisible, setIsVisible] = useState(false);

    const [Room, setRoom] = useState([])
    const [items, setItems] = useState([]);
    const handleDoubleClick = (itemId) => {
        const selectedItem = items.find(item => item.id === itemId);
        if (selectedItem) {
            alert(`Double-clicked on: ${selectedItem.itname}`);
        }
    };
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    // Show button when user scrolls down 400px
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
        // axios.get(`${hostlink}/${usershopid}/item`)
        //     .then((Response) => {
        //         setItems(Response.data)
        //     })
        //     .catch((Response) => {
        //         console.log("Data Not Fatched")
        //     })

        // axios.get(`${hostlink}/${usershopid}/roomservice`)
        //     .then((Response) => {
        //         setRoom(Response.data)
        //     })
        //     .catch((Response) => {
        //         console.log("Data Not Fatched")
        //     })

        const toggleVisibility = () => {
            if (window.pageYOffset > 100) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, [usershopid,usershopdeptcode]);

    const handleDeleteItem = async (id,rate) => {
        const updatedItDetails = itemdetailGrid.filter(item => item.rawcode !== id || item.rate !== rate);
        toast.success("Item deleted");
        setitemdetailGrid(updatedItDetails);
        document.getElementById("inputname1111").focus();
    };

    const handleAddItem = () => {

        if (rawcode == 0) {
            toast.error("Please Select Item..");
            document.getElementById("inputname1111").focus();
            return false
        }
        const existingItem = itemdetailGrid.find(item => item.rawcode === rawcode && item.rate === rate);
        if (existingItem) {
            const updatedItDetails = itemdetailGrid.map(item => {
                if (item.rawcode === rawcode) {
                    return { ...item, qty: Number(Number(item.qty) + Number(qty)).toFixed(2) };
                }
                return item;
            });
            setitemdetailGrid(updatedItDetails);
            toast.success("quantity added..");
            setQuery('')
            setitemdetail({
                ...itemdetail,
                rawcode: 0,
                rawname: "",
                barcode: "",
                qty: "",
                discperc: 0,
                rate: "",
                gst: "",
                itembatchno: "",
                itemexp: "",
            });
            document.getElementById("inputname1111").focus();
        } else {
            setitemdetailGrid([...itemdetailGrid, {
                rawcode: rawcode,
                rawname: rawname,
                barcode: barcode,
                discperc: discperc,
                qty: qty,
                rate: rate,
                gst: gst,
                itembatchno: itembatchno,
                itemexp: itemexp,
            }])
            toast.success("Item added..");
            setQuery('')
            setitemdetail({
                ...itemdetail,
                rawcode: 0,
                rawname: "",
                barcode: "",
                qty: "",
                rate: "",
                gst: "",
                itembatchno: "",
                itemexp: "",
            });
            document.getElementById("inputname1111").focus();
        }
    };
    const cmdNew = () => {
        setisEditMode(false);
        seteditDeleteAPi('')
        let x = document.getElementById("viewform")
        x.style.display = "None"
        let y = document.getElementById("newform")
        y.style.display = "block"
    }
    const searchData = (e) => {
        const searchValue = document.getElementById("searchroom").value.trim();
        // console.log(searchValue)
        // if (searchValue === "") {
        //     axios.get(`${hostlink}/getchehinroomservice/${usershopid}/${fromdate}/${todate}`)
        //         .then((Response) => {
        //             setsrvData(Response.data)
        //             setitemdetailGridRefresh(false)
        //         })
        //         .catch(() => { })
        // } else {
        //     axios.get(`${hostlink}/getchehinroomservice/search/${usershopid}/${fromdate}/${todate}/${searchValue}`)
        //         .then((Response) => {
        //             setsrvData(Response.data)
        //             setitemdetailGridRefresh(false)
        //         })
        //         .catch(() => { })
        // }
    }
    return (


        <div>
            <div id='viewform'>
                <div className='container p-2'>
                    <div className='row p-1'>
                        <div className='col-md-2 p-1'>
                            <h5 className='font-monospace'> <i class="fa-solid fa-cart-shopping" style={{ width: "20px" }}></i> Purchase</h5>
                        </div>
                        <div className='col-md-2 rounded' style={{ display: "flex", alignItems: "center", background: "rgb(245,245,246)", height: "40px" }}>
                            <i class="fa-solid fa-magnifying-glass " ></i> &nbsp;&nbsp; <input type="text" class="" name="searchroom" onChange={(e) => { searchData(e) }} id="searchroom" placeholder="Search Bill No" style={{ background: "rgb(245,245,246)", outline: "none", border: "0", width: "100%" }} /> <br />
                        </div>

                        <div className='col-md-6 align-self-end' style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                            <span className="f-size-on-mobile align-self-end" style={{ verticalAlign: 'middle', height: '100%', }}>
                                <label htmlFor="todate" className="form-label text-center">From : &nbsp;</label>
                            </span>
                            <span> <input type="date" id="fromdate" name="fromdate" onChange={(e) => { onChangevalueloaddata(e) }} value={fromdate} className="form-control" /></span>


                            <span className="f-size-on-mobile align-self-end">
                                <label htmlFor="todate" className="form-label text-center">&nbsp; To : &nbsp;</label>
                            </span>
                            <span>
                                <input type="date" id="todate" name="todate" onChange={(e) => { onChangevalueloaddata(e) }} value={todate} className="form-control" />
                            </span>
                        </div>


                        <div className='col-md-2 text-end'>
                        {(userType === 0 || appState[1]?.[0]?.purchaseentry?.charAt(1) === '1') ? (<button id='cmdnew' onClick={cmdNew} className='btn btn-outline-primary'> <i class="fa-solid fa-plus"></i> &nbsp;Purchase </button>) : null}
                        </div>
                        <div style={{ maxHeight: "80vh", overflow: "auto" }} className='col-12 '>
                            <table className="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col" className="f-size-on-mobile"><span className='hide-on-mobile'>Bill Date</span> <span className='show-on-mobile'>Details</span></th>
                                        <th scope="col" className="hide-on-mobile">Bill No</th>
                                        <th scope="col" className="hide-on-mobile">Party</th>
                                        <th scope="col" className="hide-on-mobile">Department</th>
                                        <th scope="col" className="hide-on-mobile">Bill Amt</th>
                                        <th scope="col" className="f-size-on-mobile text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {srvdata.map((res, x) => (
                                        <tr style={{ verticalAlign: 'middle', height: '100%' }}>
                                            <td className="f-size-on-mobile" style={{ wordWrap: "break-word" }}> <span className='show-on-mobile'><b>Bill Date :</b> </span> {res[1] ? new Date(res[1]).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"} <br />
                                                <div className="detail-on-mobile text-start align-self-start" style={{ fontSize: "12px", color: "blue", alignItems: "start", wordWrap: "break-word" }}>
                                                    {res[2] !== '' && (<>Bill No : {res[2]} <br /></>)}
                                                    {res[10] !== 0 && (<>Bill Amount : {res[3]} <br /></>)} <span style={{ color: "blue" }}> </span>
                                                </div>
                                            </td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[2]}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[4]}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[5]}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[3]}</td>
                                            <td className='text-center' style={{ verticalAlign: 'middle', height: '100%' }}>
                                                {(userType === 0 || appState[1]?.[0]?.purchaseentry?.charAt(2) === '1') ? (<Link to={"/Purchase"} onClick={(e) => { cmdEdit(res[0]) }} className='btn btn-sm btn-outline-dark'><i class="fa-solid fa-pen-to-square"></i><span className="hide-on-mobile" > Edit</span></Link>) : null} &nbsp; <span className="detail-on-mobile"><br /> <br /></span>
                                                {/* <Link className='btn btn-sm btn-outline-primary' onClick={(e) => { printdata(res[11]) }}><i class="fa-solid fa-print"></i> <span className="hide-on-mobile">Print</span></Link> &nbsp; */}
                                                {(userType === 0 || appState[1]?.[0]?.purchaseentry?.charAt(3) === '1') ? (<Link className='btn btn-sm btn-danger' onClick={(e) => { handleOpenModal(res[0], res[2]) }} > <i class="fa-solid fa-trash"></i> <span className="hide-on-mobile"> Delete</span></Link>) : null} &nbsp;
                                                {/* <Link className='btn btn-sm btn-danger' onClick={(e) => { cmdDelete(res[0]) }} > <i class="fa-solid fa-trash"></i> <span className="hide-on-mobile"> Delete</span></Link> &nbsp; */}

                                                {/* onClick={(e) => { handleOpenModal(row.id, row.flname) }} */}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* New Service */}
            <div id='newform' style={{ display: "none" }}>
                <div className='container-fluid py-1 '>
                    <div className='row py-1 shadow rounded' style={{ backgroundImage: 'linear-gradient(to right, #4DD6F4, #ff9999, #4DD6F4)', color: 'white' }}>
                        <div className="col-md-2 align-self-center"><h4> <i class="fa-solid fa-cart-shopping" style={{ width: "20px" }}></i> &nbsp; Purchase</h4></div>
                        <div className="col-2 text-end align-self-center"><label for="inputState" class="form-label"> Bill No :</label> </div>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='partyblno' value={partyblno} class="form-control" id="partyblno" style={{ width: "200px" }} /> <br />
                        <div className="col-1 text-start-0 align-self-center"><label for="inputState" class="form-label"> Bill Date :</label> </div>
                        <input type="date" onChange={(e) => { onChangevalue(e) }} name='partybldate' value={partybldate} class="form-control" id="partybldate" style={{ width: "200px" }} /> <br />
                        <div className="col-1 text-start-0 align-self-center"><label for="inputState" class="form-label"> Department :</label> </div>
                        <div className="col-2 text-start-0 align-self-center">
                            <select id="deptcode" name='deptcode' value={deptcode} class="form-select" disabled>
                                <option selected key={0} value={0}>NA</option>
                                {deptdata.map((x) => {
                                    return (
                                        <option key={x.id} value={x.id}> {x.deptname} </option>
                                    )
                                })}
                            </select>
                        </div>

                    </div>
                    <p></p>
                    <div className="row">
                        <div className="col-md-12">

                            <div className="row">
                                <div className="col-md-2 text-end m-1"><label className='font-monospace' class="form-label">Select Party </label></div>
                                <div className="col-md-4" >
                                    <select id="acccode" name='acccode' value={acccode} onChange={(e) => { onChangevalue(e) }} class="form-select">
                                        <option selected key={0} value={0}>NA</option>
                                        {accdata.map((x) => {
                                            return (
                                                <option key={x.id} value={x.id}> {x.accname} </option>
                                            )
                                        })}
                                    </select>

                                </div>
                                <div className="col-md-2"></div>
                            </div>
                            <br />

                            {usershopidBatchNo === 0 ? (
                                <div className="row py-1">
                                    <div className="col-md-1">
                                    </div>

                                    <div className="col-md-3">
                                    <div className="row">
                                            <div className="col-4">
                                            <label className='font-monospace' class="form-label">Item Name </label>
                                            </div>
                                            <div className="col-8 text-end">
                                            <label className='font-monospace' style={{color:"blue"}} class="form-label">Last Rate : {LastPurcrate}</label>
                                            </div>
                                        </div>
                                        <input type="text" placeholder="Search for items..." class="form-control" value={query} id="inputname1111" onFocus={handleFocus} onChange={handleInputChange} onKeyDown={handleKeyDown} ref={inputRef}  autoComplete='off' /> <br />
                                        {listVisible && filteredData.length > 0 && (
                                            <ul style={{}}>
                                                {filteredData.map((item, index) => (
                                                    <li
                                                        key={item.id}
                                                        onClick={() => handleSelectItem(item, index)}
                                                        onMouseDown={() => handleItemClick(index)}

                                                        className={index === selectedIndex ? 'selected' : ''}
                                                    >
                                                        {/* {item.itname} - ₹{item.lastPurchaseRate} */}
                                                        {item.itname}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}

                                    </div>
                                    <div className="col-md-2">
                                        <label className='font-monospace' class="form-label">Barcode</label>
                                        <input type="text" value={barcode} onKeyDown={handleKeyDownFindItem} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} name='barcode' class="form-control" id="barcode" />
                                    </div>
                                    <div className="col-md-1">
                                        <label className='font-monospace' class="form-label">Qty</label>
                                        <input type="text" value={qty} name='qty' onKeyDown={handleKeyDownnext} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} class="form-control" id="qty" />
                                    </div>
                                    <div className="col-md-1">
                                        <label className='font-monospace' class="form-label">Rate</label>
                                        <input type="text" value={rate} name='rate' onKeyDown={handleKeyDownnext} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} class="form-control" id="rate" />
                                    </div>
                                    <div className="col-md-1">
                                        <label className='font-monospace' class="form-label">Dis %</label>
                                        <input type="text" value={discperc} name='discperc' onKeyDown={handleKeyDownnext} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} class="form-control" id="discperc" />
                                    </div>
                                    <div id='addroomNew' className="col-md-1 text-center">
                                        <label className='font-monospace ' style={{ color: "white" }} class="form-label">-</label> <br />
                                        <button style={{ backgroundImage: 'linear-gradient(to right, #ff9999, #ff9999, #ff9999)', color: 'black', fontSize: "12px", width: "90%", textAlign: "center", justifyContent: "center" }} type="submit" onClick={() => { handleAddItem() }} class="btn btn-info"><i class="fa-solid fa-circle-plus"></i> Add</button>
                                    </div>

                                </div>
                            ) : (
                                <div className="row py-1">
                                    <div className="col-md-3">
                                        
                                        <div className="row">
                                            <div className="col-4">
                                            <label className='font-monospace' class="form-label">Item Name </label>
                                            </div>
                                            <div className="col-8 text-end">
                                            <label className='font-monospace' style={{color:"blue"}} class="form-label">Last Rate : {LastPurcrate}</label>
                                            </div>
                                        </div>
                                        <input type="text" placeholder="Search for items..." class="form-control" value={query} id="inputname1111" onFocus={handleFocus} onChange={handleInputChange} onKeyDown={handleKeyDown} autoComplete='off' ref={inputRef} /> <br />
                                        {listVisible && filteredData.length > 0 && (
                                            <ul style={{}}>
                                                {filteredData.map((item, index) => (
                                                    <li
                                                        key={item.id}
                                                        onClick={() => handleSelectItem(item, index)}
                                                        onMouseDown={() => handleItemClick(index)}

                                                        className={index === selectedIndex ? 'selected' : ''}
                                                    >
                                                        {/* {item.itname} - ₹{item.lastPurchaseRate} */}
                                                        {item.itname}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}

                                    </div>
                                    <div className="col-md-1">
                                        <label className='font-monospace' class="form-label">Barcode</label>
                                        <input type="text" value={barcode} onKeyDown={handleKeyDownFindItem} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} name='barcode' class="form-control" id="barcode" />
                                    </div>
                                    <div className="col-md-2">
                                        <label className='font-monospace' class="form-label">Batch No</label>
                                        <input type="text" value={itembatchno} onKeyDown={handleKeyDownFindItem} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} name='itembatchno' class="form-control" id="itembatchno" />
                                    </div>

                                    <div className="col-md-2">
                                        <label className='font-monospace' class="form-label">Expiry</label>
                                        <input type="date" value={itemexp} onKeyDown={handleKeyDownFindItem} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} name='itemexp' class="form-control" id="itemexp" />
                                    </div>

                                    <div className="col-md-1">
                                        <label className='font-monospace' class="form-label">Qty</label>
                                        <input type="text" value={qty} name='qty' onKeyDown={handleKeyDownnext} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} class="form-control" id="qty" />
                                    </div>
                                    <div className="col-md-1">
                                        <label className='font-monospace' class="form-label">Rate</label>
                                        <input type="text" value={rate} name='rate' onKeyDown={handleKeyDownnext} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} class="form-control" id="rate" />
                                    </div>
                                    <div className="col-md-1">
                                        <label className='font-monospace' class="form-label">Dis %</label>
                                        <input type="text" value={discperc} name='discperc' onKeyDown={handleKeyDownnext} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} class="form-control" id="discperc" />
                                    </div>
                                    <div id='addroomNew' className="col-md-1 text-center">
                                        <label className='font-monospace ' style={{ color: "white" }} class="form-label">-</label> <br />
                                        <button style={{ backgroundImage: 'linear-gradient(to right, #ff9999, #ff9999, #ff9999)', color: 'black', fontSize: "12px", width: "90%", textAlign: "center", justifyContent: "center" }} type="submit" onClick={() => { handleAddItem() }} class="btn btn-info"><i class="fa-solid fa-circle-plus"></i> Add</button>
                                    </div>

                                </div>
                            )}



                            <div className='show-on-mobile row   container' style={{ color: 'black' }}>
                                {itemdetailGrid.map((resp, index) => (
                                    <div key={index} className='row text-center shadow rounded align-self-center p-1 m-0 bg-light border border-1'>
                                        <h6 style={{ "color": "blue", borderBottom: "1px solid black" }}>Item : {resp.rawname}</h6>
                                        <div className='col-9 text-start' style={{ fontSize: "15px", color: "black" }}>
                                            <table style={{ "width": "100%" }}>
                                                <tr><td>Qty </td><td>: {resp.qty}</td></tr>
                                                <tr><td>Rate</td><td>: {resp.rate}</td></tr>
                                                <tr><td>Gst {resp.gst} % </td><td>: {((resp.qty * resp.rate) * resp.gst / 100)}</td></tr>
                                                <tr><td>Total</td><td>: {(resp.qty * resp.rate) + ((resp.qty * resp.rate) * resp.gst / 100)}</td></tr>
                                            </table>
                                        </div>
                                        <div className='col-3 align-self-center'>
                                            <button type="submit" onClick={(e) => { handleDeleteItem(resp.rawcode,resp.rate) }} className="btn btn-outline-danger"> <i className="fa-solid fa-trash"></i></button>
                                        </div>

                                    </div>
                                ))}
                            </div>


                            {usershopidBatchNo === 0 ? (
                                <div>
                                    <div className='hide-on-mobile text-left align-self-center row bg-info p-1 m-0 shadow rounded border border-1' style={{ backgroundImage: 'linear-gradient(to right, #4DD6F4, #ff9999, #4DD6F4)', color: 'black', padding: '20px' }}>
                                        <div className='row text-left align-self-center p-1 m-0 '>
                                            <div className='col-md-3'>Item Name</div>
                                            <div className='col-md-1'>Barcode</div>
                                            <div className='col-md-1 text-end'>Qty</div>
                                            <div className='col-md-1 text-end'>Rate</div>
                                            <div className='col-md-1 text-end'>Dis %</div>
                                            <div className='col-md-1 text-end'>Dis Amt</div>
                                            <div className='col-md-1 text-end'>Gst</div>
                                            <div className='col-md-1 text-end'>Gst Amt</div>
                                            <div className='col-md-1 text-end'>Amount</div>
                                            <div className='col-md-1 text-end'>Action</div>
                                        </div>
                                    </div>
                                    <div className='hide-on-mobile' style={{ maxHeight: "365px", overflow: "auto" }} ref={scrollRef}>
                                        {itemdetailGrid.map((resp, index) => (
                                            <div key={index} className='row text-left p-1 m-0 bg-light border border-1'>
                                                <div className='col-md-3 align-self-center'>{resp.rawname}</div>
                                                <div className='col-md-1 align-self-center'>{resp.barcode}</div>
                                                <div className='col-md-1 align-self-center text-end'>{Number(resp.qty).toFixed(2)}</div>
                                                <div className='col-md-1 align-self-center text-end'>{resp.rate}</div>
                                                <div className='col-md-1 align-self-center text-end'>{resp.discperc}</div>
                                                <div className='col-md-1 align-self-center text-end'>{((resp.qty * resp.rate) * resp.discperc / 100).toFixed(2)}</div>
                                                <div className='col-md-1 align-self-center text-end'>{resp.gst}</div>
                                                <div className='col-md-1 align-self-center text-end'>{(((resp.qty * resp.rate) - ((resp.qty * resp.rate) * resp.discperc / 100)) * resp.gst / 100).toFixed(2)}</div>
                                                <div className='col-md-1 align-self-center text-end'>{Number((((resp.qty * resp.rate)) - ((resp.qty * resp.rate) * resp.discperc / 100)) + ((((resp.qty * resp.rate) - ((resp.qty * resp.rate) * resp.discperc / 100)) * resp.gst / 100))).toFixed(2)}</div>
                                                <div className='col-md-1 align-self-center text-end'>
                                                    <button type="submit" onClick={(e) => { handleDeleteItem(resp.rawcode,resp.rate) }} className="btn btn-outline-danger"> <i className="fa-solid fa-trash"></i></button>
                                                </div>
                                            </div>
                                        ))}
                                        <div className=' bg-dark text-bg-dark row text-left p-1 m-0 bg-light border border-1'>
                                            <div className='col-md-3 align-self-center'></div>
                                            <div className='col-md-1 align-self-center text-end'><b>Total :</b></div>
                                            <div className='col-md-1 align-self-center  text-end'><b>{Number(itemdetailGrid.reduce((total, resp) => total + parseFloat(resp.qty), 0)).toFixed(2)}</b></div>
                                            <div className='col-md-1 align-self-center  text-end'></div>
                                            <div className='col-md-1 align-self-center  text-end'></div>
                                            <div className='col-md-1 align-self-center  text-end'></div>
                                            <div className='col-md-1 align-self-center  text-end'></div>
                                            <div className='col-md-1 align-self-center  text-end'><b>{Number(itemdetailGrid.reduce((total, resp) => total + parseFloat((((resp.qty * resp.rate) - ((resp.qty * resp.rate) * resp.discperc / 100)) * resp.gst / 100)), 0)).toFixed(2)}</b></div>
                                            <div className='col-md-1 align-self-center  text-end'><b>{Number(itemdetailGrid.reduce((total, resp) => total + parseFloat((
                                                Number(((((resp.qty * resp.rate))) -
                                                    (((resp.qty * resp.rate * resp.discperc) / 100))) * Number(resp.gst) / 100) +

                                                Number((((resp.qty * resp.rate))) -
                                                    (((resp.qty * resp.rate * resp.discperc) / 100)))

                                            )), 0)).toFixed(2)}</b></div>
                                            <div className='col-md-1 align-self-center'>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className='hide-on-mobile text-left align-self-center row bg-info p-1 m-0 shadow rounded border border-1' style={{ backgroundImage: 'linear-gradient(to right, #4DD6F4, #ff9999, #4DD6F4)', color: 'black', padding: '20px' }}>
                                        <div className='row text-left align-self-center p-1 m-0 '>
                                            <div className='col-md-3'>Item Name</div>
                                            <div className='col-md-1'>Barcode</div>
                                            <div className='col-md-1'>Batchno</div>
                                            <div className='col-md-1'>Expiry</div>
                                            <div className='col-md-1 text-end'>Qty</div>
                                            <div className='col-md-1 text-end'>Rate</div>
                                            <div className='col-md-1 text-end'>Dis %</div>
                                            <div className='col-md-1 text-end'>Gst</div>
                                            <div className='col-md-1 text-end'>Amount</div>
                                            <div className='col-md-1 text-end'>Action</div>
                                        </div>
                                    </div>
                                    <div className='hide-on-mobile' style={{ maxHeight: "365px", overflow: "auto" }} ref={scrollRef}>
                                        {itemdetailGrid.map((resp, index) => (
                                            <div key={index} className='row text-left p-1 m-0 bg-light border border-1'>
                                                <div className='col-md-3 align-self-center'>{resp.rawname}</div>
                                                <div className='col-md-1 align-self-center'>{resp.barcode}</div>
                                                <div className='col-md-1 align-self-center'>{resp.itembatchno}</div>
                                                <div className='col-md-1 align-self-center'>{resp.itemexp ? new Date(resp.itemexp).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : ""}</div>
                                                <div className='col-md-1 align-self-center text-end'>{Number(resp.qty).toFixed(2)}</div>
                                                <div className='col-md-1 align-self-center text-end'>{resp.rate}</div>
                                                <div className='col-md-1 align-self-center text-end'>{resp.discperc}</div>
                                                <div className='col-md-1 align-self-center text-end'>{resp.gst}</div>
                                                <div className='col-md-1 align-self-center text-end'>{Number((((resp.qty * resp.rate)) - ((resp.qty * resp.rate) * resp.discperc / 100)) + ((((resp.qty * resp.rate) - ((resp.qty * resp.rate) * resp.discperc / 100)) * resp.gst / 100))).toFixed(2)}</div>
                                                <div className='col-md-1 align-self-center text-end'>
                                                    <button type="submit" onClick={(e) => { handleDeleteItem(resp.rawcode,resp.rate) }} className="btn btn-outline-danger"> <i className="fa-solid fa-trash"></i></button>
                                                </div>
                                            </div>
                                        ))}
                                        <div className=' bg-dark text-bg-dark row text-left p-1 m-0 bg-light border border-1'>
                                            <div className='col-md-3 align-self-center'></div>
                                            <div className='col-md-1 align-self-center  text-end'></div>
                                            <div className='col-md-1 align-self-center  text-end'></div>
                                            <div className='col-md-1 align-self-center text-end'><b>Total :</b></div>
                                            <div className='col-md-1 align-self-center  text-end'><b>{Number(itemdetailGrid.reduce((total, resp) => total + parseFloat(resp.qty), 0)).toFixed(2)}</b></div>
                                            
                                            
                                            <div className='col-md-1 align-self-center  text-end'></div>
                                            <div className='col-md-1 align-self-center  text-end'></div>
                                            <div className='col-md-1 align-self-center  text-end'><b>{Number(itemdetailGrid.reduce((total, resp) => total + parseFloat((((resp.qty * resp.rate) - ((resp.qty * resp.rate) * resp.discperc / 100)) * resp.gst / 100)), 0)).toFixed(2)}</b></div>
                                            <div className='col-md-1 align-self-center  text-end'><b>{Number(itemdetailGrid.reduce((total, resp) => total + parseFloat((
                                                Number(((((resp.qty * resp.rate))) -
                                                    (((resp.qty * resp.rate * resp.discperc) / 100))) * Number(resp.gst) / 100) +

                                                Number((((resp.qty * resp.rate))) -
                                                    (((resp.qty * resp.rate * resp.discperc) / 100)))

                                            )), 0)).toFixed(2)}</b></div>
                                            <div className='col-md-1 align-self-center'>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}



                        </div>
                        <div className="col-md-3 ">
                        </div>
                    </div>
                </div>
                <br /><br /><br /><br />
                <div className="parent-container">
                    {/* Fixed element at the bottom */}
                    <div className="fixed-bottom" style={{ position: 'fixed', bottom: 0, width: '100%', backgroundColor: '#fff', borderTop: '1px solid black' }}>

                        <div className="container-fluid" >

                            <div className="row">
                                <div className="col-md-9 f-size-on-mobile text-end p-1" style={{ borderRight: "1px solid black" }}>
                                    <p className='hide-on-mobile m-2'></p>
                                    {/* <button id='cmdsave' onClick={() => { saveroomservice(1) }} class="btn btn-primary">Save & Print</button>  &nbsp; */}
                                    <button id='cmdsave' onClick={() => { saveroomservice(0) }} class="btn btn-primary">Save</button>  &nbsp;
                                    <a href='/Purchase' class="btn btn-outline-primary">Cancel</a>
                                </div>
                                {/* Summary */}
                                <div className="col-md-3 f-size-on-mobile ">
                                    <div className='rounded'>
                                        <div className="row p-1 m-1 f-size-on-mobile">
                                            <div className="col-6 text-end size-on-mobile" style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold', color: "blue" }}> Round-off :</div> <div style={{ fontFamily: 'arial', color: "blue", fontSize: '14px', fontWeight: 'bold' }} className="col-6 text-end">  {Number((Math.round(itemdetailGrid.reduce((total, resp) => total + parseFloat((Number(((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))) * Number(resp.gst) / 100) + Number((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))))), 0))) - (itemdetailGrid.reduce((total, resp) => total + parseFloat((Number(((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))) * Number(resp.gst) / 100) + Number((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))))), 0))).toFixed(2)} </div>
                                            <div className="col-6 text-end size-on-mobile" style={{ fontFamily: 'arial', fontSize: '18px', fontWeight: 'bold', backgroundColor: "black", color: "white" }}> Total :</div> <div style={{ fontFamily: 'arial', backgroundColor: "black", color: "white", fontSize: '16px', fontWeight: 'bold' }} className="col-6 text-end"> {Math.round(itemdetailGrid.reduce((total, resp) => total + parseFloat((Number(((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))) * Number(resp.gst) / 100) + Number((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))))), 0))} </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            </div>
            <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                <div><p>Are you sure you want to delete this <br /> Bill : {deletemsg} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { cmdDelete(deleteid) }}>Yes</button> <button className="no-button" onClick={handleNoClick}>No</button></div></div>
            </Modal>
            <ToastContainer autoClose={1000}></ToastContainer>
            {isLoading && <Loading />}
        </div>
    )
}

export default Purchase