import React, { useEffect, useRef, useState, useContext } from 'react'
import hostlink from '../Hostlink/hostlink';

import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import Modal from 'react-modal';
import Loading from '../Component/Loading';
import { useLocation } from 'react-router-dom';
import numberToWords from 'number-to-words';
import { UserLoginDetails } from '../Hostlink/UserLoginDetails';
const StockTRF = () => {
  const [LastPurcrate, setLastPurcrate] = useState(0);
  const [LastStock, setLastStock] = useState(0);
  const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
  const usershopid = appState?.[0]?.id || 0;
  const usershopFGcode = appState?.[0]?.fgcode || 0;
  const usershopRWcode = appState?.[0]?.rwcode || 0;
  const usershopidref = appState?.[0]?.hotelshopid || 0;
  const usershopdeptcode = appState?.[0]?.deptcode || 0;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const linkpono = queryParams.get('pono');
  const linktrfdeptcode = queryParams.get('todept');
  const [isLoadingPo, setisLoadingPo] = useState(false);
  const areParametersValid = linkpono && linktrfdeptcode;
  useEffect(() => {
    if (linkpono && linktrfdeptcode) {
      // Set itemdetail state with query parameters
      setitemdetail(prevState => ({
        ...prevState,
        pono: linkpono,
        todeptcode: linktrfdeptcode
      }));

      // Call cmdNew() after setting state
      cmdNew();

      // Set isLoadingPo to true to trigger data loading
      setisLoadingPo(true);
    } else {
      console.log('Missing parameters: linkpono or linktrfdeptcode');
    }
  }, [linkpono, linktrfdeptcode, usershopid]);

  useEffect(() => {
    // setitemdetail(prevState => ({
    //   ...prevState,
    //   pono: linkpono,
    //   todeptcode: linktrfdeptcode
    // }));

    // Only load data if isLoadingPo is true
    console.log(linktrfdeptcode)
    console.log("linktrfdeptcode")
    if (isLoadingPo) {
      loaddata();
    }
  }, [isLoadingPo]);


  const [isLoading, setIsLoading] = useState(false);
  const [updategridstock, setupdategridstock] = useState(false);
  const today = new Date();
  const roomCodeRef = useRef(null);
  const [isEditMode, setisEditMode] = useState(false);


  const scrollRef = useRef(null);
  const [itemdetailGridRefresh, setitemdetailGridRefresh] = useState(true)
  const [editDeleteAPi, seteditDeleteAPi] = useState('');
  const [query, setQuery] = useState('');
  const [editblno, seteditblno] = useState(0);
  const [data, setData] = useState([]);
  const [accdata, setaccdata] = useState([])
  const [deptdata, setdeptdata] = useState([])
  const [itemclosingdata, setitemclosingdata] = useState([])
  const [srvdata, setsrvData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [listVisible, setListVisible] = useState(false);
  const inputRef = useRef(null);

  const queryCondition = usershopidref == 0
    ? `${usershopid}`
    : `${usershopidref}`;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messagedata, setMessagedata] = useState({
    deleteid: "",
    deletemsg: ""
  })
  const { deleteid, deletemsg } = messagedata;


  const handleOpenModal = (e, flname) => {
    setMessagedata({ ...messagedata, deleteid: e, deletemsg: flname })
    setIsModalOpen(true);
  };

  const handleCloseModal = () => { setIsModalOpen(false); };
  const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); };

  const queryConditionItem = usershopidref !== 0 ? `${usershopdeptcode}/storeitem` : "item";
  const queryConditionItemRW = usershopidref !== 0 ? `${usershopdeptcode}/itemGRPLoc` : "itemGRP";
  const fetchData = async () => {
    try {
      // console.log(`${hostlink}/StockReportNew/${usershopid}/${queryCondition}/${usershopdeptcode}/${today.toISOString().split('T')[0]}/${today.toISOString().split('T')[0]}/0/0`)
      // const response = await fetch(`${hostlink}/StockReportNew/${usershopid}/${queryCondition}/${usershopdeptcode}/${today.toISOString().split('T')[0]}/${today.toISOString().split('T')[0]}/0/0`);
      const response = await fetch(`${hostlink}/${queryCondition}/${queryConditionItem}`);
      const jsonData = await response.json();
      setData(jsonData);
      setItems(jsonData)

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [usershopid, usershopdeptcode]);

  const cmdEdit = async (shopvno) => {
    let x = document.getElementById("viewform")
    x.style.display = "None"
    let y = document.getElementById("newform")
    y.style.display = "block"
    setisEditMode(true);
    seteditblno(shopvno)
    await axios.get(`${hostlink}/stktrf/${queryCondition}/${shopvno}`)
      .then((resp) => {
        const updatedData = resp.data.map(item => ({
          ...item,
          closingstk: "-",  // Adding closingstk field
        }));
    
        setitemdetailGrid(updatedData);
        // setitemdetailGrid(resp.data)
        setitemdetail(resp.data[0])
        setupdategridstock(true)
      })
  }
  const cmdDelete = (shopvno) => {

    axios.delete(`${hostlink}/stktrf/${queryCondition}/${shopvno}`)
      .then((resp) => {
        toast.success("Delete Sucessfull")
        setTimeout(() => {
          setitemdetailGridRefresh(true)
          setIsModalOpen(false);
        }, 1000);
      })
      .catch((resp) => {
        console.log("Something Else")
      })
  }

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setQuery(inputValue);
    filterData(inputValue);
    setListVisible(true);
    setSelectedIndex(-1);
  };

  const filterData = (query) => {
    if (!query) {
      setFilteredData([]);
      return;
    }

    const filtered = data.filter(item => {
      return item.itname && item.itname.toLowerCase().includes(query.toLowerCase());
    }).slice(0, 5); // Limit to first 5 records after filtering

    setFilteredData(filtered);
  };
  const printdataservice = async (shopvno) => {
    try {
      console.log(`${hostlink}/stktrfReportStorePrint/${queryCondition}/${shopvno}`)
      console.log(`${hostlink}/stktrfReportStorePrint/${queryCondition}/${shopvno}`)
      const response = await axios.get(`${hostlink}/stktrfReportStorePrint/${queryCondition}/${shopvno}`)
      if (response && response.data && response.data.length > 0) {
        const printContent = generatePrintContentService(response.data);
        const data = `1,${hostlink}/stktrfReportStorePrint/${queryCondition}/${shopvno},${printContent}`;
        console.log(data)
        // Check if ReactNativeWebView is available
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(data);
          return false;
        }
        // Create a new print window (iframe instead of new window)
        const printWindow = document.createElement('iframe');
        printWindow.style.position = 'absolute';
        printWindow.style.width = '0';
        printWindow.style.height = '0';
        printWindow.style.border = 'none';
        document.body.appendChild(printWindow);
        const doc = printWindow.contentWindow.document;
        doc.open();
        doc.write(printContent);
        doc.close();

        // Set CSS for printing
        const style = doc.createElement('style');
        style.innerHTML = `
                @media print {
                    body, html {
                        width: 210mm;
                        margin: 2mm; /* Remove margins */
                        padding: 0; /* Remove padding */
                    }
                    * {
                        box-sizing: border-box; /* Include padding and border in element's width and height */
                    }
                    @page {
                        margin: 0; /* Remove margin */
                        size: 210mm 297mm; /* Set size to 80mm width */
                    }
                }
            `;
        doc.head.appendChild(style);

        // Ensure the content is fully loaded before printing
        printWindow.onload = () => {
          const contentHeight = doc.body.scrollHeight;
          console.log("Content height:", contentHeight);
          printWindow.contentWindow.print();
          if (contentHeight > 210) {
            doc.body.innerHTML += '\x1D\x56\x00'; // Manual cut command
          }
        };
      } else {
        console.error("Empty or invalid response received.");
      }
    } catch (error) {
      console.error("Error fetching or printing data:", error);
    }
  };
  const generatePrintContentService = (data) => {
    // console.log(data[0][1]);
    //appState?.[0]?.cname || '-'
    const companyGSTNO = appState?.[0]?.gstno || '-';
    const customergstno = "";

    // Ensure blgstamt is a valid number
    const blgstamt = 0;

    let print_cgst = 0;
    let print_sgst = 0;
    let print_igst = 0;

    // Compare the first two digits of both GST numbers
    if (companyGSTNO.substring(0, 2) === customergstno.substring(0, 2)) {
      print_igst = blgstamt;
    } else {
      print_cgst = (blgstamt / 2).toFixed(2);
      print_sgst = (blgstamt / 2).toFixed(2);
      print_igst = 0;
    }

    // Convert to number format for further usage
    print_cgst = Number(print_cgst);
    print_sgst = Number(print_sgst);
    print_igst = Number(print_igst);

    const billprintlineTC1 = appState?.[0]?.billprintlineTC1 || '-'; const Html_billprintlineTC1 = billprintlineTC1 !== null ? `<b> <p style="text-align: left; font-size: 12px; width:210mm;">${billprintlineTC1}</p> </b>` : '';
    const billprintlineTC2 = appState?.[0]?.billprintlineTC2 || '-'; const Html_billprintlineTC2 = billprintlineTC2 !== null ? `<p style="text-align: left; font-size: 10px; width:210mm;">${billprintlineTC2}</p> ` : '';
    const billprintlineTC3 = appState?.[0]?.billprintlineTC3 || '-'; const Html_billprintlineTC3 = billprintlineTC3 !== null ? `<p style="text-align: left; font-size: 10px; width:210mm;">${billprintlineTC3}</p> ` : '';
    const billprintlineTC4 = appState?.[0]?.billprintlineTC4 || '-'; const Html_billprintlineTC4 = billprintlineTC4 !== null ? `<p style="text-align: left; font-size: 10px; width:210mm;">${billprintlineTC4}</p> ` : '';
    const billprintlineTC5 = appState?.[0]?.billprintlineTC5 || '-'; const Html_billprintlineTC5 = billprintlineTC5 !== null ? `<p style="text-align: left; font-size: 10px; width:210mm;">${billprintlineTC5}</p> ` : '';


    const billprintline1 = appState?.[0]?.billprintline1 || '-'; const billprintline1Html1 = billprintline1 !== null ? `<p style="text-align: center; width:210mm;">${billprintline1}</p>` : '';
    const billprintline2 = appState?.[0]?.billprintline2 || '-'; const billprintline1Html2 = billprintline2 !== null ? `<p style="text-align: center; width:210mm;">${billprintline2}</p>` : '';
    const billprintline3 = appState?.[0]?.billprintline3 || '-'; const billprintline1Html3 = billprintline3 !== null ? `<p style="text-align: center; width:210mm;">${billprintline3}</p>` : '';
    const billprintline4 = appState?.[0]?.billprintline4 || '-'; const billprintline1Html4 = billprintline4 !== null ? `<p style="text-align: center; width:210mm;">${billprintline4}</p>` : '';
    const billprintline5 = appState?.[0]?.billprintline5 || '-'; const billprintline1Html5 = billprintline5 !== null ? `<p style="text-align: center; width:210mm;">${billprintline5}</p>` : '';
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const custdlvdetailHtml = data.length > 0
      ? `` : '';

    // Resulting HTML
    const resultHtml = custdlvdetailHtml !== '' ? `<span style="font-size: 14px;">${custdlvdetailHtml}</span>` : '';

    const Totalbamt = data.reduce((total, resp) => {
      // Ensure resp.rent is a valid number
      const rentValue = parseFloat((resp[0].qty));
      return total + (isNaN(rentValue) ? 0 : rentValue);
    }, 0).toFixed(2);

    const TotalbamtAmt = data.reduce((total, resp) => {
      const rentValue = parseFloat(resp[0].qty * resp[0].rate) || 0; // Parse and handle invalid numbers
      return total + rentValue; // Add rentValue to total
    }, 0).toFixed(2);

    // const Billamtinword = `<p style="width:210mm;"><b>In Word :</b> ${capitalizeFirstLetter(numberToWords.toWords(Number(TotalbamtAmt).toFixed(2)).replace(/,/g, ''))}</p>`;


    return `<style>*{margin: 0;padding: 0;box-sizing: border-box;}div{width: 190mm;}hr{width: 210mm;}</style><div style="font-family: 'Bahnschrift Condensed'; width: 210mm;">
    <h1 style="text-align: center;">${appState?.[0]?.cname || '-'}</h1>
    ${billprintline1Html1}        ${billprintline1Html2}        ${billprintline1Html3}        ${billprintline1Html4}        ${billprintline1Html5}
    <h1 style="text-align: center;">Stock Transfer</h1>

    <h2 style="text-align: center;">From : ${data[0][1]} To ${data[0][2]} </h2>

    <table style="border-Top: 1px solid black; width: 205mm;">
        
        <tr style="border-bottom: 1px solid black;">
        <td style="width: 35%;border-bottom: 1px solid black;">Sr No : ${data[0][0].shopvno}</td>
        <td style="width: 40%;border-bottom: 1px solid black;">PO No : ${data[0][0].pono}</td>
        <td style="width: 30%;border-bottom: 1px solid black;">Date : ${data[0][0].vdate ? new Date(data[0][0].vdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"}</td></tr>
     </table>
    </br>
        <br>
       <b>Item Details</b>

    <table style="border-Top: 1px solid black; width: 205mm;">
    <tr style="border-bottom: 1px solid black;">
    <td style="width: 10%;border-bottom: 1px solid black;">Sr No</td>
    <td style="width: 30%;border-bottom: 1px solid black;">Item Name</td>
    <td style="width: 10%;border-bottom: 1px solid black;">Unit</td>
    <td style="width: 10%;border-bottom: 1px solid black; text-align: end;">Qty</td>
    <td style="width: 10%;border-bottom: 1px solid black; text-align: end;">Rate</td>
    <td style="width: 10%;border-bottom: 1px solid black; text-align: end;">Amount</td>
    </tr>

    ${data.map((x, index) => `<tr style="width: 100%">
        <td style="width: 10%; max-width: 15ch; word-wrap: break-word;">${index + 1} </td>
        <td style="width: 30%;">${x[0].rawname}</td>
        <td style="width: 10%;">${x[3]}</td>
        <td style="width: 10%;  text-align: end;">${x[0].qty}</td>
        <td style="width: 10%;  text-align: end;">${x[0].rate}</td>
        <td style="width: 10%;  text-align: end;">${Number(Number(x[0].rate) * Number(x[0].qty)).toFixed(2)}</td>
      </tr>`).join('')}
      
      
     

      <table style="border-Top: 1px solid black; width: 205mm;">
       <tr style="border-bottom: 1px solid black;">
        <td style="width: 10%;border-bottom: 1px solid black;"></td>
        <td style="width: 10%;border-bottom: 1px solid black; text-align: end;"></td>
        <td style="width: 20%;border-bottom: 1px solid black; text-align: end;"><b>Total</b></td>
        <td style="width: 20%;border-bottom: 1px solid black; text-align: end;"><b>${Number(TotalbamtAmt).toFixed(2)}</b></td>
        </tr>
      </table>
           

      </table>
    </div>`;
  };
  const saveroomservice = async (isprint) => {
    document.getElementById("cmdsave").disabled = true
    document.getElementById("cmdsave1").disabled = true
    if (vdate == "") {
      toast.error("Please Enter Bill Date...")
      document.getElementById("cmdsave").disabled = false
      document.getElementById("cmdsave1").disabled = false
      document.getElementById("vdate").focus(); return false
    }
    if (fromdeptcode == 0) {
      toast.error("Please Select from Department...")
      document.getElementById("cmdsave").disabled = false
      document.getElementById("cmdsave1").disabled = false
      document.getElementById("fromdeptcode").focus(); return false
    }
    if (todeptcode == 0) {
      toast.error("Please Select to Department...")
      document.getElementById("cmdsave").disabled = false
      document.getElementById("cmdsave1").disabled = false
      document.getElementById("todeptcode").focus(); return false
    }

    if (todeptcode == fromdeptcode) {
      toast.error("Both Department is Same...")
      document.getElementById("cmdsave").disabled = false
      document.getElementById("cmdsave1").disabled = false
      document.getElementById("todeptcode").focus(); return false
    }
    if (itemdetailGrid.length === 0) {
      toast.error("Nothing to Save. Add Item...")
      document.getElementById("cmdsave").disabled = false
      document.getElementById("cmdsave1").disabled = false
      return false
    }
    const extractNumber = (value) => {
      if (typeof value === "string") {
        return parseFloat(value.replace(/[^0-9.-]/g, "")) || 0; // Ensure negative values are retained
      }
      return Number(value) || 0;
    };
    // console.log(itemdetailGrid)
    // const negativeStockRows = itemdetailGrid
    //   .filter(row => {
    //     const closingStock = extractNumber(row.closingstk);
    //     const quantity = extractNumber(row.qty);
    //     return (closingStock - quantity) < 0; // No need to check for closingStock >= 0
    //   })
    //   .map(row => row.rawname);

    const negativeStockRows = itemdetailGrid
  .filter(row => {
    if (row.closingstk === "-") return false; // Skip rows where closingstk is "-"
    
    const closingStock = extractNumber(row.closingstk);
    const quantity = extractNumber(row.qty);
    
    return (closingStock - quantity) < 0; // Check for negative stock
  })
  .map(row => row.rawname);


    if (negativeStockRows.length > 0) {
      const names = negativeStockRows.join(", ");
      document.getElementById("cmdsave").disabled = false
      toast.error(`The following rows have a negative closing stock: ${names}. Please correct them.`);
      return false;
    }

    for (let i = 0; i < itemdetailGrid.length; i++) {
      itemdetailGrid[i].id = '';
      itemdetailGrid[i].shopid = queryCondition;
      itemdetailGrid[i].totqty = itemdetailGrid.reduce((total, resp) => total + parseFloat(resp.qty), 0);
      itemdetailGrid[i].vdate = vdate
      itemdetailGrid[i].pono = pono
      itemdetailGrid[i].fromdeptcode = fromdeptcode
      itemdetailGrid[i].todeptcode = todeptcode
    }

    if (isEditMode == true) {
      await axios.post(`${hostlink}/stktrfedit/${usershopid}/${editblno}`, itemdetailGrid)
        .then((resp) => {
          toast.success("Save Sucess..");
          setInterval(() => {
            window.location.assign("/StockTRF")
          }, 1000);
        }).catch((resp) => { console.log("Data Not Save.."); document.getElementById("cmdsave").disabled = false; document.getElementById("cmdsave1").disabled = false })
    } else {
      await axios.post(`${hostlink}/stktrf`, itemdetailGrid)
        .then((resp) => {
          toast.success("Save Sucess..");
          if (isprint === 1) { printdataservice(resp.data); };
          setInterval(() => {
            window.location.assign("/StockTRF")
          }, 1000);
        }).catch((resp) => { console.log("Data Not Save.."); document.getElementById("cmdsave").disabled = false; document.getElementById("cmdsave1").disabled = false })

    }
  }
  const handleSelectItem = (item, index) => {
    setSelectedItem(item);
    setQuery(item.itname);
    setListVisible(false);
    setSelectedIndex(index);
  };
  const [itemdetail, setitemdetail] = useState({ fromdeptcode: usershopdeptcode, pono: "", todeptcode: 0, fgqty: 0, vdate: today.toISOString().split('T')[0], partyblno: "", rawcode: 0, rawname: "", barcode: "", qty: 1, acccode: 0, fgcode: 0, fgqty: 1, deptcode: 0, rate: 0, discperc: 0, taxable: 0, gst: 0, gstamt: 0, ittotal: 0, totqty: 0, totgst: 0, totordamt: 0, rcode: "", roomnoview: "", closingstk: 0 });
  const { rawcode, rawname, barcode, fgcode, todeptcode, pono, fromdeptcode, fgqty, qty, rate, discperc, taxable, gst, gstamt, ittotal, vdate, partyblno, acccode, deptcode, closingstk } = itemdetail;
  const [itemdetailGrid, setitemdetailGrid] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        let sqlQuery
        if (usershopidref == 0) {
          sqlQuery = `SELECT purcrate,  (SELECT rate FROM purcmas p  WHERE p.rawcode = ${rawcode}  AND p.shopid IN (${queryCondition})  ORDER BY partybldate DESC  LIMIT 1) as purcrate,(SELECT sum(qty) FROM stockmaster p WHERE p.rawcode = ${rawcode} AND p.shopid IN (${queryCondition})  AND p.deptcode = ${usershopdeptcode}) +i.opstock AS Stock,(select unitname from unitmas where id = i.unitcode) as unitname  FROM itemmas i  WHERE id = ${rawcode}  AND shopid IN (${queryCondition})`;
        } else {
          sqlQuery = `SELECT purcrate,  (SELECT rate FROM purcmas p  WHERE p.rawcode = ${rawcode}  AND p.shopid IN (${queryCondition})  ORDER BY partybldate DESC  LIMIT 1) as purcrate,(SELECT sum(qty) FROM stockmaster p WHERE p.rawcode = ${rawcode}  AND p.deptcode = ${usershopdeptcode}) AS Stock,(select unitname from unitmas where id = i.unitcode) as unitname  FROM itemmas i  WHERE id = ${rawcode}  AND shopid IN (${queryCondition})`;
        }
        console.log(sqlQuery)
        const billResponse = await axios.post(`${hostlink}/api/executeQueryNew`, { sqlQuery });

        if (billResponse.data && billResponse.data.length > 0 && Array.isArray(billResponse.data[0])) {
          const [first, second] = billResponse.data[0]; // Destructure values safely
          const selectedRate = second === null || second === 0 ? first : second;
          setLastPurcrate(selectedRate);
          setLastStock(Number(billResponse.data[0][2]).toFixed(2) + " " + billResponse.data[0][3]);
          setitemdetail({ ...itemdetail, unitname: billResponse.data[0][3], rate: selectedRate });
          setIsLoading(false)
        } else {
          setIsLoading(false)
          setLastPurcrate(0);
          setLastStock(0);
        }
      } catch (error) {
        console.error("Data Not Found...", error);
      } finally {
        setIsLoading(false)
      }
    };
    fetchData();
  }, [rawcode, queryCondition, hostlink]);
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [itemdetailGrid]);

  useEffect(() => {

    // axios.get(`${hostlink}/StockReport/${usershopid}/${queryCondition}/${fromdeptcode}/${fromdate}/${todate}/${usershopdeptcode == fromdeptcode ? 0 : 1}`)
    //   .then((Response) => { setitemclosingdata(Response.data) })

    axios.get(`${hostlink}/${queryCondition}/deptmas`)
      .then((Response) => { setdeptdata(Response.data) })
    // 
    axios.get(`${hostlink}/api/executeQuery?sqlQuery=select shopvno,vdate,(select deptname from deptmas where id = stktrfmas.fromdeptcode) as fromdept,(select deptname from deptmas where id = stktrfmas.todeptcode) as todept,totqty,pono from stktrfmas where shopid = ${queryCondition} and vdate between '${fromdate}' and '${todate}' and fromdeptcode = ${usershopdeptcode}   and iswastageentry = 0 group by shopvno,vdate order by shopvno desc`)
      .then((Response) => {
        setsrvData(Response.data)
        setitemdetailGridRefresh(false)
      })
      .catch((Response) => {
        console.log("Data Not Fatched")
      })

    setitemdetail(prevState => ({
      ...prevState,
      pono: linkpono,
      todeptcode: linktrfdeptcode || 0,
      fromdeptcode: usershopdeptcode
    }));
    // setitemdetail({
    //   ...itemdetail,
    //   fromdeptcode: usershopdeptcode
    // });
  }, [itemdetailGridRefresh, usershopid]);
  const handleItemClick = (index) => {
    setSelectedIndex(index);
    handleSelectItem(filteredData[index], index);
    setQuery(filteredData[index].itname);
    setitemdetail({
      ...itemdetail,
      rawcode: filteredData[index].id,
      rawname: filteredData[index].itname,
      closingstk: filteredData[index].Stock,
      barcode: filteredData[index].barcode,
      qty: 1,
      rate: filteredData[index].lastPurchaseRate,
      discperc: filteredData[index].discperc,
      gst: filteredData[index].gst,
    });
    document.getElementById("qty").focus();
  };
  const handleKeyDown = (event) => {
    if (document.getElementById("inputname1111").value == "") {
      return false
    }
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      setSelectedIndex(prevIndex => (prevIndex < filteredData.length - 1 ? prevIndex + 1 : prevIndex));
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      setSelectedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    } else if (event.key === 'Enter' && selectedIndex !== -1) {
      handleSelectItem(filteredData[selectedIndex], selectedIndex);
      setQuery(filteredData[selectedIndex].itname);
      setitemdetail({
        ...itemdetail,
        rawcode: filteredData[selectedIndex].id,
        rawname: filteredData[selectedIndex].itname,
        barcode: filteredData[selectedIndex].barcode,
        discperc: filteredData[selectedIndex].discperc,
        closingstk: filteredData[selectedIndex].Stock,
        qty: 1,
        rate: filteredData[selectedIndex].lastPurchaseRate,
        gst: filteredData[selectedIndex].gst,
      });
      document.getElementById("qty").focus();
    }
  };

  const handleFocus = (e) => {
    // setTimeout(() => {
    //     e.target.select(); // Select the input value when it receives focus
    // }, 0);
  };
  const handleKeyDownnext = (event) => {
    if (event.keyCode === 39) {
      event.preventDefault(); // Prevent cursor from moving to the end
      event.target.setSelectionRange(0, event.target.value.length); // Select the input value
    }
    if (event.keyCode === 13 || event.keyCode === 9) { // Check if the Enter key or Tab key is pressed
      event.preventDefault(); // Prevent default Tab behavior
      const interactiveElements = document.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
      const currentIndex = Array.from(interactiveElements).indexOf(event.target); // Get the index of the current interactive element
      if (currentIndex < interactiveElements.length - 1) {
        interactiveElements[currentIndex + 1].focus(); // Focus on the next interactive element
      } else if (event.keyCode === 9) {
        interactiveElements[0].focus();
      }
    }
  };


  const [frmdata, setfrmdata] = useState({
    fromdate: today.toISOString().split('T')[0],
    todate: today.toISOString().split('T')[0],
    status: 3
  });
  const { fromdate, todate, status } = frmdata;
  const handleKeyDownFindItem = (event) => {

    const existingItem = data.find(item => item.barcode === barcode);
    if (existingItem) {
      console.log("Item found:", existingItem);

      setQuery(existingItem.itname)
      setitemdetail({
        ...itemdetail,
        rawcode: existingItem.id,
        rawname: existingItem.itname,
        barcode: existingItem.barcode,
        discperc: existingItem.discperc,
        qty: 1,
        rate: existingItem.restrate,
        closingstk: existingItem.closingstk,
        gst: existingItem.gst,
      });
    } else {
      setitemdetail({
        ...itemdetail,
        id: 0,
      });
      return false
    }


    if (event.keyCode === 13 || event.keyCode === 9) {
      const interactiveElements = document.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
      const currentIndex = Array.from(interactiveElements).indexOf(event.target);

      if (currentIndex < interactiveElements.length - 1) {
        interactiveElements[currentIndex + 1].focus();
        console.log("1")

      } else if (event.keyCode === 9) {
        interactiveElements[0].focus();
        console.log("2")
      }
    }
  };
  const [guestdata, setguestdata] = useState({ c_Gname: "", c_GMob: "", c_GAdd: "", c_Grcode: "", c_Grcodename: "", c_GCHID: "" });
  const { c_Gname, c_GMob, c_GAdd, c_Grcode, c_Grcodename, c_GCHID, } = guestdata;
  const onChangevalueRoom = (e, f) => {
    const existingItem = Room.find(item => item[0] === f);
    if (existingItem) {
      console.log("find")

      const updatedItDetails = Room.map(roomdata => {
        if (roomdata[0] === f) {
          setguestdata({ ...guestdata, c_GCHID: roomdata[1], c_Grcodename: f, c_Gname: roomdata[2], c_GMob: roomdata[3], c_GAdd: roomdata[4], c_Grcode: roomdata[5] })
          toast.success("Conform Guest Detail..");
          document.getElementById("inputname1111").focus();
        }
      });

    } else {
      setguestdata({ ...guestdata, c_Gname: "", c_GMob: "", c_GAdd: "", c_Grcode: "" })
      toast.error("Select Room");
    }
  }

  const onChangevalue = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,<>?=]/g, '');
    setitemdetail({ ...itemdetail, [name]: sanitizedValue });
  }
  const onChangevalueloaddata = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
    setfrmdata({ ...frmdata, [name]: sanitizedValue });
    setitemdetailGridRefresh(true)
  }
  const [isVisible, setIsVisible] = useState(false);

  const [Room, setRoom] = useState([])
  const [items, setItems] = useState([]);
  const handleDoubleClick = (itemId) => {
    const selectedItem = items.find(item => item.id === itemId);
    if (selectedItem) {
      alert(`Double-clicked on: ${selectedItem.itname}`);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  // Show button when user scrolls down 400px
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }

    const toggleVisibility = () => {
      if (window.pageYOffset > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, [usershopid]);

  const handleDeleteItem = async (id) => {
    const updatedItDetails = itemdetailGrid.filter(item => item.rawcode !== id);

    setitemdetailGrid(updatedItDetails);
    document.getElementById("inputname1111").focus();
  };

  const handleAddItem = () => {

    if (rawcode == 0) {
      toast.error("Please Select Item..");
      document.getElementById("inputname1111").focus();
      return false
    }
    const existingItem = itemdetailGrid.find(item => item.rawcode === rawcode);
    if (existingItem) {
      const updatedItDetails = itemdetailGrid.map(item => {
        if (item.rawcode === rawcode) {
          return { ...item, qty: Number(Number(item.qty) + Number(qty)).toFixed(2) };
        }
        return item;
      });
      setitemdetailGrid(updatedItDetails);
      setupdategridstock(true)

      setQuery('')
      setitemdetail({
        ...itemdetail,
        rawcode: 0,
        rawname: "",
        barcode: "",
        qty: "",
        discperc: 0,
        rate: "",
        gst: "",
      });
      document.getElementById("inputname1111").focus();
    } else {
      setitemdetailGrid([...itemdetailGrid, {
        rawcode: rawcode,
        rawname: rawname,
        barcode: barcode,
        discperc: discperc,
        closingstk: LastStock,
        qty: qty,
        rate: rate,
        gst: gst,
      }])
      setupdategridstock(true)

      setQuery('')
      setitemdetail({
        ...itemdetail,
        rawcode: 0,
        rawname: "",
        barcode: "",
        qty: "",
        rate: "",
        gst: "",
      });
      document.getElementById("inputname1111").focus();
    }
  };
  const cmdNew = () => {
    setisEditMode(false);
    seteditDeleteAPi('')
    let x = document.getElementById("viewform")
    x.style.display = "None"
    let y = document.getElementById("newform")
    y.style.display = "block"
  }

  useEffect(() => {

    // const updatedGrid = itemdetailGrid.map(item => {
    //     const correspondingCmbItem = items.find(cmb => cmb.id === item.rawcode);
    //     if (correspondingCmbItem) {
    //         return {
    //             ...item,
    //             closingstk: correspondingCmbItem.Stock || 0,
    //         };
    //     }
    //     return item;
    // });
    // console.log(updatedGrid);
    // setitemdetailGrid(updatedGrid);
    // setupdategridstock(false);

  }, [updategridstock, itemclosingdata, usershopid]);  // Add itemclosingdata as dependency


  const loaddata = async (e) => {
    if (todeptcode == 0) {
      toast.error("Please Select to Department...")
      document.getElementById("todeptcode").focus(); return false
    }
    if (pono == "") {
      toast.error("Please Enter PO No...")
      document.getElementById("pono").focus(); return false
    }
    setIsLoading(true)
    try {
      console.log(`${hostlink}/api/executeQuery?sqlQuery=select vdate from stktrfmas where shopid = ${usershopid} and pono = ${pono}`)
      const isExist = await axios.get(`${hostlink}/api/executeQuery?sqlQuery=select vdate from stktrfmas where shopid = ${usershopid} and pono = ${pono}`);
      if (isExist.data.length !== 0) {
        setIsLoading(false);
        console.log(isExist.data[0])
        toast.error(`PO has already been issued in ${isExist.data[0] ? new Date(isExist.data[0]).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"}`);
        setitemdetail({ ...itemdetail, pono: "", });
        return false
      }

      // const billResponse = await axios.get(`${hostlink}/purchaseorderdept/${usershopid}/${pono}/${todeptcode}`);
      // const response = await fetch(`${hostlink}/StockReportNew/${usershopid}/${queryCondition}/${usershopdeptcode}/${today.toISOString().split('T')[0]}/${today.toISOString().split('T')[0]}/0/0`);
      console.log(`${hostlink}/StockReportNewPOFetch/${usershopid}/${queryCondition}/${usershopdeptcode}/${today.toISOString().split('T')[0]}/${today.toISOString().split('T')[0]}/0/0/${pono}/${todeptcode}`)
      const billResponse = await axios.get(`${hostlink}/StockReportNewPOFetch/${usershopid}/${queryCondition}/${usershopdeptcode}/${today.toISOString().split('T')[0]}/${today.toISOString().split('T')[0]}/0/0/${pono}/${todeptcode}`);
      if (billResponse.data.length == 0) {
        toast.error("Po Not Found...");
        setitemdetail({ ...itemdetail, pono: "", });
      } else {
        setitemdetailGrid(billResponse.data)
        // setupdategridstock(true)
      }
      setIsLoading(false);
    } catch (error) {

      setIsLoading(false)
    }
  };
  const searchData = (e) => {
    const searchValue = document.getElementById("searchroom").value.trim();
    console.log(searchValue)
    if (searchValue === "") {
      axios.get(`${hostlink}/api/executeQuery?sqlQuery=select shopvno,vdate,(select deptname from deptmas where id = stktrfmas.fromdeptcode) as fromdept,(select deptname from deptmas where id = stktrfmas.todeptcode) as todept,totqty,pono from stktrfmas where shopid = ${queryCondition} and vdate between '${fromdate}' and '${todate}' and fromdeptcode = ${usershopdeptcode}   and iswastageentry = 0 group by shopvno,vdate`)
        .then((Response) => {
          setsrvData(Response.data)
          setitemdetailGridRefresh(false)
        })
        .catch(() => { })
    } else {
      axios.get(`${hostlink}/api/executeQuery?sqlQuery=select shopvno,vdate,(select deptname from deptmas where id = stktrfmas.fromdeptcode) as fromdept,(select deptname from deptmas where id = stktrfmas.todeptcode) as todept,totqty,pono from stktrfmas where shopid = ${queryCondition} and fromdeptcode = ${usershopdeptcode}   and iswastageentry = 0 and pono = ${searchValue} group by shopvno,vdate`)
        .then((Response) => {
          setsrvData(Response.data)
          setitemdetailGridRefresh(false)
        })
        .catch(() => { })
    }
  }
  return (


    <div>
      <div id='viewform'>
        <div className='container p-2'>
          <div className='row p-1'>
            <div className='col-md-2 p-1'>
              <h5 className='font-monospace'> <i class="fa-solid fa-right-left" style={{ width: "20px" }}></i> Stock Transfer</h5>
            </div>
            <div className='col-md-2 rounded' style={{ display: "flex", alignItems: "center", background: "rgb(245,245,246)", height: "40px" }}>
              <i class="fa-solid fa-magnifying-glass " ></i> &nbsp;&nbsp; <input type="text" class="" name="searchroom" onChange={(e) => { searchData(e) }} id="searchroom" placeholder="Search V No" style={{ background: "rgb(245,245,246)", outline: "none", border: "0", width: "100%" }} /> <br />
            </div>

            <div className='col-md-6 align-self-end' style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
              <span className="f-size-on-mobile align-self-end" style={{ verticalAlign: 'middle', height: '100%', }}>
                <label htmlFor="todate" className="form-label text-center">From : &nbsp;</label>
              </span>
              <span> <input type="date" id="fromdate" name="fromdate" onChange={(e) => { onChangevalueloaddata(e) }} value={fromdate} className="form-control" /></span>


              <span className="f-size-on-mobile align-self-end">
                <label htmlFor="todate" className="form-label text-center">&nbsp; To : &nbsp;</label>
              </span>
              <span>
                <input type="date" id="todate" name="todate" onChange={(e) => { onChangevalueloaddata(e) }} value={todate} className="form-control" />
              </span>
            </div>


            <div className='col-md-2 text-end'>
              {(userType === 0 || appState[1]?.[0]?.stocktransfer?.charAt(1) === '1') ? (<button id='cmdnew' onClick={cmdNew} className='btn btn-outline-primary'> <i class="fa-solid fa-plus"></i> &nbsp;Create New </button>) : null}
            </div>
            <div style={{ maxHeight: "80vh", overflow: "auto" }} className='col-12 '>
              <table className="table table-hover table-striped">
                <thead>
                  <tr>
                    <th scope="col" className="hide-on-mobile">Sr No</th>
                    <th scope="col" className="f-size-on-mobile"><span className='hide-on-mobile'>Date</span> <span className='show-on-mobile'>Details</span></th>
                    <th scope="col" className="hide-on-mobile">PO No</th>
                    <th scope="col" className="hide-on-mobile">From</th>
                    <th scope="col" className="hide-on-mobile">To</th>
                    <th scope="col" className="hide-on-mobile">Total Qty</th>
                    <th scope="col" className="f-size-on-mobile text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {srvdata.map((res, x) => (
                    <tr style={{ verticalAlign: 'middle', height: '100%' }}>
                      <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[0]}</td>
                      <td className="f-size-on-mobile" style={{ wordWrap: "break-word" }}> <span className='show-on-mobile'><b>Date :</b> </span> {res[1] ? new Date(res[1]).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"} <br />
                        <div className="detail-on-mobile text-start align-self-start" style={{ fontSize: "12px", color: "blue", alignItems: "start", wordWrap: "break-word" }}>
                          {res[2] !== '' && (<>From : {res[2]} <br /></>)}
                          {res[10] !== 0 && (<>To : {res[3]} <br /></>)} <span style={{ color: "blue" }}> </span>
                          {res[2] !== '' && (<>Total Qty : {res[4]} <br /></>)}
                        </div>
                      </td>
                      <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[5]}</td>
                      <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[2]}</td>
                      <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[3]}</td>
                      <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[4]}</td>

                      <td className='text-center' style={{ verticalAlign: 'middle', height: '100%' }}>
                        {(userType === 0 || appState[1]?.[0]?.stocktransfer?.charAt(2) === '1') ? (<Link to={"/StockTRF"} onClick={(e) => { cmdEdit(res[0]) }} className='btn btn-sm btn-outline-dark'><i class="fa-solid fa-pen-to-square"></i><span className="hide-on-mobile" > Edit</span></Link>) : null} &nbsp; <span className="detail-on-mobile"><br /> <br /></span>
                        <Link className='btn btn-sm btn-outline-primary' onClick={(e) => { printdataservice(res[0]) }}><i class="fa-solid fa-print"></i> <span className="hide-on-mobile">Print</span></Link> &nbsp;
                        {(userType === 0 || appState[1]?.[0]?.stocktransfer?.charAt(3) === '1') ? (<Link className='btn btn-sm btn-danger' onClick={(e) => { handleOpenModal(res[0], res[0]) }} > <i class="fa-solid fa-trash"></i> <span className="hide-on-mobile"> Delete</span></Link>) : null}&nbsp;
                        {/* <Link className='btn btn-sm btn-danger' onClick={(e) => { cmdDelete(res[0]) }} > <i class="fa-solid fa-trash"></i> <span className="hide-on-mobile"> Delete</span></Link> &nbsp; */}

                        {/* onClick={(e) => { handleOpenModal(row.id, row.flname) }} */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* New Service */}
      <div id='newform' style={{ display: "none" }}>
        <div className='container-fluid py-1 '>
          <div className='row py-1 shadow rounded' style={{ backgroundImage: 'linear-gradient(to right, #4DD6F4, #ff9999, #4DD6F4)', color: 'white' }}>
            <div className="col-md-2 align-self-center"><h4> <i class="fa-solid fa-right-left" style={{ width: "20px" }}></i> &nbsp; Stock Transfer</h4></div>
            <div className="col-2 text-end align-self-center"><label for="inputState" class="form-label"> </label> </div>
            {/* <input type="text" onChange={(e) => { onChangevalue(e) }} name='partyblno' value={partyblno} class="form-control" id="partyblno" style={{ width: "200px" }} /> <br /> */}
            <div className="col-1 text-start-0 align-self-end text-end"><label for="inputState" class="form-label"> Date :</label> </div>
            <input type="date" onChange={(e) => { onChangevalue(e) }} name='vdate' value={vdate} class="form-control" id="vdate" style={{ width: "200px" }} /> <br />


          </div>
          <p></p>
          <div className="row">
            <div className="col-md-12">

              <div className="row">
                <div className="col-md-2 text-end m-1"><label className='font-monospace' class="form-label">From Department : </label></div>
                <div className="col-md-2" >
                  <select id="fromdeptcode" name='fromdeptcode' value={fromdeptcode} class="form-select" disabled>
                    <option selected key={0} value={0}>NA</option>
                    {deptdata.map((x) => {
                      return (
                        <option key={x.id} value={x.id}> {x.deptname} </option>
                      )
                    })}
                  </select>

                </div>
                <div className="col-1 text-end align-self-center"><label for="inputState" class="form-label"> To :</label> </div>
                <div className="col-2 text-start-0 align-self-center">
                  <select id="todeptcode" name='todeptcode' value={todeptcode} onChange={(e) => { onChangevalue(e) }} class="form-select">
                    <option selected key={0} value={0}>NA</option>
                    {deptdata.map((x) => {
                      return (
                        <option key={x.id} value={x.id}> {x.deptname} </option>
                      )
                    })}
                  </select>
                </div>
                <div className="col-1 text-end align-self-center"><label for="inputState" class="form-label"> Po No :</label> </div>
                <div className="col-md-1 text-end align-self-center" >
                  <input type="text" onKeyDown={handleKeyDownFindItem} onFocus={handleFocus} name='pono' value={pono} onChange={(e) => { onChangevalue(e) }} class="form-control" id="pono" />
                </div>
                <div className="col-md-1 text-end align-self-center" >
                  <button onClick={() => { loaddata() }} className='btn btn-outline-dark'> Load (PO) </button>
                </div>
              </div>
              <br />
              <div className="row py-1">
                <div className="col-md-1">
                </div>
                <div className="col-md-4">
                  <div className="row">
                    <div className="col-3">
                      <label className='font-monospace' class="form-label">Item Name </label>
                    </div>
                    <div className="col-9 text-end">
                      <label className='font-monospace' style={{ color: "blue" }} class="form-label">Last Rate : {LastPurcrate} <span style={{ color: "red" }}>(Stock : {LastStock})</span></label>
                    </div>
                  </div>
                  <input type="text" placeholder="Search for items..." class="form-control" value={query} id="inputname1111" onFocus={handleFocus} onChange={handleInputChange} onKeyDown={handleKeyDown} ref={inputRef} autoComplete='off' /> <br />
                  {listVisible && filteredData.length > 0 && (
                    <ul style={{}}>
                      {filteredData.map((item, index) => (
                        <li
                          key={item.id}
                          onClick={() => handleSelectItem(item, index)}
                          onMouseDown={() => handleItemClick(index)}

                          className={index === selectedIndex ? 'selected' : ''}
                        >
                          {item.itname}
                        </li>
                      ))}
                    </ul>
                  )}

                </div>
                <div className="col-md-2">
                  <label className='font-monospace' class="form-label">Barcode</label>
                  <input type="text" value={barcode} onKeyDown={handleKeyDownFindItem} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} name='barcode' class="form-control" id="barcode" /> <br />
                </div>
                <div className="col-md-1">
                  <label className='font-monospace' class="form-label">Qty</label>
                  <input type="text" value={qty} name='qty' onKeyDown={handleKeyDownnext} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} class="form-control" id="qty" /> <br />
                </div>
                <div className="col-md-1">
                  <label className='font-monospace' class="form-label">Rate</label>
                  <input type="text" value={rate} name='rate' onKeyDown={handleKeyDownnext} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} class="form-control" id="rate" /> <br />
                </div>
                {/* <div className="col-md-1">
                  <label className='font-monospace' class="form-label">Dis %</label>
                  <input type="text" value={discperc} name='discperc' onKeyDown={handleKeyDownnext} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} class="form-control" id="discperc" /> <br />
                </div> */}
                <div id='addroomNew' className="col-md-2 text-center">
                  <label className='font-monospace ' style={{ color: "white" }} class="form-label">-</label> <br />
                  <button style={{ backgroundImage: 'linear-gradient(to right, #ff9999, #ff9999, #ff9999)', color: 'black', fontSize: "12px", width: "90%", textAlign: "center", justifyContent: "center" }} type="submit" onClick={() => { handleAddItem() }} class="btn btn-info"><i class="fa-solid fa-circle-plus"></i> Add</button>
                </div>

              </div>

              <div className='show-on-mobile row   container' style={{ color: 'black' }}>
                {itemdetailGrid.map((resp, index) => (
                  <div key={index} className='row text-center shadow rounded align-self-center p-1 m-0 bg-light border border-1'>
                    <h6 style={{ "color": "blue", borderBottom: "1px solid black" }}>Item : {resp.rawname}</h6>
                    <div className='col-9 text-start' style={{ fontSize: "15px", color: "black" }}>
                      <table style={{ "width": "100%" }}>
                        <tr><td>Qty </td><td>: {resp.qty}</td></tr>
                        <tr><td>Rate</td><td>: {resp.rate}</td></tr>
                        <tr><td>Gst {resp.gst} % </td><td>: {((resp.qty * resp.rate) * resp.gst / 100)}</td></tr>
                        <tr><td>Total</td><td>: {(resp.qty * resp.rate) + ((resp.qty * resp.rate) * resp.gst / 100)}</td></tr>
                      </table>
                    </div>
                    <div className='col-3 align-self-center'>
                      <button type="submit" onClick={(e) => { handleDeleteItem(resp.rawcode) }} className="btn btn-outline-danger"> <i className="fa-solid fa-trash"></i></button>
                    </div>

                  </div>
                ))}
              </div>

              <div className='hide-on-mobile text-left align-self-center row bg-info p-1 m-0 shadow rounded border border-1' style={{ backgroundImage: 'linear-gradient(to right, #4DD6F4, #ff9999, #4DD6F4)', color: 'black', padding: '20px' }}>
                <div className='row text-left align-self-center p-1 m-0 '>
                  <div className='col-md-4'>Item Name</div>
                  <div className='col-md-3'>Barcode</div>
                  <div className='col-md-1 text-end'>Qty</div>
                  <div className='col-md-1 text-end'>Rate</div>
                  {/* <div className='col-md-1 text-end'>Rate</div> */}
                  {/* <div className='col-md-1 text-end'>Dis %</div>
                  <div className='col-md-1 text-end'>Dis Amt</div>
                  <div className='col-md-1 text-end'>Gst</div>
                  <div className='col-md-1 text-end'>Gst Amt</div>
                  <div className='col-md-1 text-end'>Amount</div> */}
                  <div className='col-md-1 text-end'>Action</div>
                  <div className='col-md-2 text-end'>Current Stock</div>
                </div>
              </div>
              <div className='hide-on-mobile' style={{ maxHeight: "365px", overflow: "auto" }} ref={scrollRef}>
                {itemdetailGrid.map((resp, index) => (
                  <div key={index} className='row text-left p-1 m-0 bg-light border border-1'>
                    <div className='col-md-4 align-self-center'>{resp.rawname}</div>
                    <div className='col-md-3 align-self-center'>{resp.barcode}</div>
                    <div className='col-md-1 align-self-center text-end'>{Number(resp.qty).toFixed(2)}</div>
                    <div className='col-md-1 align-self-center text-end'>{Number(resp.rate).toFixed(2)}</div>
                    <div className='col-md-1 align-self-center text-end'>
                      <button type="submit" onClick={(e) => { handleDeleteItem(resp.rawcode) }} className="btn btn-outline-danger"> <i className="fa-solid fa-trash"></i></button>
                    </div>
                    {/* <div className={`col-md-2 align-self-center text-end ${Number(resp.closingstk) < 0 ? 'bg-danger text-white' : ''}`}>{Number(resp.closingstk).toFixed(2)}</div> */}
                    <div
                      className={`col-md-2 align-self-center text-end ${Number(resp.closingstk) < 0 ? 'bg-danger text-white' : ''}`}
                    >
                      {resp.closingstk}
                    </div>


                  </div>
                ))}
                <div className=' bg-dark text-bg-dark row text-left p-1 m-0 bg-light border border-1'>
                  <div className='col-md-6 align-self-center'></div>
                  <div className='col-md-1 align-self-center text-end'><b>Total :</b></div>
                  <div className='col-md-1 align-self-center  text-end'><b>{Number(itemdetailGrid.reduce((total, resp) => total + parseFloat(resp.qty), 0)).toFixed(2)}</b></div>

                  <div className='col-md-1 align-self-center  text-end'></div>
                  <div className='col-md-1 align-self-center'>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 ">
            </div>
          </div>
        </div>
        <br /><br /><br /><br />
        <div className="parent-container">
          {/* Fixed element at the bottom */}
          <div className="fixed-bottom" style={{ position: 'fixed', bottom: 0, width: '100%', backgroundColor: '#fff', borderTop: '1px solid black' }}>

            <div className="container-fluid" >

              <div className="row">
                <div className="col-md-9 f-size-on-mobile text-end p-1" >
                  <p className='hide-on-mobile m-2'></p>
                  <button id='cmdsave' onClick={() => { saveroomservice(1) }} class="btn btn-primary"><i class="fa-solid fa-print"></i> Save & Print </button>  &nbsp;
                  <button id='cmdsave1' onClick={() => { saveroomservice(0) }} class="btn btn-primary"><i class="fa-solid fa-floppy-disk"></i> Save </button>  &nbsp;
                  <a href='/StockTRF' class="btn btn-outline-primary">Cancel</a>
                </div>
                {/* Summary */}
                <div className="col-md-3 f-size-on-mobile ">
                  <div className='rounded'>
                    {/* <div className="row p-1 m-1 f-size-on-mobile">
                      <div className="col-6 text-end size-on-mobile" style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold', color: "blue" }}> Round-off :</div> <div style={{ fontFamily: 'arial', color: "blue", fontSize: '14px', fontWeight: 'bold' }} className="col-6 text-end">  {Number((Math.round(itemdetailGrid.reduce((total, resp) => total + parseFloat((Number(((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))) * Number(resp.gst) / 100) + Number((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))))), 0))) - (itemdetailGrid.reduce((total, resp) => total + parseFloat((Number(((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))) * Number(resp.gst) / 100) + Number((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))))), 0))).toFixed(2)} </div>
                      <div className="col-6 text-end size-on-mobile" style={{ fontFamily: 'arial', fontSize: '18px', fontWeight: 'bold', backgroundColor: "black", color: "white" }}> Total :</div> <div style={{ fontFamily: 'arial', backgroundColor: "black", color: "white", fontSize: '16px', fontWeight: 'bold' }} className="col-6 text-end"> {Math.round(itemdetailGrid.reduce((total, resp) => total + parseFloat((Number(((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))) * Number(resp.gst) / 100) + Number((((resp.qty * resp.rate))) - (((resp.qty * resp.rate * resp.discperc) / 100))))), 0))} </div>
                    </div> */}
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>


      </div>
      <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
        <div><p>Are you sure you want to delete this <br /> Sr No : {deletemsg} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { cmdDelete(deleteid) }}>Yes</button> <button className="no-button" onClick={handleNoClick}>No</button></div></div>
      </Modal>
      <ToastContainer autoClose={5000}></ToastContainer>
      {isLoading && <Loading />}
    </div>
  )
}

export default StockTRF