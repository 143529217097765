import React from 'react'
import css from "./app.module.css"
import Navbar from './Component/Navbar'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ItemUnit_View from './Component/ItemUnit_View'
import Home from './Component/Home'
import ItemGroupMaster from './Component/ItemGroupMaster'
import KitchenMaster from './Component/KitchenMaster'
import DishTypeMaster from './Component/DishTypeMaster'
import ItemCompanyMaster from './Component/ItemCompanyMaster'
import DishHeadMaster from './Component/DishHeadMaster'
import FloorMaster from './Component/FloorMaster'
import RoomMaster from './Component/RoomMaster'
import TableMaster from './Component/TableMaster'
import ItemMaster from './Component/ItemMaster'
import Logbook from './Component/Logbook'
import RoomTypeMaster from './Component/RoomTypeMaster'
import CheckIn from './Component/CheckIn'
import CheckOut from './Component/CheckOut'
import SaleBill from './Component/SaleBill'
import MessageBox from './Component/MessageBox'
import SaleBillTrash from './Component/SaleBillTrash'
import SaleBillCancel from './Component/SaleBillCancel'
import Test from './Component/Test.jsx'
import Reservation from './Component/Reservation'
import SettingsTool from './Component/SettingsTool.jsx'
import Rpt_Room_status from './Report/Rpt_Room_status'
import Rpt_CheckInReport from './Report/Rpt_CheckInReport'
import Rpt_BillReport from './Report/Rpt_BillReport'
import Shop_Create from './Component/Shop_Create'
import AccountMaster from './Component/AccountMaster'
import RoomService from './Component/RoomService'
import FoodOrder from './Component/FoodOrder'
import KotView from './Component/KotView'
import RestBillView from './Component/RestBillView'
import Rpt_TableStatus from './Report/Rpt_TableStatus'
import Rpt_SaleReport from './Report/Rpt_SaleReport'
import RestBillViewTrash from './Component/RestBillViewTrash'
import RestBillViewCancelled from './Component/RestBillViewCancelled'
import PrintKot from './Component/PrintKot'
import BillPaymentRest from './Component/BillPaymentRest'
import RecoveryyPassword from './Component/RecoveryyPassword'
import Passwordchange from './Component/Passwordchange'
import PrinterSelector from './Component/PrinterSelector'
import { Printdata } from './Component/Printdata'
import TransferBill from './Component/TransferBill'
import EntryTrfBill from './Component/EntryTrfBill'
import BanquetHall from './Component/BanquetHall'
import BanquetBook from './Component/BanquetBook'
import Bom from './Component/Bom'
import WaiterMas from './Component/WaiterMas'
import CommentMas from './Component/CommentMas.jsx'
import TransferTable from './Component/TransferTable.jsx'
import MergeTable from './Component/MergeTable.jsx'
import Purchase from './Component/Purchase.jsx'
import DepartmentMas from './Component/DepartmentMas.jsx'
import Rpt_Stock_Report from './Report/Rpt_Stock_Report.jsx'
import Production from './Component/Production.jsx'
import Rpt_CostingReport from './Report/Rpt_CostingReport.jsx'
import StockTRF from './Component/StockTRF.jsx'
import Rpt_PurchaseReport from './Report/Rpt_PurchaseReport.jsx'
import LocationItemDisplay from './Component/LocationItemDisplay.jsx'
import ItemMasterStore from './Component/ItemMasterStore.jsx'
import LocationDishheadDisplay from './Component/LocationDishheadDisplay.jsx'
import LocationDishTypeDisplay from './Component/LocationDishTypeDisplay.jsx'
import TransferTableItem from './Component/TransferTableItem.jsx'
import KotItemCancel from './Component/KotItemCancel.jsx'
import Rpt_Kot_Item_Cancel from './Report/Rpt_Kot_Item_Cancel.jsx'
import ToolPass from './Component/ToolPass.jsx'
import PoEntry from './Component/PoEntry.jsx'
import Rpt_StockTRf from './Report/Rpt_StockTRf.jsx'
import Rpt_SaleReportItem_wise from './Report/Rpt_SaleReportItem_wise.jsx'
import LedgerView from './Component/LedgerView.jsx'
import MaintenanceRoom from './Component/MaintenanceRoom.jsx'
import StockTRFWastage from './Component/StockTRFWastage.jsx'
import Rpt_Stockwastage from './Report/Rpt_Stockwastage.jsx'
import FoodPackage from './Component/FoodPackage.jsx'
import Rpt_Hotel_ReservationReport from './Report/Rpt_Hotel_ReservationReport.jsx'
import Rpt_Banquet_ReservationReport from './Report/Rpt_Banquet_ReservationReport.jsx'
import RptSaleItemSummary from './Report/RptSaleItemSummary.jsx'
import TransactionDetail from './Component/TransactionDetail.jsx'
import OurClint from './Component/OurClint.jsx'
import UserPermission from './Component/UserPermission.jsx'
import { UserLoginDetails } from './Hostlink/UserLoginDetails.js'
import Rpt_Item_Statement_report from './Report/Rpt_Item_Statement_report.jsx'
import KitchenDisplay from './Component/KitchenDisplay.jsx'
import KitchenDisplayStatus from './Component/KitchenDisplayStatus.jsx'
import WifiLink from './WifiShare/WifiLink.jsx'
import BluetoothTerminal from './Component/BluetoothTerminal.jsx'
import UsbPrinter from './Component/UsbPrinter.jsx'
import Rpt_MIS_Report from './Report/Rpt_MIS_Report.jsx'
import CheckOut_Details from './Component/CheckOut_Details.jsx'
import Rpt_Room_availability from './Report/Rpt_Room_availability.jsx'
import Rpt_Room_availability_Roomtype from './Report/Rpt_Room_availability_Roomtype.jsx'
import Reservation_view from './Component/Reservation_view.jsx'
import CleanerMas from './Component/CleanerMas.jsx'
import Cleaner from './Component/Cleaner.jsx'
import Rpt_Cleaner_Report from './Report/Rpt_Cleaner_Report.jsx'
import Check_in_Commission from './Report/Check_in_Commission.jsx'
import CardMaster from './Component/CardMaster.jsx'
import CardView from './Component/CardView.jsx'
import CardReturn from './Component/CardReturn.jsx'
import Rpt_Card_Recharge_Report from './Report/Rpt_Card_Recharge_Report.jsx'
import Rpt_Stock_By_Purchase from './Report/Rpt_Stock_By_Purchase.jsx'
import SelfOrder from './Component/SelfOrder.jsx'
import EntryTrfBillUpdate from './Component/EntryTrfBillUpdate.jsx'
import CustomerMaster from './Component/CustomerMaster.jsx'
import OurClintRetail from './Component/OurClintRetail.jsx'
import Rpt_Wallet_history from './Report/Rpt_Wallet_history.jsx'
import EmployeeMaster from './Component/EmployeeMaster.jsx'
import EmpAttandance from './Component/EmpAttandance.jsx'
import SalaryList from './Component/SalryList.jsx'
import Rpt_Emp_Salry from './Report/Rpt_Emp_Salry.jsx'



const App = () => {
  
  return (
    <div id={css.app} autoComplete="off">
          <Navbar/>
          <BrowserRouter>
            <Routes>
              <Route path='/' Component={Home}></Route>
              <Route path='/ItemUnit_View' Component={ItemUnit_View}></Route>
              <Route path='/ItemGroupMaster' Component={ItemGroupMaster}></Route>
              <Route path='/KitchenMaster' Component={KitchenMaster}></Route>
              <Route path='/DishTypeMaster' Component={DishTypeMaster}/>
              <Route path='/ItemCompanyMaster' Component={ItemCompanyMaster}/>
              <Route path='/DishHeadMaster' Component={DishHeadMaster}/>
              <Route path='/FloorMaster' Component={FloorMaster}/>
              <Route path='/RoomMaster' Component={RoomMaster}/>
              <Route path='/TableMaster' Component={TableMaster}/>
              <Route path='/ItemMaster' Component={ItemMaster}/>
              <Route path='/Logbook' Component={Logbook}/>
              <Route path='/RoomTypeMaster' Component={RoomTypeMaster}/>
              <Route path='/CheckIn' Component={CheckIn}/>
              <Route path='/CheckOut' Component={CheckOut}/>
              <Route path='/SaleBill' Component={SaleBill}/>
              <Route path='/MessageBox' Component={MessageBox}/>
              <Route path='/SaleBillTrash' Component={SaleBillTrash}/>
              <Route path='/SaleBillCancel' Component={SaleBillCancel}/>
              <Route path='/Test' Component={Test}/>
              <Route path='/KitchenDisplayStatus' Component={KitchenDisplayStatus}/>
              <Route path='/Reservation' element={<Reservation/>}/>
              <Route path='/SettingsTool' element={<SettingsTool/>}/>
              <Route path='/Rpt_Room_status' element={<Rpt_Room_status/>}/>
              <Route path='/Rpt_CheckInReport' element={<Rpt_CheckInReport/>}/>
              <Route path='/Rpt_BillReport' element={<Rpt_BillReport/>}/>
              <Route path='/Shop_Create' element={<Shop_Create/>}/>
              <Route path='/AccountMaster' element={<AccountMaster/>}/>
              <Route path='/RoomService' element={<RoomService/>}/>
              <Route path='/FoodOrder' element={<FoodOrder/>}/>
              <Route path='/KotView' element={<KotView/>}/>
              <Route path='/RestBillView' element={<RestBillView/>}/>
              <Route path='/Rpt_TableStatus' element={<Rpt_TableStatus/>}/>
              <Route path='/Rpt_SaleReport' element={<Rpt_SaleReport/>}/>
              <Route path='/RestBillViewTrash' element={<RestBillViewTrash/>}/>
              <Route path='/RestBillViewCancelled' element={<RestBillViewCancelled/>}/>
              <Route path='/PrintKot' element={<PrintKot/>}/>
              <Route path='/BillPaymentRest' element={<BillPaymentRest/>}/>
              <Route path='/RecoveryyPassword' element={<RecoveryyPassword/>}/>
              <Route path='/Passwordchange' element={<Passwordchange/>}/>
              <Route path='/PrinterSelector' element={<PrinterSelector/>}/>
              <Route path='/Printdata' element={<Printdata/>}/>
              <Route path='/TransferBill' element={<TransferBill/>}/>
              <Route path='/EntryTrfBill' element={<EntryTrfBill/>}/>
              <Route path='/BanquetHall' element={<BanquetHall/>}/>
              <Route path='/BanquetBook' element={<BanquetBook/>}/>                  
              <Route path='/Bom' element={<Bom/>}/>  
              <Route path='/WaiterMas' element={<WaiterMas/>}/>  
              <Route path='/CommentMas' element={<CommentMas/>}/> 
              <Route path='/TransferTable' element={<TransferTable/>}/>  
              <Route path='/MergeTable' element={<MergeTable/>}/>  
              <Route path='/Purchase' element={<Purchase/>}/>  
              <Route path='/DepartmentMas' element={<DepartmentMas/>}/>  
              <Route path='/Rpt_Stock_Report' element={<Rpt_Stock_Report/>}/>  
              <Route path='/Production' element={<Production/>}/>  
              <Route path='/Rpt_CostingReport' element={<Rpt_CostingReport/>}/>  
              <Route path='/StockTRF' element={<StockTRF/>}/>  
              <Route path='/Rpt_PurchaseReport' element={<Rpt_PurchaseReport/>}/>  
              <Route path='/LocationItemDisplay' element={<LocationItemDisplay/>}/>  
              <Route path='/ItemMasterStore' element={<ItemMasterStore/>}/>  
              <Route path='/LocationDishheadDisplay' element={<LocationDishheadDisplay/>}/>  
              <Route path='/LocationDishTypeDisplay' element={<LocationDishTypeDisplay/>}/>  
              <Route path='/TransferTableItem' element={<TransferTableItem/>}/>  
              <Route path='/KotItemCancel' element={<KotItemCancel/>}/>  
              <Route path='/Rpt_Kot_Item_Cancel' element={<Rpt_Kot_Item_Cancel/>}/>  
              <Route path='/ToolPass' element={<ToolPass/>}/>  
              <Route path='/PoEntry' element={<PoEntry/>}/>  
              <Route path='/Rpt_StockTRf' element={<Rpt_StockTRf/>}/>  
              <Route path='/Rpt_SaleReportItem_wise' element={<Rpt_SaleReportItem_wise/>}/>  
              <Route path='/LedgerView' element={<LedgerView/>}/>  
              <Route path='/MaintenanceRoom' element={<MaintenanceRoom/>}/>  
              <Route path='/StockTRFWastage' element={<StockTRFWastage/>}/>  
              <Route path='/Rpt_Stockwastage' element={<Rpt_Stockwastage/>}/>  
              <Route path='/FoodPackage' element={<FoodPackage/>}/>  
              <Route path='/Rpt_Hotel_ReservationReport' element={<Rpt_Hotel_ReservationReport/>}/>  
              <Route path='/Rpt_Banquet_ReservationReport' element={<Rpt_Banquet_ReservationReport/>}/>  
              <Route path='/RptSaleItemSummary' element={<RptSaleItemSummary/>}/>  
              <Route path='/TransactionDetail' element={<TransactionDetail/>}/>  
              <Route path='/OurClint' element={<OurClint/>}/>  
              <Route path='/UserPermission' element={<UserPermission/>}/>  
              <Route path='/UserLoginDetails' element={<UserLoginDetails/>}/>  
              <Route path='/Rpt_Item_Statement_report' element={<Rpt_Item_Statement_report/>}/>  
              <Route path='/KitchenDisplay' element={<KitchenDisplay/>}/>  
              <Route path='/WifiLink' element={<WifiLink/>}/>  
              <Route path='/BluetoothTerminal' element={<BluetoothTerminal/>}/>  
              <Route path='/UsbPrinter' element={<UsbPrinter/>}/>  
              <Route path='/Rpt_MIS_Report' element={<Rpt_MIS_Report/>}/>
              <Route path='/CheckOut_Details' element={<CheckOut_Details/>}/>
              <Route path='/Rpt_Room_availability' element={<Rpt_Room_availability/>}/>        
              <Route path='/Rpt_Room_availability_Roomtype' element={<Rpt_Room_availability_Roomtype/>}/>       
              <Route path='/Reservation_view' element={<Reservation_view/>}/>       
              <Route path='/CleanerMas' element={<CleanerMas/>}/> 
              <Route path='/Cleaner' element={<Cleaner/>}/> 
              <Route path='/Housekeeping-Report' element={<Rpt_Cleaner_Report/>}/> 
              <Route path='/Check_in_Commission' element={<Check_in_Commission/>}/> 
              <Route path='/card-issue-return' element={<CardMaster/>}/> 
              <Route path='/CardView' element={<CardView/>}/> 
              <Route path='/CardReturn' element={<CardReturn/>}/> 
              <Route path='/Rpt_Card_Recharge_Report' element={<Rpt_Card_Recharge_Report/>}/> 
              <Route path='/Rpt_Stock_By_Purchase' element={<Rpt_Stock_By_Purchase/>}/> 
              <Route path='/SelfOrder' element={<SelfOrder/>}/> 
              <Route path='/EntryTrfBillUpdate' element={<EntryTrfBillUpdate/>}/> 
              <Route path='/CustomerMaster' element={<CustomerMaster/>}/> 
              <Route path='/OurClintRetail' element={<OurClintRetail/>}/> 
              <Route path='/Rpt_Wallet_history' element={<Rpt_Wallet_history/>}/> 
              <Route path='/EmployeeMaster' element={<EmployeeMaster/>}/> 
              <Route path='/EmpAttandance' element={<EmpAttandance/>}/> 
              <Route path='/SalaryList' element={<SalaryList/>}/> 
              <Route path='/Rpt_Emp_Salry' element={<Rpt_Emp_Salry/>}/> 
              
            </Routes>
          </BrowserRouter>     
          
    </div>
  )
}

export default App
