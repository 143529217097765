import axios from 'axios';
import React, { useRef, useState, useContext } from 'react'
import { useEffect } from 'react'
import hostlink from '../Hostlink/hostlink';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import Loading from './Loading';
import { UserLoginDetails } from '../Hostlink/UserLoginDetails';
const BillPaymentRest = () => {
  const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
  const usershopid = appState?.[0]?.id || 0;
  const usershopidCreditCrdlimit = appState?.[0]?.creditcardlimit || 0;
  const [CardBalance, setCardBalance] = useState(0);
  const [CardOwnerName, setCardOwnerName] = useState("");
  const [CardOwnerMob, setCardOwnerMob] = useState("");

  const [callcardbalance, setcallcardbalance] = useState(false);
  const [TableListUnbilled, setTableListUnbilled] = useState([]);
  const [cmbAccdata, setcmbAccdata] = useState([]);
  const [cmbAccdataBank, setcmbAccdataBank] = useState([]);
  const [selected, setSelected] = useState(0);
  const [Payselected, setPayselected] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (index) => {
    setSelected(index);
    setPayselected(index);
    setMessagedata({ ...messagedata, payacccode: index })
  };
  const [messagedata, setMessagedata] = useState({
    paybldate: "",
    payblno: "",
    cardno: "",
    paybltype: "",
    payblAmt: 0,
    payacccode: "",
    billremarks: "",
    pay1acc: 0,
    pay2acc: 0,
    pay1amt: 0,
    pay2amt: 0,
    tndramt: 0,
    tipamount: 0,
    retamt: 0
  })
  const { paybldate, payacccode, payblno, paybltype, payblAmt, pay1acc, pay2acc, pay1amt, cardno, pay2amt, tndramt, tipamount, retamt, billremarks } = messagedata;

  const nameInputbillremarks = useRef(null);

  const trfbill = async (e) => {

    const queryParams = new URLSearchParams({
      paybldate,
      paybltype,
      payblno,
      payblAmt
    }).toString();
    window.location.assign(`/EntryTrfBill?${queryParams}`);
  };
  const paybill = async (e) => {

    if (cardno && cardno.trim() !== "") {
      let cardbalance = Number(usershopidCreditCrdlimit) + Number(CardBalance);
      console.log("Usershopid Credit Limit:", usershopidCreditCrdlimit);
      console.log("Card Balance:", CardBalance);
      console.log("Computed Card Balance:", cardbalance);
      console.log("Payable Amount:", payblAmt);
      if (!isNaN(cardbalance) && !isNaN(payblAmt)) {
        if (cardbalance >= payblAmt) {
          const validatedBillRemarks = billremarks.trim() === "" ? "-" : billremarks;
          await axios.post(`${hostlink}/RestbillPaymentMultiPayTenderCard/${usershopid}/${payblno}/${appState?.[0]?.creditcardacc}/${payblAmt}/0/0/0/0/${validatedBillRemarks}/0/${cardno}/${CardOwnerName}/${CardOwnerMob}`).then((resp) => { console.log("PAID"); toast.success("Paid Sucessfull") }).catch((resp) => { console.log("Something else") });
            setInterval(() => {
              setIsLoading(false);
              window.location.assign("/BillPaymentRest")
            }, 1000);
          console.log("✅ Card Has Enough Balance...");
          return false;
        } else {
          toast.error("Insufficient Balance...")
          console.log("❌ Insufficient Balance...");
          return false;
        }
      } else {
        console.log("⚠️ Error: Invalid Number Values!");
        return false;
      }
    } else {
      console.log("⚠️ Error: Card number is empty!");
    }

    
    document.getElementById("paybtn1").disabled = true
    document.getElementById("paybtn2").disabled = true
    setIsLoading(true);

    if (Number(pay1amt) < 0) {
      toast.error("Please select a valid Amount...");
      document.getElementById("pay1amt").focus();
      document.getElementById("paybtn1").disabled = false
      document.getElementById("paybtn2").disabled = false
      setIsLoading(false);
      return false;
    }

    if (Number(pay2amt) < 0) {
      toast.error("Please select a valid Amount...");
      document.getElementById("paybtn1").disabled = false
      document.getElementById("paybtn2").disabled = false
      document.getElementById("pay2amt").focus();
      setIsLoading(false);
      return false;
    }

    if (Number(pay1amt) > 0) {
      if (Number(pay1acc) === 0) {
        document.getElementById("pay1acc").focus();
        toast.error("Please select a valid Paymode...");
        document.getElementById("paybtn1").disabled = false
        document.getElementById("paybtn2").disabled = false
        setIsLoading(false);
        return false;
      }
    }

    if (Number(pay2amt) > 0) {
      if (Number(pay2acc) === 0) {
        document.getElementById("pay2acc").focus();
        document.getElementById("paybtn1").disabled = false
        document.getElementById("paybtn2").disabled = false
        toast.error("Please select a valid Paymode...");
        setIsLoading(false);
        return false;
      }
    }

    if (Number(pay1amt) + Number(pay2amt) !== Number(payblAmt)) {
      toast.error("Please Enter a Valid Amount...");
      document.getElementById("paybtn1").disabled = false
      document.getElementById("paybtn2").disabled = false
      setIsLoading(false);
      return false;
    }

    if (Number(pay1acc) === Number(pay2acc)) {
      document.getElementById("paybtn1").disabled = false
      document.getElementById("paybtn2").disabled = false
      toast.error("Please select Valid Paymode...");
      setIsLoading(false);
      return false;
    }

    const validatedBillRemarks = billremarks.trim() === "" ? "-" : billremarks;

    // Add tip amount to either pay1amt or pay2amt based on pay1amt
    let finalPay1Amt = Number(pay1amt);
    let finalPay2Amt = Number(pay2amt);

    if (finalPay1Amt > 0) {
      finalPay1Amt += Number(tipamount);  // Add tip to pay1amt if pay1amt is > 0
    } else {
      finalPay2Amt += Number(tipamount);  // Add tip to pay2amt if pay1amt is 0
    }
    await axios.post(`${hostlink}/RestbillPaymentMultiPayTender/${usershopid}/${payblno}/${pay1acc}/${pay1amt}/${pay2acc}/${pay2amt}/${tndramt}/${retamt}/${validatedBillRemarks}/${tipamount}`).then((resp) => { console.log("PAID"); toast.success("Paid Sucessfull") }).catch((resp) => { console.log("Something else") });
    setInterval(() => {
      setIsLoading(false);
      window.location.assign("/BillPaymentRest")
    }, 1000);
  };

  const onChangeacc1 = (e, f) => {
    const existingItem = cmbAccdata.find(data => data.accname === f);
    if (existingItem) {
      console.log("find")
      const updatedItDetails = cmbAccdata.map(data => {
        if (data.accname === f) {
          setMessagedata({ ...messagedata, pay1acc: data.id })
        }
      });
    }
    else {
      setMessagedata({ ...messagedata, pay1acc: 0 })
    }

  }
  const onChangeacc2 = (e, f) => {
    const existingItem = cmbAccdataBank.find(data => data.accname === f);
    if (existingItem) {
      console.log("find")
      const updatedItDetails = cmbAccdataBank.map(data => {
        if (data.accname === f) {
          setMessagedata({ ...messagedata, pay2acc: data.id })
        }
      });
    }
    else {
      setMessagedata({ ...messagedata, pay2acc: 0 })
    }
  }

  useEffect(() => {
    setMessagedata({ ...messagedata, pay2amt: payblAmt - pay1amt });
  }, [pay1amt])

  useEffect(() => {
    if (cardno == "") {
      setCardBalance(0); // Reset balance on error
    }
    if (cardno?.trim() !== "") {
      axios.get(`${hostlink}/api/executeQuery`, {
        params: {
          sqlQuery: `SELECT SUM(cardbalance), status, custname, mobno 
                           FROM cardmaster 
                           WHERE shopid = ${usershopid} 
                           AND cardno = '${cardno}' 
                           AND status = 1`
        }
      })
        .then((resp) => {
          const result = resp.data[0] || [0, 0, "", ""]; // Default values to avoid undefined errors

          setCardBalance(result[0]); // Safe handling of balance
          setCardOwnerMob(result[3]);
          setCardOwnerName(result[2]);
        })
        .catch((error) => {
          console.error("Error fetching card data:", error);
          setCardBalance(0); // Reset balance on error
        })
        .finally(() => {
          setcallcardbalance(false)
        });
    }
  }, [callcardbalance, usershopid]);


  useEffect(() => {
    setMessagedata({ ...messagedata, pay1amt: payblAmt - pay2amt });
  }, [pay2amt])

  useEffect(() => {
    setMessagedata({ ...messagedata, retamt: tndramt - payblAmt });
  }, [tndramt])


  const onChangevalue = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,<>?=]/g, '');
    setMessagedata({ ...messagedata, [name]: sanitizedValue });
  }
  const [isModalOpen_Payment, setIsModalOpen_Payment] = useState(false);
  const handleCloseModal_Payment = () => { setIsModalOpen_Payment(false); };
  const handleNoClick_Payment = () => { console.log('User clicked No'); handleCloseModal_Payment(); };
  const handleKeyDown = (event, nextRef) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (nextRef && nextRef.current) {
        nextRef.current.focus();
      }
    }
  };
  useEffect(() => {
    if (usershopid != 0) {
      axios.get(`${hostlink}/RestbillviewPayment/${usershopid}`).then((resp) => { setTableListUnbilled(resp.data) }).catch((resp) => { console.log("Not Fatch") });
      axios.get(`${hostlink}/${usershopid}/accmas/0`).then((Response) => { if (Response.data.length > 0) { setSelected(Response.data.id); } setMessagedata({ ...messagedata, pay1acc: Response.data[0].id }); setcmbAccdata(Response.data); }).catch((Response) => { "data Not Found" })
      axios.get(`${hostlink}/${usershopid}/accmas/1`).then((Response) => { setcmbAccdataBank(Response.data); }).catch((Response) => { "data Not Found" })
    }
  }, [usershopid])

  return (
    <div style={{ "background": "linear-gradient(to bottom, #87CEEB, #ffffff)" }}>

      <div className='container' style={{ fontFamily: "Bahnschrift Condensed" }}>
        <div className="row" >
          <h4 className='text-center text-bg-dark rounded'><i class="fa-solid fa-indian-rupee-sign"></i> Payment</h4>
          {TableListUnbilled.map((x) => { return (<div className='col-md-3 p-1'><button onClick={() => { setMessagedata({ ...messagedata, paybldate: x.SaleMasDTO.bldate, payblno: x.SaleMasDTO.shopvno, paybltype: x.bltype, pay2amt: 0, pay1amt: x.SaleMasDTO.totblamt, retamt: 0, tndramt: 0, payblAmt: x.SaleMasDTO.totblamt }); setIsModalOpen_Payment(true) }} className={`btn shadow rounded ${x.bltype === 'Delivery' ? 'btn-light' : 'btn-light'}`} value={x.id} style={{ width: "100%", border: "1px solid pink" }}>Bill Date : {x.SaleMasDTO.bldate ? new Date(x.SaleMasDTO.bldate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"} <br /> <b>Table No : {x.SaleMasDTO.tablename || "NA"}</b><br /> <b>Bill No : {x.SaleMasDTO.shopvno}</b>, Type : {x.bltype} <br /> <span style={{ color: "red" }}><b>Bill Amt : <i class="fa-solid fa-indian-rupee-sign"></i> {x.SaleMasDTO.totblamt}</b></span></button></div>) })}</div>
      </div>
      <div className="row">
        <div className="col-11 text-end">
          {/* <button style={{ width: "20%" }} onClick={() => { setIsModalOpen_Payment(false) }} className='btn btn-dark'><i class="fa-solid fa-right-from-bracket"></i> Cancel</button> */}
        </div>
      </div>



      <Modal isOpen={isModalOpen_Payment} onRequestClose={handleCloseModal_Payment} contentLabel="Delete Confirmation" shouldCloseOnOverlayClick={false} className="modal-content" overlayClassName="modal-overlay">
        <div style={{ fontFamily: "Bahnschrift Condensed" }}>
          <h4 className='text-bg-dark rounded'><i class="fa-solid fa-indian-rupee-sign"></i> Payment - ( {paybltype} )</h4>
          <table style={{ width: "100%" }}>

            <tr><td className='text-end' style={{ width: "50%" }}><b>Bill Date :       </b></td><td className='text-start'> &nbsp;<b >{paybldate ? new Date(paybldate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</b></td><td className='text-end' style={{ width: "50%" }}><b> </b></td><td className='text-start' > &nbsp;<b style={{ fontSize: "10px" }}></b></td></tr>
            <tr><td className='text-end' style={{ width: "50%" }}><b>Bill No :    </b></td><td className='text-start'> &nbsp;<b>{payblno}</b></td> <td className='text-end' style={{ width: "50%", color: "red" }}><b>Bill Amount :</b></td><td className='text-start'> &nbsp;<b style={{ width: "50%", color: "red" }}>{payblAmt}</b></td></tr>
            <tr style={{ width: "50%", color: "red" }}></tr>

            {/* <tr><td className='text-end' style={{ width: "50%" }}><b>Bill Date :       </b></td><td className='text-start'> &nbsp;<b >{paybldate ? new Date(paybldate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</b></td></tr>
            <tr><td className='text-end' style={{ width: "50%" }}><b>Type :       </b></td><td className='text-start'> &nbsp;<b >{paybltype}</b></td></tr>
            <tr><td className='text-end' style={{ width: "50%" }}><b>Bill No :    </b></td><td className='text-start'> &nbsp;<b>{payblno}</b></td> </tr>
            <tr style={{ width: "50%", color: "red" }}><td className='text-end' style={{ width: "50%", color: "red" }}><b>Bill Amount :</b></td><td className='text-start'> &nbsp;<b>{payblAmt}</b></td></tr> */}

          </table>
          
          <h3><u>Pay Mode</u></h3>
          <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <span style={{ textAlign: "start" }}>
              <label className="form-label">Scan Card</label>
            </span>
            <span className="text-danger">Card Balance: {Number(CardBalance).toFixed(2)}</span>
          </div>
          <input type="text" name='cardno' value={cardno} onFocus={() => setMessagedata({ ...messagedata, cardno: "" })} onBlur={() => setcallcardbalance(true)} onKeyDown={(e) => handleKeyDown(e, nameInputbillremarks)} onChange={(e) => { onChangevalue(e) }} placeholder='Scan Crad' id="cardno" autoComplete='off' className="form-control" />
          <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <small style={{ textAlign: "start" }}>
              <label className="form-label text-primary">{CardOwnerName}</label>
            </small>
            <small className="text-primary">{CardOwnerMob}</small>
          </div>
          
          <table style={{ width: "100%" }}>
            <tr><td className='text-end' style={{ width: "50%" }}><b>
              <div class="col-2 align-self-center">
                <select id="pay1acc" onChange={(e) => { onChangeacc1(e.target.value, e.target.selectedOptions[0].text) }} class="form-select" style={{ width: "170px" }}>
                  {cmbAccdata.map((x) => { return (<option key={x.accname} value={x.accname} title={x.id}> {x.accname} </option>) })}
                </select>
              </div>
            </b></td><td className='text-start'> <input type="number" value={pay1amt} onChange={(e) => { onChangevalue(e) }} name='pay1amt' class="form-control" id="pay1amt" /></td></tr>

            <tr><td className='text-end' style={{ width: "50%" }}><b>
              <div class="col-2 align-self-center">
                <select id="pay2acc" onChange={(e) => { onChangeacc2(e.target.value, e.target.selectedOptions[0].text) }} class="form-select" style={{ width: "170px" }}>
                  <option selected key={0} value={0}>Choose...</option>
                  {cmbAccdataBank.map((x) => { return (<option key={x.accname} value={x.accname} title={x.id}> {x.accname} </option>) })}
                </select>
              </div>
            </b></td><td className='text-start'> <input type="number" value={pay2amt} onChange={(e) => { onChangevalue(e) }} name='pay2amt' class="form-control" id="pay2amt" /></td></tr>

            <tr><td className='text-end' style={{ width: "50%" }}><b>
              Tender Amount : &nbsp;
            </b></td><td className='text-start'> <input type="number" value={tndramt} onChange={(e) => { onChangevalue(e) }} name='tndramt' class="form-control" id="tndramt" /></td></tr>
            <tr><td className='text-end' style={{ width: "50%" }}><b>
              Returnable Amount : &nbsp;
            </b></td><td className='text-start'> <input type="number" value={retamt} name='retamt' class="form-control" id="retamt" /></td></tr>

            <tr><td className='text-end' style={{ width: "50%" }}><b>
              Tips Amount : &nbsp;
            </b></td><td className='text-start'> <input type="number" value={tipamount} onChange={(e) => { onChangevalue(e) }} name='tipamount' class="form-control" id="tipamount" /></td></tr>

          </table>

          {/* <div className='col-12 text-center p-1' style={{ display: "flex", fontFamily: "Bahnschrift Condensed", overflowX: "scroll", WebkitOverflowScrolling: "touch", scrollbarWidth: "none", msOverflowStyle: "none", }}> <style>{`.scroll-container::-webkit-scrollbar {display: none;}`}</style>
            {cmbAccdata.map((x, index) => (<div key={x.id} className="col-md-3 p-1 bor " style={{ border: "1px solid black", backgroundColor: selected === x.id ? 'lightblue' : 'white', flex: "0 0 auto" }}>
              <input type="radio" id={`id-${x.id}`} name="paymentnew" value={x.id} checked={selected === x.id} onChange={() => handleChange(x.id)} style={{ width: "10%" }} /> &nbsp;
              <label htmlFor={`id-${x.id}`} onClick={() => handleChange(x.id)} style={{ width: "80%" }} className='text-start'> {x.accname} </label><br />
            </div>))}
          </div> */}
          <p></p>
          <input type="text" name='billremarks' value={billremarks} onChange={(e) => { onChangevalue(e) }} placeholder='Remarks' id="billremarks" ref={nameInputbillremarks} className="form-control" />
          <br />
          <button id='paybtn1' onClick={() => { paybill(); }} className='btn btn-danger'>Proceed to Pay</button> &nbsp;
          <button id='paybtn2' onClick={() => { trfbill(); }} className='btn btn-info'>Proceed to Room</button> &nbsp;
          <button onClick={() => { setIsModalOpen_Payment(false); setMessagedata({ ...messagedata, retamt: 0, tndramt: 0 }); }} className='btn btn-dark'>Cancel</button>
        </div>

      </Modal>
      <ToastContainer autoClose={1000}></ToastContainer>
      {isLoading && <Loading />}
    </div>
  )
}

export default BillPaymentRest