import React, { useEffect, useRef, useState, useContext } from 'react';

import axios from 'axios';
import hostlink from '../Hostlink/hostlink';
import * as XLSX from 'xlsx';
import { PDFDownloadLink, PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { UserLoginDetails } from '../Hostlink/UserLoginDetails';

const Rpt_Emp_Salry = () => {
    const [salaryData, setSalaryData] = useState([]);
    const [month, setMonth] = useState("April");
    const [year, setYear] = useState(2025);

    useEffect(() => {
        fetchSalaries();
    }, [month, year]);

    const fetchSalaries = async () => {
        try {
            const response = await axios.get(`${hostlink}/salary/calculate-all`, {
                params: { monthName: month, year: year, shopid: usershopid },
            });
            setData(response.data);
        } catch (error) {
            console.error("Error fetching salary data", error);
        }
    };
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const [RoomType, setRoomType] = useState([]);
    const [Room, setRoom] = useState([]);
    const [data, setData] = useState([]);

    const [frmdata, setfrmdata] = useState({
        rtype: "0",
        rcode: "0",
        status: "-1"
    });

    const { rtype, rcode, status } = frmdata;

    const tableRef = useRef(null);

    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setfrmdata({ ...frmdata, [name]: sanitizedValue });
    };

    const getroombyid = (e) => {
        if (`${e}` == 0) {
            axios.get(`${hostlink}/${usershopid}/room`)
                .then((Response) => {
                    setRoom(Response.data)
                }).catch((Response) => { "data Not Found" });

        }
        else {
            axios.get(`${hostlink}/room/getnamebyid/${e}`)
                .then((Response) => {
                    setRoom(Response.data)
                }).catch((Response) => { "data Not Found" });

        }
    };



    const exportToExcel = () => {
        const ws = XLSX.utils.table_to_sheet(tableRef.current); // Convert table to worksheet
        const boldAndColorStyle = {
            font: { bold: true },
            fill: { fgColor: { rgb: "FFFF00" } } // Yellow color, you can change the color as needed
        };
        const headerRow = XLSX.utils.decode_range(ws['!ref']).e.c;
        for (let c = 0; c <= headerRow; c++) {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c });
            if (ws[cellAddress].v === 'Sr No') { // Check if the cell value is 'Sr No'
                ws[cellAddress].s = boldAndColorStyle;
                for (let r = 1; r <= XLSX.utils.decode_range(ws['!ref']).e.r; r++) {
                    const dataCellAddress = XLSX.utils.encode_cell({ r, c });
                    if (ws[dataCellAddress]) {
                        ws[dataCellAddress].s = boldAndColorStyle;
                    }
                }
                break;
            }
        }
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, 'salary.xlsx');
    };

    useEffect(() => {
        axios.get(`${hostlink}/${usershopid}/rtmas`)
            .then((Response) => { setRoomType(Response.data); })
            .catch((Response) => { "data Not Found"; });

        axios.get(`${hostlink}/${usershopid}/room`)
            .then((Response) => { setRoom(Response.data); })
            .catch((Response) => { "data Not Found"; });
    }, [usershopid]);

    return (
        <div className='m-1'>
            <div className='container p-3 shadow rounded' style={{ transition: 'box-shadow 0.3s' }}>
                <div className='row'>
                    <div className='col-md-3 align-self-end'>
                        <h5 className='font-monospace text-primary'>  <span id='pagemode' > Employee Salary List</span> </h5>
                    </div>
                    <div className="mb-4 flex gap-4">
                        <select value={month} onChange={(e) => setMonth(e.target.value)} className="border p-2">
                            {["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"].map((m) => (
                                <option key={m} value={m}>{m}</option>
                            ))}
                        </select>

                        <input type="number" value={year} onChange={(e) => setYear(e.target.value)} className="border p-2 w-20" />

                        <button onClick={fetchSalaries} className="bg-blue-500  px-4 py-2">Fetch Salaries</button>
                    </div>
                    <div className='col-md-12 align-self-center text-end my-2'>
                        <button onClick={() => { fetchSalaries(); }} className='btn btn-sm btn-outline-primary'> <i class="fa-solid fa-magnifying-glass-chart"></i> Show</button> &nbsp;
                        <button onClick={() => { exportToExcel(); }} className='btn btn-sm btn-outline-primary'><i class="fa-solid fa-table"></i> Excel</button> &nbsp;

                        {/* <PDFDownloadLink document={<PDFDocument data={data} />} fileName="room_status.pdf">
                            {({ blob, url, loading, error }) =>
                                loading ? 'load' : 'data'
                            }
                            <button className='btn btn-sm btn-outline-primary'><i class="fa-regular fa-file-pdf"></i> PDF </button>
                        </PDFDownloadLink> */}

                    </div>
                </div>
            </div>

            <br />
            <div className='container' style={{ maxHeight: "400px", overflow: "auto" }}>
                <table className="table table-hover table-striped" ref={tableRef}>
                    <thead>
                        <tr>
                            <th className="border p-2">Employee Name</th>
                            <th className="border p-2">Total Days</th>
                            <th className="border p-2">Present</th>
                            <th className="border p-2">Absent</th>
                            <th className="border p-2">Holidays</th>
                            <th className="border p-2 text-end">Final Salary</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.length === 0 ? (
                            <tr>
                                <td colSpan="6" className="text-center p-4">No salary data available</td>
                            </tr>
                        ) : (
                            data.map((employee, index) => (
                                <tr key={index} className="border">
                                    <td className="border p-2">{employee.employeeName}</td>
                                    <td className="border p-2">{employee.totalDays}</td>
                                    <td className="border p-2">{employee.presentDays}</td>
                                    <td className="border p-2">{employee.absentDays}</td>
                                    <td className="border p-2">{employee.holidays}</td>
                                    <td className="border p-2 font-bold text-end">₹ {employee.finalSalary.toFixed(2)}</td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
const PDFDocument = ({ data }) => (
    <Document>
        <Page>
            <View style={styles.container}>
                <Text style={styles.title}>Room Status</Text>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <Text style={styles.headerCell}>Sr No</Text>
                        <Text style={styles.headerCell}>Room Name</Text>
                        <Text style={styles.headerCell}>Room Type</Text>
                        <Text style={styles.headerCell}>Floor</Text>
                        <Text style={styles.headerCell}>Rent</Text>
                        <Text style={styles.headerCell}>Status</Text>
                    </View>
                    {data.map((res, x) => (
                        <View key={x} style={styles.tableRow}>
                            <Text style={styles.cell}>{x + 1}</Text>
                            <Text style={styles.cell}>{res.name}</Text>
                            <Text style={styles.cell}>{res.rtname}</Text>
                            <Text style={styles.cell}>{res.flname}</Text>
                            <Text style={styles.cell}>{res.rent}</Text>
                            <Text style={styles.cell}>{res.stname}</Text>
                        </View>
                    ))}
                </View>
            </View>
        </Page>
    </Document>
);

const styles = StyleSheet.create({
    container: {
        padding: 20,
    },
    title: {
        fontSize: 20,
        marginBottom: 20,
        textAlign: 'center',
    },
    table: {
        display: 'table',
        width: '100%',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#000',
    },
    headerCell: {
        fontSize: 14,
        fontWeight: 'bold',
        padding: 5,
        flex: 1,
        textAlign: 'center',
    },
    cell: {
        fontSize: 14,
        padding: 5,
        flex: 1,
        textAlign: 'center',
    },
});


export default Rpt_Emp_Salry;
