import { UserLoginDetails } from '../Hostlink/UserLoginDetails'
import React, { useEffect, useState, useContext, useRef } from 'react'
import axios from 'axios';
import hostlink from '../Hostlink/hostlink';
import * as XLSX from 'xlsx';
import { PDFDownloadLink, PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import Loading from '../Component/Loading';

const Rpt_Card_Recharge_Report = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const usershopdeptcode = appState?.[0]?.deptcode || 0;
    const usershopidref = appState?.[0]?.hotelshopid || 0;

    const queryCondition = usershopidref == 0
        ? `${usershopid}`
        : `${usershopidref}`;
    const [RoomType, setRoomType] = useState([]);
    const [Room, setRoom] = useState([]);
    const [deptdata, setdeptdata] = useState([])
    const [data, setData] = useState([]);
    const today = new Date();

    const [frmdata, setfrmdata] = useState({
        fromdate: today.toISOString().split('T')[0],
        todate: today.toISOString().split('T')[0],
        deptcode: usershopdeptcode,
        mobno: '',
        cardno: '',
        status: 0
    });
    const [totalSums, setTotalSums] = useState({});
    const [returnSums, setReturnSums] = useState({});
    
    const { fromdate, todate, status, deptcode, cardno, mobno } = frmdata;
    const tableRef = useRef(null);
    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setfrmdata({ ...frmdata, [name]: sanitizedValue });
    };
    
const [transactionCounts, setTransactionCounts] = useState({}); // ✅ Added missing state

const loaddata = async () => {
    setIsLoading(true);
    try {
        const formattedMobNo = mobno === "" ? "-" : mobno;
        const formattedCardNo = cardno === "" ? "-" : cardno;
        const billResponse = await axios.get(`${hostlink}/${usershopid}/rechargedetailFIlter/${fromdate}/${todate}/${formattedCardNo}/${formattedMobNo}`);
        const billData = billResponse.data;
        setData(billData);

        const accResponse = await axios.get(`${hostlink}/${usershopid}/accmas`);
        const accmasData = accResponse.data;

        const accmasMap = accmasData.reduce((acc, curr) => {
            acc[curr.id] = curr.accname;
            return acc;
        }, {});

        const pay1Sums = {};
        const pay2Sums = {};
        const transactionCountsTemp = {};  // ✅ Using temp variable

        billData.forEach((item) => {
            if (item.pay1amt !== 0) {
                const accname = accmasMap[item.pay1code] || "UNKNOWN";
                const accountKey = item.pay1amt < 0 ? `${accname} (RETURN)` : accname;
                
                pay1Sums[accountKey] = (pay1Sums[accountKey] || 0) + item.pay1amt;
                transactionCountsTemp[accountKey] = (transactionCountsTemp[accountKey] || 0) + 1;
            }

            if (item.pay2amt !== 0) {
                const accname = accmasMap[item.pay2code] || "UNKNOWN";
                const accountKey = item.pay2amt < 0 ? `${accname} (RETURN)` : accname;
                
                pay2Sums[accountKey] = (pay2Sums[accountKey] || 0) + item.pay2amt;
                transactionCountsTemp[accountKey] = (transactionCountsTemp[accountKey] || 0) + 1;
            }
        });

        const combinedSums = { ...pay1Sums };
        Object.keys(pay2Sums).forEach((key) => {
            combinedSums[key] = (combinedSums[key] || 0) + pay2Sums[key];
        });

        setTotalSums(combinedSums);
        setTransactionCounts(transactionCountsTemp);  // ✅ Set state here
        setIsLoading(false);
    } catch (error) {
        setIsLoading(false);
        console.log("Data Not Found...");
    }
};

    // const loaddata = async (e) => {
    //     setIsLoading(true)
    //     try {
    //             console.log(`${hostlink}/${usershopid}/viewCardMasterReport/${fromdate}/${todate}`)
    //             const billResponse = await axios.get(`${hostlink}/${usershopid}/viewCardMasterReport/${fromdate}/${todate}`);
    //             setData(billResponse.data)            
    //             setIsLoading(false)
    //     } catch (error) {
    //         console.log("Data Not Found...");
    //     }
    // };
    useEffect(() => {
        axios.get(`${hostlink}/${queryCondition}/deptmas`)
            .then((Response) => { setdeptdata(Response.data) })
    }, [usershopid])

    const exportToExcel = () => {
        const ws = XLSX.utils.table_to_sheet(tableRef.current);
        const boldAndColorStyle = {
            font: { bold: true },
            fill: { fgColor: { rgb: "FFFF00" } }
        };
        const headerRow = XLSX.utils.decode_range(ws['!ref']).e.c;
        for (let c = 0; c <= headerRow; c++) {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c });
            if (ws[cellAddress].v === 'Sr No') {
                ws[cellAddress].s = boldAndColorStyle;
                for (let r = 1; r <= XLSX.utils.decode_range(ws['!ref']).e.r; r++) {
                    const dataCellAddress = XLSX.utils.encode_cell({ r, c });
                    if (ws[dataCellAddress]) {
                        ws[dataCellAddress].s = boldAndColorStyle;
                    }
                }
                break;
            }
        }
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, 'Reservation Report.xlsx');
    };

    return (
        <div className='m-1'>
            <div className='container p-3 shadow rounded' style={{ transition: 'box-shadow 0.3s' }}>
                <div className='row'>
                    <div className='col-md-6 align-self-center'>
                        <h5 className='font-monospace text-primary'> <i class="fa-regular fa-credit-card"></i> <span id='pagemode'> Card Recharge Report</span></h5>
                    </div>
                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="fromdate" className="form-label">From Date:</label>
                    </div>
                    <div className='col-md-2'>
                        <input type="date" id="fromdate" name='fromdate' onChange={(e) => { onChangevalue(e) }} value={fromdate} className="form-control" />
                    </div>
                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="todate" className="form-label">To Date:</label>
                    </div>
                    <div className='col-md-2'>
                        <input type="date" id="todate" name='todate' onChange={(e) => { onChangevalue(e) }} value={todate} className="form-control" />
                    </div>


                    <div className='col-md-6 align-self-center text-center'>

                    </div>

                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="fromdate" className="form-label">Mob No :</label>
                    </div>
                    <div className='col-md-2'>
                        <input type="text" id="mobno" name='mobno' onChange={(e) => { onChangevalue(e) }} value={mobno} className="form-control" />
                    </div>



                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="fromdate" className="form-label">Card No :</label>
                    </div>
                    <div className='col-md-2'>
                        <input type="text" id="cardno" name='cardno' onChange={(e) => { onChangevalue(e) }} value={cardno} className="form-control" />
                    </div>


                    <div className='col-md-12 align-self-center text-end mt-2'>
                        {/* <button onClick={() => { loaddata(0); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-magnifying-glass-chart"></i> Department-wise </button> */}
                        <button onClick={() => { loaddata(1); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-magnifying-glass-chart"></i> Show</button>
                        <button onClick={() => { exportToExcel(); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-table"></i> Excel</button>

                    </div>
                </div>
            </div>


            <br />
            {/* maxHeight: "400px", */}
            <div className='container-fluid' style={{ overflow: "auto" }}>
                <table className="table table-hover table-striped " ref={tableRef}>

                    <thead className='text-center' >
                        <tr >
                            <th scope="col">Sr No</th>
                            <th className='text-start' scope="col">Resh. Date</th>
                            <th className='text-start' scope="col">Time</th>
                            <th className='text-start' scope="col">Card No</th>
                            <th className='text-start' scope="col">Customer Name</th>
                            <th className='text-start' scope="col">Mobile No</th>
                            <th className='text-start' scope="col">Recharge Amount</th>
                            <th className='text-start' scope="col">MOP-1</th>
                            <th className='text-end' scope="col">MOP-1 Amt</th>
                            <th className='text-start' scope="col">MOP-2</th>
                            <th className='text-end' scope="col">MOP-2 Amt</th>
                            <th className='text-end' scope="col">No Of Person</th>
                            <th className='text-start' scope="col">Remarks</th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {data.map((res, x) => (
                            <tr key={x}>
                                <td>{x + 1}</td>
                                <td className='text-start'>{res.entrydate ? new Date(res.entrydate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</td>
                                <td className='text-start'>{res.entrytime}</td>
                                <td className='text-start'>{res.cardno}</td>
                                <td className='text-start'>{res.custname}</td>
                                <td className='text-start'>{res.mobno}</td>
                                <td className='text-start'>{res.amount}</td>
                                <td className='text-start'>{res.pay1}</td>
                                <td className='text-end'>{res.pay1amt}</td>
                                <td className='text-start'>{res.pay2}</td>
                                <td className='text-end'>{res.pay2amt}</td>
                                <td className='text-end'>{res.noofperson}</td>
                                <td className="text-start" style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
                                    {res.remarks ? res.remarks.replace(/(.{20})/g, "$1\n") : "-"}
                                </td>


                            </tr>
                        ))}
                        <tr class="table-info">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                <div>
    <h4>Total Transactions</h4>
    <table className="table" style={{ textAlign: "end", width: "400px" }}>
        <thead>
            <tr>
                <th>Account Name</th>
                <th>Total Amount</th>
                <th>Transactions</th>
            </tr>
        </thead>
        <tbody>
            {Object.keys(totalSums).map((accname) =>
                !accname.includes("(RETURN)") ? (
                    <tr key={accname}>
                        <td style={{ textAlign: "end", width: "150px" }}>{accname}</td>
                        <td style={{ textAlign: "end", width: "150px" }}>
                            {totalSums[accname].toFixed(2)}
                        </td>
                        <td style={{ textAlign: "end", width: "100px" }}>
                            {transactionCounts[accname] || 0}
                        </td>
                    </tr>
                ) : null
            )}
            <tr style={{ fontWeight: "bold", backgroundColor: "#f0f0f0" }}>
                <td style={{ textAlign: "end", width: "150px" }}>Total</td>
                <td style={{ textAlign: "end", width: "150px" }}>
                    {Object.keys(totalSums)
                        .filter((accname) => !accname.includes("(RETURN)"))
                        .reduce((sum, accname) => sum + totalSums[accname], 0)
                        .toFixed(2)}
                </td>
                <td style={{ textAlign: "end", width: "100px" }}>
                    {Object.keys(transactionCounts)
                        .filter((accname) => !accname.includes("(RETURN)"))
                        .reduce((sum, accname) => sum + transactionCounts[accname], 0)}
                </td>
            </tr>
        </tbody>
    </table>

    <h4>Return Transactions</h4>
    <table className="table" style={{ textAlign: "end", width: "400px" }}>
        <thead>
            <tr>
                <th>Account Name</th>
                <th>Return Amount</th>
                <th>Transactions</th>
            </tr>
        </thead>
        <tbody>
            {Object.keys(totalSums).map((accname) =>
                accname.includes("(RETURN)") ? (
                    <tr key={accname}>
                        <td style={{ textAlign: "end", width: "150px" }}>{accname}</td>
                        <td style={{ textAlign: "end", width: "150px" }}>
                            {Math.abs(totalSums[accname]).toFixed(2)}
                        </td>
                        <td style={{ textAlign: "end", width: "100px" }}>
                            {transactionCounts[accname] || 0}
                        </td>
                    </tr>
                ) : null
            )}
            <tr style={{ fontWeight: "bold", backgroundColor: "#f0f0f0" }}>
                <td style={{ textAlign: "end", width: "150px" }}>Total</td>
                <td style={{ textAlign: "end", width: "150px" }}>
                    {Object.keys(totalSums)
                        .filter((accname) => accname.includes("(RETURN)"))
                        .reduce((sum, accname) => sum + Math.abs(totalSums[accname]), 0)
                        .toFixed(2)}
                </td>
                <td style={{ textAlign: "end", width: "100px" }}>
                    {Object.keys(transactionCounts)
                        .filter((accname) => accname.includes("(RETURN)"))
                        .reduce((sum, accname) => sum + transactionCounts[accname], 0)}
                </td>
            </tr>
        </tbody>
    </table>
</div>

<br /><br />

            </div>
            {isLoading && <Loading />}
        </div>
    )
}


export default Rpt_Card_Recharge_Report