import axios from 'axios';
import React, { useRef, useState } from 'react'
import hostlink from '../Hostlink/hostlink';
import Loading from './Loading'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const OurClint = () => {
    const [isLoading, setIsLoading] = useState(false);
    const today = new Date();
    const tableRef = useRef(null);
    const [frmdata, setfrmdata] = useState({
        fromdate: today.toISOString().split('T')[0],
        todate: today.toISOString().split('T')[0],
        password:""
    });
    const { fromdate, todate,password } = frmdata;
    const [data, setData] = useState([]);
    const loaddata = async (e) => {
        try {
            
            if(password !="mpka140"){
                toast.error("Password not valid..")
                return false;
            }
            setIsLoading(true);
            // const billResponse = await axios.get(`${hostlink}/api/executeQuery?sqlQuery=SELECT s.id, s.cname,s.startdate, COALESCE(SUM(DISTINCT sm.totblamt), 0) AS total_sales,(select chdate from checkinroomdetails where shopid = s.id order by id desc limit 1) as lastcheckin,(select bldate from billmas where shopid = s.id order by id desc limit 1) as lastcheckOut,(select blamt from billmas where shopid = s.id order by id desc limit 1) as lastBillAmt,(select guestname from billmas where shopid = s.id order by id desc limit 1) as lastGuestName,(select shopvno from kotmas where shopid = s.id order by id desc limit 1) as lastKot,(select shopvno from salemas where shopid = s.id order by id desc limit 1) as lastBillrest,(select totblamt from salemas where shopid = s.id order by id desc limit 1) as lastBillamtrest,(select bldate from salemas where shopid = s.id order by id desc limit 1) as lastBillDate FROM shopmas s LEFT JOIN salemas sm ON sm.shopid = s.id  AND sm.bldate BETWEEN '${fromdate}' and '${todate}' AND sm.shopvno = (SELECT MIN(shopvno) FROM salemas WHERE shopid = sm.shopid AND bldate BETWEEN '${fromdate}' and '${todate}') GROUP BY s.id, s.cname ORDER BY s.id DESC`);


            const sqlQuery =`
                SELECT 
    s.id, 
    s.cname, 
    s.startdate, 
    COALESCE(t.total_sales, 0) AS total_sales, 
    c.lastcheckin, 
    b.lastcheckOut, 
    b.lastBillAmt, 
    b.lastGuestName, 
    k.lastKot, 
    sb.lastBillrest, 
    sb.lastBillamtrest, 
    sb.lastBillDate, 
    s.userstatus 
FROM shopmas s 

LEFT JOIN (
    SELECT shopid, SUM(totblamt) AS total_sales
    FROM (
        SELECT shopid, MAX(totblamt) AS totblamt 
        FROM salemas
        GROUP BY shopid, shopvno
    ) sub
    GROUP BY shopid
) t ON t.shopid = s.id


LEFT JOIN (
    SELECT shopid, chdate AS lastcheckin
    FROM checkinroomdetails
    WHERE id IN (SELECT MAX(id) FROM checkinroomdetails GROUP BY shopid)
) c ON c.shopid = s.id


LEFT JOIN (
    SELECT shopid, bldate AS lastcheckOut, blamt AS lastBillAmt, guestname AS lastGuestName
    FROM billmas
    WHERE id IN (SELECT MAX(id) FROM billmas GROUP BY shopid)
) b ON b.shopid = s.id


LEFT JOIN (
    SELECT shopid, shopvno AS lastKot
    FROM kotmas
    WHERE id IN (SELECT MAX(id) FROM kotmas GROUP BY shopid)
) k ON k.shopid = s.id


LEFT JOIN (
    SELECT shopid, shopvno AS lastBillrest, totblamt AS lastBillamtrest, bldate AS lastBillDate
    FROM salemas
    WHERE id IN (SELECT MAX(id) FROM salemas GROUP BY shopid)
) sb ON sb.shopid = s.id

ORDER BY s.id DESC;
              `
              const billResponse = await axios.post(`${hostlink}/api/executeQueryNew`, { sqlQuery });
            const billData = billResponse.data;
            setData(billResponse.data)
            setIsLoading(false);

        } catch (error) {
            console.log("Data Not Found...");
        }
    };
    const updateuserstatus = (id, status) => {
        const newStatus = status === 1 ? 0 : 1; // Toggle status (1 -> 0, 0 -> 1)
        axios.post(`${hostlink}/UserShopStatus/${id}/${newStatus}`)
            .then((Response) => {
                toast.success("User Updated..")
                loaddata()
            })
            .catch((res) => { console.log("Data Not find") })
    }
    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setfrmdata({ ...frmdata, [name]: sanitizedValue });
    };

    return (
        <div>

            <div className='container'>
                <div className="row mt-2">
                    <div className='col-12 col-md-2 align-self-start text-start'>
                        <h3>Clint List...</h3>
                    </div>
                    <div className='col-6 col-md-1 align-self-center text-end'>
                        <label htmlFor="fromdate" className="form-label">From Date</label>
                    </div>
                    <div className='col-6 col-md-2'>
                        <input type="date" id="fromdate" name='fromdate' onChange={(e) => { onChangevalue(e) }} value={fromdate} className="form-control" />
                    </div>
                    <div className='col-6 col-6 col-md-1 align-self-center text-end'>
                        <label htmlFor="todate" className="form-label">To Date</label>
                    </div>
                    <div className='col-6 col-md-2'>
                        <input type="date" id="todate" name='todate' onChange={(e) => { onChangevalue(e) }} value={todate} className="form-control" />
                    </div>
                    <div className='col-6 col-md-2'>
                        <input type="password" id="password" name='password' onChange={(e) => { onChangevalue(e) }} value={password} className="form-control" />
                    </div>
                    <div className='col-3 col-md-1 align-self-center text-end'>
                        <button onClick={() => { loaddata(1); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-magnifying-glass-chart"></i> Show </button>
                    </div>
                </div>
            </div>

            <div className='container-fluid' style={{ overflow: "auto" }}>
                <table className="table table-hover table-striped " ref={tableRef}>

                    <thead className='text-center' >
                        <tr >
                            <th className='text-start' scope="col">ID</th>
                            <th className='text-start' scope="col">Create Date</th>
                            <th className='text-start' scope="col">Company Name</th>
                            <th className='text-end' scope="col">Restaurant Details...</th>
                            <th className='text-end' scope="col">Hotel Detail...</th>
                            <th className='text-end' scope="col">Status</th>

                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {data.map((res, x) => {
                            return (
                                <tr key={x}>
                                    <td className='text-start'>{res[0]}</td>
                                    <td className='text-start'>{res[2] ? new Date(res[2]).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"}</td>
                                    <td className='text-start'>{res[1]}</td>
                                    <td className='text-end'>
                                        Sale Amount : {res[3]}<br />
                                        Last Kot No : {res[8]}<br />
                                        Last Bill No : {res[9]}<br />
                                        Last Bill-amount : {res[10]}<br />
                                        Last Bill-Date : {res[11] ? new Date(res[11]).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"}<br />
                                    </td>
                                    <td className='text-end'>
                                        Last Check-in Date :	{res[4] ? new Date(res[4]).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"} <br />
                                        Last Check-out Date :	{res[5] ? new Date(res[5]).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"}<br />
                                        Last Bill-amount :	  {res[6]}<br />
                                        Guest Name :	 {res[7]}   <br />
                                    </td>
                                    <td className='text-end'><button onClick={(e) => { updateuserstatus(res[0], res[12]) }} className='btn btn-outline-primary'>{res[12] === 1 ? 'Deactive' : `Active`}</button></td>
                                </tr>
                            );
                        })}

                        <tr class="table-info">
                            <td className='text-end'><h6></h6></td>
                            <td className='text-end'><h6></h6></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {isLoading && <Loading />}
            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    )
}

export default OurClint