import axios from 'axios';
import React, { useRef, useState } from 'react'
import hostlink from '../Hostlink/hostlink';
import Loading from './Loading'
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const OurClintRetail = () => {
    const [isLoading, setIsLoading] = useState(false);
    const today = new Date();
    const tableRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [messagedata, setMessagedata] = useState({
        deleteid: "",
        deletemsg: "",
        updatemessage: "",
        password:""
    })
    const { deleteid, deletemsg, updatemessage,password } = messagedata;


    const handleOpenModal = (e, flname, updatemessage) => {
        setMessagedata({ ...messagedata, deleteid: e, deletemsg: flname, updatemessage: updatemessage })
        setIsModalOpen(true);
    };
    const handleCloseModal = () => { setIsModalOpen(false); };
    const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); };

    const [frmdata, setfrmdata] = useState({
        fromdate: today.toISOString().split('T')[0],
        todate: today.toISOString().split('T')[0],
    });
    const { fromdate, todate} = frmdata;
    const [data, setData] = useState([]);
    const loaddata = async (e) => {
        try {

            if (password != "mpka140") {
                toast.error("Password not valid..")
                return false;
            }
            setIsLoading(true);
            // const billResponse = await axios.get(`${hostlink}/api/executeQuery?sqlQuery=SELECT s.id, s.cname,s.startdate, COALESCE(SUM(DISTINCT sm.totblamt), 0) AS total_sales,(select chdate from checkinroomdetails where shopid = s.id order by id desc limit 1) as lastcheckin,(select bldate from billmas where shopid = s.id order by id desc limit 1) as lastcheckOut,(select blamt from billmas where shopid = s.id order by id desc limit 1) as lastBillAmt,(select guestname from billmas where shopid = s.id order by id desc limit 1) as lastGuestName,(select shopvno from kotmas where shopid = s.id order by id desc limit 1) as lastKot,(select shopvno from salemas where shopid = s.id order by id desc limit 1) as lastBillrest,(select totblamt from salemas where shopid = s.id order by id desc limit 1) as lastBillamtrest,(select bldate from salemas where shopid = s.id order by id desc limit 1) as lastBillDate FROM shopmas s LEFT JOIN salemas sm ON sm.shopid = s.id  AND sm.bldate BETWEEN '${fromdate}' and '${todate}' AND sm.shopvno = (SELECT MIN(shopvno) FROM salemas WHERE shopid = sm.shopid AND bldate BETWEEN '${fromdate}' and '${todate}') GROUP BY s.id, s.cname ORDER BY s.id DESC`);


            const sqlQuery = `SELECT id,startdate,cname,messagedata from retailposmas`
            const billResponse = await axios.post(`${hostlink}/api/executeQueryNew`, { sqlQuery });
            const billData = billResponse.data;
            setData(billResponse.data)
            setIsLoading(false);

        } catch (error) {
            console.log("Data Not Found...");
            setIsLoading(false);
        }
    };
    const deleteData = async (e, flname) => {
        try {
            const nameQuery = `update retailposmas set messagedata = '${updatemessage}' where id = '${deleteid}'`;
            const nameResponse = await axios.post(`${hostlink}/api/executeUpdate?sqlQuery=${encodeURIComponent(nameQuery)}`);
            if (nameResponse.data == 'Rows affected: 1') {
                toast.success("Update success..")
                handleCloseModal();
                loaddata()
            } else {
                toast.error("Something went wrong..");
                return false
            }

        } catch (error) { }
    }
    const updateuserstatus = (id, status) => {

    }
    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setMessagedata({ ...messagedata, [name]: sanitizedValue });
    };

    return (
        <div>

            <div className='container'>
                <div className="row mt-2">
                    <div className='col-12 col-md-2 align-self-start text-start'>
                        <h3>Clint List...</h3>
                    </div>
                    <div className='col-6 col-md-1 align-self-center text-end'>
                        {/* <label htmlFor="fromdate" className="form-label">From Date</label> */}
                    </div>
                    <div className='col-6 col-md-2'>
                        {/* <input type="date" id="fromdate" name='fromdate' onChange={(e) => { onChangevalue(e) }} value={fromdate} className="form-control" /> */}
                    </div>
                    <div className='col-6 col-6 col-md-1 align-self-center text-end'>
                        {/* <label htmlFor="todate" className="form-label">To Date</label> */}
                    </div>
                    <div className='col-6 col-md-2'>
                        {/* <input type="date" id="todate" name='todate' onChange={(e) => { onChangevalue(e) }} value={todate} className="form-control" /> */}
                    </div>
                    <div className='col-6 col-md-2'>
                        <input type="password" id="password" name='password' onChange={(e) => { onChangevalue(e) }} value={password} className="form-control" />
                    </div>
                    <div className='col-3 col-md-1 align-self-center text-end'>
                        <button onClick={() => { loaddata(1); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-magnifying-glass-chart"></i> Show </button>
                    </div>
                </div>
            </div>

            <div className='container-fluid' style={{ overflow: "auto" }}>
                <table className="table table-hover table-striped " ref={tableRef}>

                    <thead className='text-center' >
                        <tr >
                            <th className='text-start' scope="col">ID</th>
                            <th className='text-start' scope="col">Create Date</th>
                            <th className='text-start' scope="col">Company Name</th>
                            <th className='text-end' scope="col">Update</th>
                            <th className='text-end' scope="col">Status</th>

                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {data.map((res, x) => {
                            return (
                                <tr key={x}>
                                    <td className='text-start'>{res[0]}</td>
                                    <td className='text-start'>{res[1] ? new Date(res[1]).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"}</td>
                                    <td className='text-start'>{res[2]}</td>
                                    <td className='text-end'>{res[3]}</td>
                                    <td className='text-end'><button onClick={(e) => { handleOpenModal(res[0], res[2], res[3]) }} className='btn btn-outline-danger'>Change</button></td>
                                </tr>
                            );
                        })}

                        <tr class="table-info">
                            <td className='text-end'><h6></h6></td>
                            <td className='text-end'><h6></h6></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                <div>
                    <p>Are you sure you want to Update this <br /> : {deletemsg} ?</p>
                    <input type="text" onChange={(e) => { onChangevalue(e) }} name='updatemessage' value={updatemessage} class="form-control" id="updatemessage" maxLength={8} /> <br />

                    <div className="button-container"><button className="yes-button" onClick={(e) => { deleteData(deleteid) }}>Update</button> <button className="no-button" onClick={handleNoClick}>Cancel</button></div>
                </div>
            </Modal>
            {isLoading && <Loading />}
            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    )
}

export default OurClintRetail