import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import hostlink from "../Hostlink/hostlink";
import { UserLoginDetails } from "../Hostlink/UserLoginDetails";

const EmployeeAttendance = () => {
  const { appState } = useContext(UserLoginDetails);
  const usershopid = appState?.[0]?.id || 0;

  const [employees, setEmployees] = useState([]);
  const [attendance, setAttendance] = useState([]);
  const [selectedDate, setSelectedDate] = useState(); // Default to today

  useEffect(() => {
    fetchEmployees();
    fetchAttendance(selectedDate);
  }, [selectedDate]);

  const fetchEmployees = async () => {
    try {
      const response = await axios.get(`${hostlink}/${usershopid}/empmas`);
      setEmployees(response.data);

      const initialAttendance = response.data.map(emp => ({
        employeeId: emp.id,
        date: selectedDate,
        inTime: "",
        outTime: "",
        status: "Absent",
      }));
      setAttendance(initialAttendance);
    } catch (error) {
      console.error("Error fetching employees", error);
    }
  };

  const fetchAttendance = async (date) => {
    try {
      const response = await axios.get(`${hostlink}/attendance/by-date?date=${date}`);
      const attendanceData = response.data.map(a => ({
        employeeId: a.employee.id,
        date: a.date,
        inTime: a.inTime || "",
        outTime: a.outTime || "",
        status: a.status || "Absent"
      }));
      setAttendance(attendanceData);
    } catch (error) {
      console.error("Error fetching attendance", error);
    }
  };

  const handleInputChange = (employeeId, field, value) => {
    setAttendance(prevState =>
      prevState.map(a =>
        a.employeeId === employeeId ? { ...a, [field]: value } : a
      )
    );
  };

  const handleSave = async () => {
    try {
      const formattedAttendance = attendance.map(a => ({
        employeeid: a.employeeId,  // Must match Java field name!
        attdate: a.date,
        inTime: a.inTime,
        outTime: a.outTime,
        status: a.status,
        shopid:usershopid
      }));
      console.log(formattedAttendance);
      await axios.post(`${hostlink}/attendance/save-all`, formattedAttendance);
      alert("Attendance saved successfully.");
    } catch (error) {
      console.error("Error saving attendance", error);
    }
  };
  
  

  const markAllAs = (status) => {
    setAttendance(prevState =>
      prevState.map(a => ({ ...a, status }))
    );
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold text-center mb-4">Employee Attendance</h2>

      <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-4">
        <div>
          <label className="font-semibold mr-2">Select Date:</label>
          <input 
            type="date" 
            value={selectedDate} 
            onChange={(e) => setSelectedDate(e.target.value)} 
            className="border rounded p-2"
          />
        </div>
        <div className="flex gap-2">
          <button 
            onClick={() => markAllAs("Present")} 
            className="bg-green-500 px-4 py-2 rounded shadow-md hover:bg-green-600"
          >
            Mark All Present
          </button>
          <button 
            onClick={() => markAllAs("Absent")} 
            className="bg-red-500 px-4 py-2 rounded shadow-md hover:bg-red-600"
          >
            Mark All Absent
          </button>
          <button 
            onClick={() => markAllAs("Holiday")} 
            className="bg-yellow-500 px-4 py-2 rounded shadow-md hover:bg-yellow-600"
          >
            Mark All Holiday
          </button>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr className="bg-gray-200 text-gray-700">
              <th className="p-2 border">ID</th>
              <th className="p-2 border">Employee Name</th>
              <th className="p-2 border">Date</th>
              <th className="p-2 border">In Time</th>
              <th className="p-2 border">Out Time</th>
              <th className="p-2 border">Status</th>
            </tr>
          </thead>
          <tbody>
            {employees.map((emp) => (
              <tr key={emp.id} className="text-center border-t hover:bg-gray-100">
                <td className="p-2 border">{emp.id}</td>
                <td className="p-2 border">{emp.empname}</td>
                <td className="p-2 border">{selectedDate}</td>
                <td className="p-2 border">
                  <input
                    type="time"
                    value={attendance.find(a => a.employeeId === emp.id)?.inTime || ""}
                    onChange={(e) => handleInputChange(emp.id, "inTime", e.target.value)}
                    className="border rounded p-1"
                  />
                </td>
                <td className="p-2 border">
                  <input
                    type="time"
                    value={attendance.find(a => a.employeeId === emp.id)?.outTime || ""}
                    onChange={(e) => handleInputChange(emp.id, "outTime", e.target.value)}
                    className="border rounded p-1"
                  />
                </td>
                <td className="p-2 border">
                  <select
                    value={attendance.find(a => a.employeeId === emp.id)?.status || "Absent"}
                    onChange={(e) => handleInputChange(emp.id, "status", e.target.value)}
                    className="border rounded p-1 bg-gray-100"
                  >
                    <option value="Present">Present</option>
                    <option value="Absent">Absent</option>
                    <option value="Holiday">Holiday</option>
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex justify-end mt-4">
        <button 
          onClick={handleSave} 
          className="bg-blue-500 px-4 py-2 rounded shadow-md hover:bg-blue-600"
        >
          Save Attendance
        </button>
      </div>
    </div>
  );
};

export default EmployeeAttendance;
