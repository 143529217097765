import React, { useEffect, useRef, useState, useContext } from 'react';
import axios from 'axios';
import hostlink from '../Hostlink/hostlink';
import * as XLSX from 'xlsx';
import { PDFDownloadLink, PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import Loading from '../Component/Loading';
import { UserLoginDetails } from '../Hostlink/UserLoginDetails';

const Rpt_Stock_Report = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const usershopidref = appState?.[0]?.hotelshopid || 0;
    const usershopdeptcode = appState?.[0]?.deptcode || 0;
    const [query, setQuery] = useState('');
    const inputRef = useRef(null);
    const [listVisible, setListVisible] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const handleFocus = (e) => {
        // setTimeout(() => {
        //     e.target.select(); // Select the input value when it receives focus
        // }, 0);
    };
    const handleKeyDown = (event) => {
        if (document.getElementById("inputname1111").value == "") {
            return false
        }
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            setSelectedIndex(prevIndex => (prevIndex < filteredData.length - 1 ? prevIndex + 1 : prevIndex));
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            setSelectedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
        } else if (event.key === 'Enter' && selectedIndex !== -1) {
            handleSelectItem(filteredData[selectedIndex], selectedIndex);
            setQuery(filteredData[selectedIndex].itname);
            const queryCondition = filteredData[selectedIndex].ispackage == 0
                ? filteredData[selectedIndex].restrate
                : 0;

            setfrmdata({
                ...frmdata,
                rawcode: filteredData[selectedIndex].id,
            });

        }
    };
    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setQuery(inputValue);
        filterData(inputValue);
        setListVisible(true);
        setSelectedIndex(-1);
    };
    const filterData = (query) => {
        if (!query) {
            setFilteredData([]);
            return;
        }

        const filtered = DataItem.filter(item => {
            return item.itname && item.itname.toLowerCase().includes(query.toLowerCase());
        }).slice(0, 5); // Limit to first 5 records after filtering

        setFilteredData(filtered);
    };

    useEffect(() => {
        fetchData();
    }, [usershopid]);
    const fetchData = async () => {
        const response = await fetch(`${hostlink}/ItemViewAll/${usershopid}/${queryCondition}/${usershopdeptcode}/${today.toISOString().split('T')[0]}/${today.toISOString().split('T')[0]}/0/0`);
        const jsonData = await response.json();
        setDataItem(jsonData);
        // if (usershopidref == 0) {
        //     const response = await fetch(`${hostlink}/${usershopid}/item`);
        //     const jsonData = await response.json();
        //     setDataItem(jsonData);
        // } else {
        //     const response = await fetch(`${hostlink}/${queryCondition}/${deptcode}/storeitem`);
        //     const jsonData = await response.json();
        //     setDataItem(jsonData);
        // }
    };
    const handleItemClick = (index) => {
        const queryCondition = filteredData[index].ispackage == 0
            ? filteredData[index].restrate
            : 0;

        setSelectedIndex(index);
        handleSelectItem(filteredData[index], index);
        setQuery(filteredData[index].itname);
        setfrmdata({
            ...frmdata,
            rawcode: filteredData[index].id,
        });

    };
    const handleSelectItem = (item, index) => {
        setSelectedItem(item);
        setQuery(item.itname);
        setListVisible(false);
        setSelectedIndex(index);
    };
    const queryCondition = usershopidref == 0
        ? `${usershopid}`
        : `${usershopidref}`;
    const [RoomType, setRoomType] = useState([]);
    const [Room, setRoom] = useState([]);
    const [deptdata, setdeptdata] = useState([])
    const [itgdata, setitgdata] = useState([])
    const [DataItem, setDataItem] = useState([]);
    const [data, setData] = useState([]);
    const today = new Date();

    const [frmdata, setfrmdata] = useState({
        fromdate: today.toISOString().split('T')[0],
        todate: today.toISOString().split('T')[0],
        deptcode: usershopdeptcode,
        status: 0,
        itgcode: 0,
        rawcode: 0,
    });
    const [totalSums, setTotalSums] = useState({});

    const { fromdate, todate, status, deptcode, itgcode, rawcode } = frmdata;
    const tableRef = useRef(null);
    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setfrmdata({ ...frmdata, [name]: sanitizedValue });
    };
    const loaddata = async (e) => {
        const queryConditionItem = document.getElementById("inputname1111").value === "" ? 0 : rawcode;

        setIsLoading(true)
        try {
            console.log(`${hostlink}/StockReportNewStatus/${usershopid}/${queryCondition}/${deptcode}/${fromdate}/${todate}/${queryConditionItem}/${itgcode}`)
            const billResponse = await axios.get(`${hostlink}/StockReportNewStatus/${usershopid}/${queryCondition}/${deptcode}/${fromdate}/${todate}/${queryConditionItem}/${itgcode}`);
            const billData = billResponse.data;
            setData(billResponse.data)
            console.log(billResponse.data)
            setIsLoading(false)
        } catch (error) {
            console.log("Data Not Found...");
            setIsLoading(false)
        }
    };
    useEffect(() => {
        if(usershopid!=0){
            axios.get(`${hostlink}/${queryCondition}/deptmas`)
            .then((Response) => { setdeptdata(Response.data) })
        axios.get(`${hostlink}/${queryCondition}/ItemGroup`)
            .then((Response) => { setitgdata(Response.data) })
        setfrmdata({
            ...frmdata,
            deptcode: usershopdeptcode,
        });

        }

    }, [usershopid,queryCondition])

    const exportToExcel = () => {
        const ws = XLSX.utils.table_to_sheet(tableRef.current);
        const boldAndColorStyle = {
            font: { bold: true },
            fill: { fgColor: { rgb: "FFFF00" } }
        };
        const headerRow = XLSX.utils.decode_range(ws['!ref']).e.c;
        for (let c = 0; c <= headerRow; c++) {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c });
            if (ws[cellAddress].v === 'Sr No') {
                ws[cellAddress].s = boldAndColorStyle;
                for (let r = 1; r <= XLSX.utils.decode_range(ws['!ref']).e.r; r++) {
                    const dataCellAddress = XLSX.utils.encode_cell({ r, c });
                    if (ws[dataCellAddress]) {
                        ws[dataCellAddress].s = boldAndColorStyle;
                    }
                }
                break;
            }
        }
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, 'Stock Report.xlsx');
    };

    return (
        <div className='m-1'>
            <div className='container p-3 shadow rounded' style={{ transition: 'box-shadow 0.3s' }}>
                <div className='row'>
                    <div className='col-md-3 align-self-center'>
                        <h5 className='font-monospace text-primary'> <i class="fa-solid fa-bars-staggered"></i> <span id='pagemode'> Stock Report</span></h5>
                    </div>
                    <div className='col-6 col-md-1 align-self-center text-end'>
                        <label htmlFor="fromdate" className="form-label">From Date </label>
                    </div>
                    <div className='col-6 col-md-2'>
                        <input type="date" id="fromdate" name='fromdate' onChange={(e) => { onChangevalue(e) }} value={fromdate} className="form-control" />
                    </div>
                    <div className='col-6 col-md-1 align-self-center text-end'>
                        <label htmlFor="todate" className="form-label">To Date </label>
                    </div>
                    <div className='col-6 col-md-2'>
                        <input type="date" id="todate" name='todate' onChange={(e) => { onChangevalue(e) }} value={todate} className="form-control" />
                    </div>
                    <div className='col-6 col-md-1 align-self-end text-end'>
                        <label htmlFor="roomtype" className="form-label">Department </label>
                    </div>
                    <div className="col-6 col-md-2 text-start-0 align-self-center">
                        <select id="deptcode" name='deptcode' value={deptcode} onChange={(e) => { onChangevalue(e) }} class="form-select">
                            {deptdata.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}> {x.deptname} </option>
                                )
                            })}
                        </select>
                    </div>
                    <div className='col-12 col-md-1 align-self-end text-end'>

                    </div>

                    <div className='col-6 col-md-3 align-self-center text-end'>
                        <label htmlFor="roomtype" className="form-label">Item Group </label>
                    </div>
                    <div className="col-6 col-md-2 text-start-0 align-self-center">
                        <select id="itgcode" name='itgcode' value={itgcode} onChange={(e) => { onChangevalue(e) }} class="form-select">
                            <option selected key={0} value={0}>All</option>
                            {itgdata.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}> {x.groupname} </option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="col-md-3 mt-2">
                        <input type="text" placeholder="Search for items..." class="form-control" value={query} id="inputname1111" onFocus={handleFocus} onChange={handleInputChange} onKeyDown={handleKeyDown} autoComplete='off' ref={inputRef} /> <br />
                        {listVisible && filteredData.length > 0 && (
                            <ul style={{}}>
                                {filteredData.map((item, index) => (
                                    <li
                                        key={item.id}
                                        onClick={() => handleSelectItem(item, index)}
                                        onMouseDown={() => handleItemClick(index)}

                                        className={index === selectedIndex ? 'selected' : ''}
                                    >
                                        {item.itname}
                                    </li>
                                ))}
                            </ul>
                        )}

                    </div>
                    <div className='col-12 col-md-2 align-self-center text-end mt-2'>
                        {/* <button onClick={() => { loaddata(0); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-magnifying-glass-chart"></i> Department-wise </button> */}
                        <button onClick={() => { loaddata(1); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-magnifying-glass-chart"></i> Show</button>
                        <button onClick={() => { exportToExcel(); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-table"></i> Excel</button>
                        {/* <PDFDownloadLink document={<PDFDocument data={data} fromdate={fromdate} todate={todate} />} fileName="Stock Report.pdf">
                            {({ blob, url, loading, error }) =>
                                loading ? 'Loading' : <button className='btn btn-sm btn-outline-primary' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-regular fa-file-pdf"></i> PDF</button>
                            }
                        </PDFDownloadLink> */}
                    </div>
                </div>
            </div>


            <br />
            {/* maxHeight: "400px", */}
            <div className='container-fluid' style={{ overflow: "auto" }}>
                <table className="table table-hover table-striped " ref={tableRef}>

                    <thead className='text-center' >
                        <tr >
                            <th scope="col">Sr No</th>
                            <th className='text-start' scope="col">Item Name</th>
                            <th className='text-end' scope="col">Opening</th>
                            <th className='text-end' scope="col">Stock In</th>
                            <th className='text-end' scope="col">Stock Out</th>
                            <th className='text-end' scope="col">Closing</th>
                            <th className='text-end' scope="col">Unit</th>
                            <th className='text-end' scope="col">Sale Rate</th>
                            <th className='text-end' scope="col">Amount</th>
                            <th className='text-end' scope="col">Purc Rate</th>
                            <th className='text-end' scope="col">Amount</th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {data.map((res, x) => (
                            <tr key={x}>
                                <td>{x + 1}</td>
                                <td className='text-start'>{res.itname}</td>
                                <td className='text-end'>{Number(res.Opening).toFixed(3)}</td>
                                <td className='text-end'>{Number(res.InStk).toFixed(3)}</td>
                                <td className='text-end'>{Number(res.InOut * -1).toFixed(3)}</td>
                                <td className='text-end'>{Number(res.ClStock).toFixed(3)}</td>
                                <td className='text-end'>{res.unitname}</td>
                                <td className='text-end'>{res.Rate}</td>
                                <td className='text-end'>{Number(res.ClStock * res.Rate).toFixed(2)}</td>
                                <td className='text-end'>{res.lastPurchaseRate}</td>
                                <td className='text-end'>{Number(res.ClStock * res.lastPurchaseRate).toFixed(2)}</td>
                            </tr>

                        ))}
                        <tr class="table-info">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className='text-end'><h6>Total QTY:</h6></td>
                            <td className='text-end'><h6>{Number(data.reduce((total, res) => total + parseFloat(Number(res.ClStock)), 0)).toFixed(2)}</h6></td>
                            <td></td>
                            <td></td>
                            <td className='text-end'><h6>{Number(data.reduce((total, res) => total + parseFloat(Number(res.ClStock * res.Rate).toFixed(2)), 0)).toFixed(2)}</h6></td>
                            <td></td>
                            <td className='text-end'><h6>{Number(data.reduce((total, res) => total + parseFloat(Number(res.ClStock * res.lastPurchaseRate).toFixed(2)), 0).toFixed(2))}</h6></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {isLoading && <Loading />}
        </div>
    )
}
// const PDFDocument = ({ data, fromdate, todate }) => {
//     // Calculate total bill amount
//     // const totalBillAmount = data.reduce((total, res) => total + parseFloat(res.SaleMasDTO.totblamt), 0);
//     return (
//         <Document>
//             <Page orientation="landscape">
//                 <View style={styles.container}>
//                     <Text style={styles.title}>Stock Report</Text>
//                     <Text style={styles.title}>From : {fromdate} To : {todate}</Text>
//                     <View style={styles.table}>
//                         <View style={styles.tableRow}>
//                             <Text style={styles.headerCell}>Sr No</Text>
//                             <Text style={styles.headerCell}>Item Name</Text>
//                             <Text style={styles.headerCell}>Opening</Text>
//                             <Text style={styles.headerCell}>Stock In</Text>
//                             <Text style={styles.headerCell}>Stock Out</Text>
//                             <Text style={styles.headerCell}>Closing</Text>
//                             <Text style={styles.headerCell}>Unit</Text>
//                             <Text style={styles.headerCell}>Sale Rate</Text>
//                             <Text style={styles.headerCell}>Amount</Text>
//                             <Text style={styles.headerCell}>Purc Rate</Text>
//                             <Text style={styles.headerCell}>Amount</Text>
//                         </View>
//                         {/* {data.map((res, x) => (
//                             <View key={x} style={styles.tableRow}>
//                                 <Text style={styles.cell}>{x + 1}</Text>
//                                 <Text style={styles.cell}>{res.itname}</Text>
//                                 <Text style={styles.cell}>{res.Opening}</Text>
//                                 <Text style={styles.cell}>{res.InStk}</Text>
//                                 <Text style={styles.cell}>{res.InOut}</Text>
//                                 <Text style={styles.cell}>{res.ClStock}</Text>
//                                 <Text style={styles.cell}>{res.unitname}</Text>
//                                 <Text style={styles.cell}>{res.Rate}</Text>
//                                 <Text style={styles.cell}>{Number(res.ClStock * res.Rate).toFixed(2)}</Text>
//                                 <Text style={styles.cell}>{res.lastPurchaseRate}</Text>
//                                 <Text style={styles.cell}>{Number(res.ClStock * res.lastPurchaseRate).toFixed(2)}</Text>
//                             </View>
//                         ))} */}
//                         <View style={styles.tableRow}>
//                             <Text style={styles.cell}></Text>
//                             <Text style={styles.cell}></Text>
//                             <Text style={styles.cell}></Text>
//                             <Text style={styles.cell}></Text>
//                             <Text style={styles.cell}>Total :</Text>
//                             <Text style={styles.cell}>{data.reduce((total, res) => total + parseFloat(Number(res.Closing)), 0)}</Text>
//                             <Text style={styles.cell}></Text>
//                             <Text style={styles.cell}></Text>
//                             <Text style={styles.cell}>{data.reduce((total, res) => total + parseFloat(Number(res.Closing * res.rate).toFixed(2)), 0)}</Text>
//                             <Text style={styles.cell}></Text>
//                             <Text style={styles.cell}>{data.reduce((total, res) => total + parseFloat(Number(res.Closing * res.lastPurchaseRate).toFixed(2)), 0)}</Text>
//                         </View>
//                     </View>
//                 </View>
//             </Page>
//         </Document>
//     );
// };

const styles = StyleSheet.create({
    container: {
        padding: 20,
    },
    title: {
        fontSize: 16,
        fontStyle: 'Times New Roman',
        marginBottom: 20,
        textAlign: 'center',
    },
    table: {
        display: 'table',
        width: '100%',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#000',
    },
    headerCell: {
        fontSize: 8,
        fontWeight: 'bold',
        padding: 5,
        flex: 1,
        fontStyle: 'Times New Roman',
        textAlign: 'center',
    },
    cell: {
        fontSize: 8,
        padding: 5,
        flex: 1,
        fontStyle: 'Times New Roman',
        textAlign: 'center',
    },
});



export default Rpt_Stock_Report