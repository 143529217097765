import React, { useEffect, useRef, useState, useContext } from 'react'
import hostlink from '../Hostlink/hostlink';

import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import numberToWords from 'number-to-words';
import { UserLoginDetails } from '../Hostlink/UserLoginDetails';
const CardView = () => {
    const roomCodeRef = useRef(null);
    const [FoodPackage, setFoodPackage] = useState([])
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const usershopidref = appState?.[0]?.hotelshopid || 0;
    const fs_cname = appState?.[0]?.fs_cname || 36;    
    const scrollRef = useRef(null);
    

    const queryCondition = usershopidref == 0
        ? `${usershopid}`
        : `${usershopidref}`;

    const [itemdetailGridRefresh, setitemdetailGridRefresh] = useState(true)
    const [editDeleteAPi, seteditDeleteAPi] = useState('');
    const [query, setQuery] = useState('');
    const [data, setData] = useState([]);
    const [srvdata, setsrvData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [listVisible, setListVisible] = useState(false);
    const inputRef = useRef(null);


    const printdata = async (e) => {
        try {
            console.log(`${hostlink}/CardMaster/${e}`);
            const response = await axios.get(`${hostlink}/CardMaster/${e}`);
            if (response) {
                const printContent = generatePrintContent(response.data);
                const data = `1,${hostlink}/CardMaster/${e},${printContent}`;
                
                // Check if ReactNativeWebView is available
                if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(data);
                    return false;
                }
                // Create a new print window (iframe instead of new window)
                const printWindow = document.createElement('iframe');
                printWindow.style.position = 'absolute';
                printWindow.style.width = '0';
                printWindow.style.height = '0';
                printWindow.style.border = 'none';
                document.body.appendChild(printWindow);
                const doc = printWindow.contentWindow.document;
                doc.open();
                doc.write(printContent);
                doc.close();

                // Set CSS for printing
                const style = doc.createElement('style');
                style.innerHTML = `
                    @media print {
                        body, html {
                            width: 210mm;
                            margin: 2mm; /* Remove margins */
                            padding: 0; /* Remove padding */
                        }
                        * {
                            box-sizing: border-box; /* Include padding and border in element's width and height */
                        }
                        @page {
                            margin: 0; /* Remove margin */
                            size: 210mm 297mm; /* Set size to 80mm width */
                        }
                    }
                `;
                doc.head.appendChild(style);

                // Ensure the content is fully loaded before printing
                printWindow.onload = () => {
                    const contentHeight = doc.body.scrollHeight;
                    console.log("Content height:", contentHeight);
                    printWindow.contentWindow.print();
                    if (contentHeight > 210) {
                        doc.body.innerHTML += '\x1D\x56\x00'; // Manual cut command
                    }
                };
            } else {
                console.error("Empty or invalid response received.");
            }
        } catch (error) {
            console.error("Error fetching or printing data:", error);
        }
    };
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const generatePrintContent = (data) => {
        const billprintline1 = appState?.[0]?.billprintline1 || ""; const billprintline1Html1 = billprintline1 !== null ? `<p style="text-align: center; width:2.7in;">${billprintline1}</p>` : '';
        const billprintline2 = appState?.[0]?.billprintline2 || ""; const billprintline1Html2 = billprintline2 !== null ? `<p style="text-align: center; width:2.7in;">${billprintline2}</p>` : '';
        const billprintline3 = appState?.[0]?.billprintline3 || ""; const billprintline1Html3 = billprintline3 !== null ? `<p style="text-align: center; width:2.7in;">${billprintline3}</p>` : '';
        const billprintline4 = appState?.[0]?.billprintline4 || ""; const billprintline1Html4 = billprintline4 !== null ? `<p style="text-align: center; width:2.7in;">${billprintline4}</p>` : '';
        const billprintline5 = appState?.[0]?.billprintline5 || ""; const billprintline1Html5 = billprintline5 !== null ? `<p style="text-align: center; width:2.7in;">${billprintline5}</p>` : '';
        return `<style>*{margin: 0;padding: 0;box-sizing: border-box;}div{width: 2.7in;}hr{width: 2.7in;}</style><div style="font-family: 'Bahnschrift Condensed'; width: 2.7in;"><p1 style="display: block; margin: 0 auto;text-align: center;"> <h1>${data.cardstatus == 1 ? `Card Recharge` : 'Card Return'}</h1></p1>
        <h1 style="text-align: center;font-Size:${fs_cname}px;">${appState?.[0]?.cname || '-'}</h1>
        ${billprintline1Html1}        ${billprintline1Html2}        ${billprintline1Html3}        ${billprintline1Html4}        ${billprintline1Html5} 
        
        <hr style="borderTop: 1px solid black; width: 2.7in;" /><span style="display:flex;"></p1></span>
        <span style="display:flex; width: 2.7in;"><p1 style="text-align: start; width:50%;">Date : ${data.entrydate ? new Date(data.entrydate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"}</p1><p1 style="text-align: end; width:50%;"> Time : ${data.entrytime}</p1></span>
        <hr style="borderTop: 1px solid black; width: 2.7in;" />
        <span style="display:flex; width: 2.7in;"><p1 style="text-align: start; width:45%;">Card No </p1><p1 style="text-align: start; width:55%;">: ${data.cardno}</p1></span>
        <span style="display:flex; width: 2.7in;"><p1 style="text-align: start; width:45%;">Cust Name </p1><p1 style="text-align: start; width:55%;">: ${data.custname}</p1></span>
        <span style="display:flex; width: 2.7in;"><p1 style="text-align: start; width:45%;">Mob No </p1><p1 style="text-align: start; width:55%;">: ${data.mobno}</p1></span>
        <span style="display:flex; width: 2.7in;"><p1 style="text-align: start; width:45%;">${data.cardstatus == 1 ? `Recharge Amt` : 'Return Amt'} </p1><p1 style="text-align: start; width:55%;">: ${data.rechamt}</p1></span>

        <span style="display:flex; width: 2.7in;"><p1 style="text-align: start; width:45%;">No Of Person </p1><p1 style="text-align: start; width:55%;">: ${data.noofperson}</p1></span>
        <span style="display: flex; flex-wrap: wrap; width: 2.7in;">
    <p1 style="text-align: start; width: 45%;">Remarks</p1>
    <p1 style="text-align: start; width: 55%; white-space: pre-wrap; word-break: break-word;">: ${data.remarks}</p1>
</span>


        <hr style="borderTop: 1px solid black; width: 2.7in;" />
        <span style="display:flex; width: 2.7in;"><p1 style="text-align: center; width:100%;">*** THANK YOU !! ***</p1></span>
        </p1></span><hr style="borderTop: 1px solid black; width: 2.7in;" />      
        </div>`;
    };
   

    const cmdEdit = (id) => {
        console.log("id");
        console.log(id);
        axios.get(`${hostlink}/bhbook/${id}`)
            .then((resp) => {
                let x = document.getElementById("viewform")
                x.style.display = "None"
                let y = document.getElementById("newform")
                y.style.display = "block"
                console.log(resp.data)
                setfrmdata(resp.data[0])
                console.log(resp.data[0].bhcode);
                onChangevalueRoom(resp.data[0].bhcode, resp.data[0].bhname);
            })
    }
    const cmdDelete = (shopvno, shopid) => {
      
        axios.delete(`${hostlink}/bhbook/${shopvno}/${shopid}`)
            .then((resp) => {
                toast.success("Delete Sucessfull")
                setTimeout(() => {
                    setitemdetailGridRefresh(true)
                }, 1000);
            })
            .catch((resp) => {
                console.log("Something Else")
            })
    }

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setQuery(inputValue);
        filterData(inputValue);
        setListVisible(true);
        setSelectedIndex(-1);
    };

    const filterData = (query) => {
        if (!query) {
            setFilteredData([]);
            return;
        }

        const filtered = data.filter(item => {
            return item.itname && item.itname.toLowerCase().includes(query.toLowerCase());
        }).slice(0, 5); // Limit to first 5 records after filtering

        setFilteredData(filtered);
    };
    
    const handleSelectItem = (item, index) => {
        setSelectedItem(item);
        setQuery(item.itname);
        setListVisible(false);
        setSelectedIndex(index);
    };
    const [itemdetail, setitemdetail] = useState({ rawcode: 0, rawname: "", barcode: "", qty: 1, rate: 0, discperc: 0, taxable: 0, gst: 0, gstamt: 0, ittotal: 0, totqty: 0, totgst: 0, totordamt: 0, rcode: "", roomnoview: "" });
    const { rawcode, rawname, barcode, qty, rate, discperc, taxable, gst, gstamt, ittotal } = itemdetail;
    const [itemdetailGrid, setitemdetailGrid] = useState([]);
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    }, [itemdetailGrid]);


    const handleItemClick = (index) => {
        const queryCondition = filteredData[index].ispackage == 0
            ? filteredData[index].restrate
            : 0;

        setSelectedIndex(index);
        handleSelectItem(filteredData[index], index);
        setQuery(filteredData[index].itname);
        setitemdetail({
            ...itemdetail,
            rawcode: filteredData[index].id,
            rawname: filteredData[index].itname,
            barcode: filteredData[index].barcode,
            qty: 1,
            rate: queryCondition,
            discperc: filteredData[index].discperc,
            gst: filteredData[index].gst,
        });
        document.getElementById("qty").focus();
    };
    const handleKeyDown = (event) => {
        if (document.getElementById("inputname1111").value == "") {
            return false
        }
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            setSelectedIndex(prevIndex => (prevIndex < filteredData.length - 1 ? prevIndex + 1 : prevIndex));
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            setSelectedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
        } else if (event.key === 'Enter' && selectedIndex !== -1) {
            handleSelectItem(filteredData[selectedIndex], selectedIndex);
            setQuery(filteredData[selectedIndex].itname);
            const queryCondition = filteredData[selectedIndex].ispackage == 0
                ? filteredData[selectedIndex].restrate
                : 0;

            setitemdetail({
                ...itemdetail,
                rawcode: filteredData[selectedIndex].id,
                rawname: filteredData[selectedIndex].itname,
                barcode: filteredData[selectedIndex].barcode,
                discperc: filteredData[selectedIndex].discperc,
                qty: 1,
                rate: queryCondition,
                gst: filteredData[selectedIndex].gst,
            });
            document.getElementById("qty").focus();
        }
    };

    const handleFocus = (e) => {
        // setTimeout(() => {
        //     e.target.select(); // Select the input value when it receives focus
        // }, 0);
    };
    const handleKeyDownnext = (event) => {
        if (event.keyCode === 39) {
            event.preventDefault(); // Prevent cursor from moving to the end
            event.target.setSelectionRange(0, event.target.value.length); // Select the input value
        }
        if (event.keyCode === 13 || event.keyCode === 9) { // Check if the Enter key or Tab key is pressed
            event.preventDefault(); // Prevent default Tab behavior
            const interactiveElements = document.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
            const currentIndex = Array.from(interactiveElements).indexOf(event.target); // Get the index of the current interactive element
            if (currentIndex < interactiveElements.length - 1) {
                interactiveElements[currentIndex + 1].focus(); // Focus on the next interactive element
            } else if (event.keyCode === 9) {
                interactiveElements[0].focus();
            }
        }
    };

    const today = new Date();
    const [frmdata, setfrmdata] = useState({
        fromdate: today.toISOString().split('T')[0],
        todate: today.toISOString().split('T')[0],
        status: 0, foodpackname: "", foodamount: 0, noofroom: 0, roomrent: 0,flatdiscount:0,
        resdate: today.toISOString().split('T')[0], checkindate: today.toISOString().split('T')[0], guestname: "", guestmob: "", guestemail: "", guestadd: "", guestadd2: "", guestcity: "", guestpincode: "", noofguest: 100, hallrent: 0, advanceamt: 0, refrence: "", remarks: "", payableamt: 0
    });
    const { fromdate, todate, resdate, checkindate, guestname, guestmob, guestemail, guestadd, guestadd2, guestcity, guestpincode, noofguest, hallrent, advanceamt, refrence, foodpackname, remarks, payableamt, status, noofroom, flatdiscount,roomrent, foodamount } = frmdata;
    
    useEffect(() => {
        if(usershopid!=0){
            console.log(`${hostlink}/${usershopid}/viewCardMaster/${fromdate}/${todate}`)
        axios.get(`${hostlink}/${usershopid}/viewCardMaster/${fromdate}/${todate}`)
            .then((Response) => {
                setsrvData(Response.data)
                setitemdetailGridRefresh(false)
            })
            .catch((Response) => {
                console.log("Data Not Fatched")
            })
        }
    }, [usershopid,fromdate,todate]);
    const handleKeyDownFindItem = (event) => {

        const existingItem = data.find(item => item.barcode === barcode);
        if (existingItem) {
            console.log("Item found:", existingItem);
            const queryCondition = filteredData[existingItem].ispackage == 0
                ? filteredData[existingItem].restrate
                : 0;

            setQuery(existingItem.itname)
            setitemdetail({
                ...itemdetail,
                rawcode: existingItem.id,
                rawname: existingItem.itname,
                barcode: existingItem.barcode,
                discperc: existingItem.discperc,
                qty: 1,
                rate: queryCondition,
                gst: existingItem.gst,
            });
        } else {
            setitemdetail({
                ...itemdetail,
                id: 0,
            });
            return false
        }


        if (event.keyCode === 13 || event.keyCode === 9) {
            const interactiveElements = document.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
            const currentIndex = Array.from(interactiveElements).indexOf(event.target);

            if (currentIndex < interactiveElements.length - 1) {
                interactiveElements[currentIndex + 1].focus();
                console.log("1")

            } else if (event.keyCode === 9) {
                interactiveElements[0].focus();
                console.log("2")
            }
        }
    };
    const [guestdata, setguestdata] = useState({ c_Gname: "", c_GMob: "", c_GAdd: "", c_Grcode: "", c_Grcodename: "", c_GCHID: "" });
    const { c_Gname, c_GMob, c_GAdd, c_Grcode, c_Grcodename, c_GCHID } = guestdata;
    const onChangevalueRoom = (e, f) => {
        const existingItem = Room.find(item => item.bhname === f);
        if (existingItem) {
            setguestdata({
                ...guestdata,
                c_GCHID: existingItem.id,
                c_Gname: existingItem.bhname,
            });
        } else {
            console.log("Item not found");
        }
    };



    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,<>?=]/g, '');
        setitemdetail({ ...itemdetail, [name]: sanitizedValue });
    }
    
    const onChangevalueloaddata = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`#$%^&*+{}|;:,.<>?=]/g, '');
        setfrmdata({ ...frmdata, [name]: sanitizedValue });
        setitemdetailGridRefresh(true)
    }
    const [isVisible, setIsVisible] = useState(false);

    const [Room, setRoom] = useState([])
    const [items, setItems] = useState([]);
    const handleDoubleClick = (itemId) => {
        const selectedItem = items.find(item => item.id === itemId);
        if (selectedItem) {
            alert(`Double-clicked on: ${selectedItem.itname}`);
        }
    };
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    // Show button when user scrolls down 400px
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }


        const toggleVisibility = () => {
            if (window.pageYOffset > 100) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, [usershopid]);

    const handleDeleteItem = async (id) => {
        const updatedItDetails = itemdetailGrid.filter(item => item.rawcode !== id);
        toast.success("Item deleted");
        setitemdetailGrid(updatedItDetails);
        document.getElementById("inputname1111").focus();
    };

    const handleAddItem = () => {

        if (rawcode == 0) {
            toast.error("Please Select Item..");
            document.getElementById("inputname1111").focus();
            return false
        }
        const existingItem = itemdetailGrid.find(item => item.rawcode === rawcode);
        if (existingItem) {
            const updatedItDetails = itemdetailGrid.map(item => {
                if (item.rawcode === rawcode) {
                    return { ...item, qty: Number(Number(item.qty) + Number(qty)).toFixed(2) };
                }
                return item;
            });
            setitemdetailGrid(updatedItDetails);
            toast.success("quantity added..");
            setQuery('')
            setitemdetail({
                ...itemdetail,
                rawcode: 0,
                rawname: "",
                barcode: "",
                qty: "",
                discperc: 0,
                rate: "",
                gst: "",
            });
            document.getElementById("inputname1111").focus();
        } else {
            setitemdetailGrid([...itemdetailGrid, {
                rawcode: rawcode,
                rawname: rawname,
                barcode: barcode,
                discperc: discperc,
                qty: qty,
                rate: rate,
                gst: gst,
            }])
            toast.success("Item added..");
            setQuery('')
            setitemdetail({
                ...itemdetail,
                rawcode: 0,
                rawname: "",
                barcode: "",
                qty: "",
                rate: "",
                gst: "",
            });
            document.getElementById("inputname1111").focus();
        }
    };
    
    const cmdNewReturn = () => {
        window.location.assign("/CardReturn")
    }

    const cmdNew = () => {
        window.location.assign("/card-issue-return")
    }
    const searchData = (e) => {
        const searchValue = document.getElementById("searchroom").value.trim();
        console.log(searchValue)
        if (searchValue === "") {
            axios.get(`${hostlink}/getchehinroomservice/${usershopid}/${fromdate}/${todate}`)
                .then((Response) => {
                    setsrvData(Response.data)
                    setitemdetailGridRefresh(false)
                })
                .catch(() => { })
        } else {
            axios.get(`${hostlink}/getchehinroomservice/search/${usershopid}/${fromdate}/${todate}/${searchValue}`)
                .then((Response) => {
                    setsrvData(Response.data)
                    setitemdetailGridRefresh(false)
                })
                .catch(() => { })
        }
    }
    return (


        <div>
            <div id='viewform'>
                <div className='container p-2'>
                    <div className='row p-1'>
                        <div className='col-md-3 p-1'>
                            <h5 className='font-monospace'><i class="fa-regular fa-credit-card"></i> Issue/Return Card </h5>
                        </div>
                      

                        <div className='col-md-5 align-self-end' style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                            <span className="f-size-on-mobile align-self-end" style={{ verticalAlign: 'middle', height: '100%', }}>
                                <label htmlFor="todate" className="form-label text-center">From : &nbsp;</label>
                            </span>
                            <span> <input type="date" id="fromdate" name="fromdate" onChange={(e) => { onChangevalueloaddata(e) }} value={fromdate} className="form-control" /></span>


                            <span className="f-size-on-mobile align-self-end">
                                <label htmlFor="todate" className="form-label text-center">&nbsp; To : &nbsp;</label>
                            </span>
                            <span>
                                <input type="date" id="todate" name="todate" onChange={(e) => { onChangevalueloaddata(e) }} value={todate} className="form-control" />
                            </span>
                        </div>


                        <div className='col-md-4 text-end'>
                            <button id='cmdnew' onClick={cmdNew} className='btn btn-outline-primary'> <i class="fa-solid fa-bolt"></i> &nbsp;Recharge Card</button> &nbsp;
                            <button id='cmdnew' onClick={cmdNewReturn} className='btn btn-outline-danger'> <i class="fa-solid fa-arrow-rotate-left"></i> &nbsp;Return Card </button>
                        </div>
                        <div style={{ maxHeight: "80vh", overflow: "auto" }} className='col-12 '>
                            <table className="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col" className="f-size-on-mobile"><span className='hide-on-mobile'>V Date</span> <span className='show-on-mobile'>Details</span></th>
                                        <th scope="col" className="hide-on-mobile">Time</th>
                                        <th scope="col" className="hide-on-mobile">Card No</th>
                                        <th scope="col" className="hide-on-mobile">Cust Name</th>
                                        <th scope="col" className="hide-on-mobile">Mobile No</th>
                                        <th scope="col" className="hide-on-mobile">Rech Amt</th>
                                        <th scope="col" className="f-size-on-mobile text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {srvdata.map((res, x) => (
                                        <tr style={{ verticalAlign: 'middle', height: '100%' }}>
                                            <td className="f-size-on-mobile" style={{ wordWrap: "break-word" }}> <span className='show-on-mobile'><b>Date :</b> </span> {res.entrydate ? new Date(res.entrydate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"} <br />
                                                <div className="detail-on-mobile text-start align-self-start" style={{ fontSize: "12px", color: "blue", alignItems: "start", wordWrap: "break-word" }}>
                                                    {res[1] !== 0 && (<>Time : {res[1] ? new Date(`2022-01-01T${res[1]}`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) : "-"} <br /></>)}
                                                    {res[10] !== 0 && (<>Check-In : {res[2] ? new Date(res[2]).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"} <br /></>)} <span style={{ color: "blue" }}> </span>
                                                    <span>{res[0] !== '' && (<>Name : {res[4]} <br /></>)}</span>
                                                    <span>{res[0] !== '' && (<>Mob No : {res[5]} <br /></>)}</span>

                                                    <span>{res[0] !== '' && (<>Book By : {res[8]} <br /></>)}</span>
                                                    <span className='text-bg-danger'>{res[0] !== '' && (<>Hall Name : {res[3]} <br /></>)}</span>
                                                </div>
                                            </td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.entrytime}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.cardno}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.custname}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.mobno}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.rechamt}</td>
                                            <td className='text-center' style={{ verticalAlign: 'middle', height: '100%' }}>
                                                <Link className='btn btn-sm btn-outline-primary' onClick={(e) => { printdata(res.id) }}><i class="fa-solid fa-print"></i> <span className="hide-on-mobile"></span></Link> &nbsp;
                                                {/* <Link className='btn btn-sm btn-danger' onClick={(e) => { cmdDelete(res[7], res[6]) }} > <i class="fa-solid fa-trash"></i> <span className="hide-on-mobile"></span></Link> &nbsp; */}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    )
}

export default CardView