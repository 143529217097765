import React, { useEffect, useState, useRef, useContext } from 'react'
import CryptoJS from 'crypto-js';
import css from "./navbar.module.css"
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import hostlink from '../Hostlink/hostlink';
import './Reservation.css'
import Loading from './Loading'
import softwaresecretKey from '../Hostlink/softwaresecretKey';
import { UserLoginDetails } from '../Hostlink/UserLoginDetails';

const Navbar = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSoftwareMode, setisSoftwareMode] = useState(true);
    const navbarRef = useRef(null);
    const contentRef = useRef(null);

    const [isModalOpen_DayClose, setIsModalOpen_DayClose] = useState(false);
    const handleCloseModal_DayClose = () => { setIsModalOpen_DayClose(false); };

    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopidref = appState?.[0]?.hotelshopid || 0;
    const usershopid = appState?.[0]?.id || 0;
    const usershopidShowcommission = appState?.[0]?.showcommissioncheckin || 0;
    const usershopidCname = appState?.[0]?.cname || "-";
    useEffect(() => {
        if (navbarRef.current && contentRef.current) {
            const navbarHeight = navbarRef.current.offsetHeight;
            contentRef.current.style.paddingTop = `${navbarHeight}px`;
        }
    }, []);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isFormDisabled, setIsFormDisabled] = useState(false);
    const toggleFullScreen = () => {

        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen().catch(err => {
                console.error(`Error attempting to enable full-screen mode: ${err.message}`);
            });
            setIsFullScreen(true);
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
                setIsFullScreen(false);
            }
        }
    };


    const dayclosecard = async () => {
        await axios.post(`${hostlink}/api/executeUpdate?sqlQuery=UPDATE cardmaster SET cardstatus = 0, status = 0 WHERE shopid = ${usershopid}`)
            .then((resp) => {
                toast.success("Day Close Successful");
            })
            .catch((error) => {
                console.log("Something went wrong:", error);
            });
        setIsModalOpen_DayClose(false);
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [messagedata, setMessagedata] = useState({
        deleteid: "",
        deletemsg: ""
    })
    const { deleteid, deletemsg } = messagedata;


    const handleOpenModal = (e, flname) => {
        setMessagedata({ ...messagedata, deleteid: e, deletemsg: flname })
        setIsModalOpen(true);
    };
    const handleCloseModal = () => { setIsModalOpen(false); };
    const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); window.location.assign("/") };
    const validateInput = (inputId, errorMessage) => {
        const value = document.getElementById(inputId).value.trim();
        if (value === "") {
            toast.error(errorMessage);
            document.getElementById(inputId).focus();
            return false;
        }
        return value;
    };
    const checkuserUser = async () => {
        const username = validateInput("exampleDropdownFormEmail1", "Please Enter UserName...");
        const password = validateInput("exampleDropdownFormPassword1", "Please Enter Password...");

        if (!username || !password) return false;

        try {
            setIsLoading(true);
            const query = `select * from userpermissionmas where narration = '${username}' and password = '${password}' and userstatus = 0 limit 1`;
            const response = await axios.get(`${hostlink}/api/executeQuery?sqlQuery=${encodeURIComponent(query)}`);

            if (response.data.length === 0) {
                toast.error("Invalid Username or Password or disable user");
                setIsLoading(false);
                return false;
            }

            // Fetch login response and store it at index 1 in decryptedData
            const loginResponse = await axios.get(`${hostlink}/SubuserLogin/${encodeURIComponent(username)}/${encodeURIComponent(password)}`);
            const secretKey = softwaresecretKey;
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(loginResponse.data), secretKey).toString();

            localStorage.removeItem('MYPCKARTUSER');
            localStorage.setItem('MYPCKARTUSER', encryptedData);

            if (Array.isArray(loginResponse.data) && loginResponse.data.length === 0) {
                toast.error("Enter Valid username and password.");
                setIsLoading(false);
            } else {
                const storedData = localStorage.getItem('MYPCKARTUSER');
                if (storedData) {
                    const bytes = CryptoJS.AES.decrypt(storedData, secretKey);
                    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

                    // Fetch shop data and store it at index 0 of decryptedData
                    const shopDataResponse = await axios.get(`${hostlink}/shopmas/${decryptedData[0].shopid}`);
                    decryptedData[0] = shopDataResponse.data;

                    // Store loginResponse data at index 1 of decryptedData
                    decryptedData[1] = loginResponse.data;

                    // Encrypt the modified data and store it in localStorage
                    const encryptedCompleteData = CryptoJS.AES.encrypt(JSON.stringify(decryptedData), secretKey).toString();
                    localStorage.setItem('MYPCKARTUSER', encryptedCompleteData);
                }
                toast.success("User Found.");
                setTimeout(() => {
                    window.location.assign("/");
                }, 1000);
            }
        } catch (error) {
            console.error("Error during user check:", error);
            toast.error("An error occurred while logging in.");
            setIsLoading(false);
        }
    };

    const checkuserCleaner = async () => {
        const username = validateInput("exampleDropdownFormEmail1", "Please Enter UserName...");
        const password = validateInput("exampleDropdownFormPassword1", "Please Enter Password...");

        if (!username || !password) return false;

        try {
            setIsLoading(true);
            const query = `select * from cleanermas where narration = '${username}' and password = '${password}' limit 1`;
            const response = await axios.get(`${hostlink}/api/executeQuery?sqlQuery=${encodeURIComponent(query)}`);

            if (response.data.length === 0) {
                toast.error("Invalid Username or Password");
                setIsLoading(false);
                return false;
            }

            // Fetch login response and store it at index 1 in decryptedData

            const loginResponse = await axios.get(`${hostlink}/checkCleanerMas/${encodeURIComponent(username)}/${encodeURIComponent(password)}`);
            const secretKey = softwaresecretKey;
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(loginResponse.data), secretKey).toString();

            localStorage.removeItem('MYPCKARTUSER');
            localStorage.setItem('MYPCKARTUSER', encryptedData);

            if (Array.isArray(loginResponse.data) && loginResponse.data.length === 0) {
                toast.error("Enter Valid username and password.");
                setIsLoading(false);
            } else {
                const storedData = localStorage.getItem('MYPCKARTUSER');
                if (storedData) {
                    const bytes = CryptoJS.AES.decrypt(storedData, secretKey);
                    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

                    // Fetch shop data and store it at index 0 of decryptedData
                    const shopDataResponse = await axios.get(`${hostlink}/shopmas/${decryptedData[0].shopid}`);
                    decryptedData[0] = shopDataResponse.data;

                    // Store loginResponse data at index 1 of decryptedData
                    decryptedData[1] = loginResponse.data;
                    decryptedData[2] = "Cleaner";
                    decryptedData[3] = "CleanerData";

                    // Encrypt the modified data and store it in localStorage
                    const encryptedCompleteData = CryptoJS.AES.encrypt(JSON.stringify(decryptedData), secretKey).toString();
                    localStorage.setItem('MYPCKARTUSER', encryptedCompleteData);


                }
                toast.success("User Found.");
                setTimeout(() => {
                    window.location.assign("/");
                }, 1000);
            }
        } catch (error) {
            console.error("Error during user check:", error);
            toast.error("An error occurred while logging in.");
            setIsLoading(false);
        }
    };

    const checkuserWaiter = async () => {
        const username = validateInput("exampleDropdownFormEmail1", "Please Enter UserName...");
        const password = validateInput("exampleDropdownFormPassword1", "Please Enter Password...");

        if (!username || !password) return false;

        try {
            setIsLoading(true);
            const query = `select * from waitermas where narration = '${username}' and password = '${password}' limit 1`;
            const response = await axios.get(`${hostlink}/api/executeQuery?sqlQuery=${encodeURIComponent(query)}`);

            if (response.data.length === 0) {
                toast.error("Invalid Username or Password");
                setIsLoading(false);
                return false;
            }

            // Fetch login response and store it at index 1 in decryptedData
            console.log(`${hostlink}/checkWaiter/${encodeURIComponent(username)}/${encodeURIComponent(password)}`);
            const loginResponse = await axios.get(`${hostlink}/checkWaiter/${encodeURIComponent(username)}/${encodeURIComponent(password)}`);
            const secretKey = softwaresecretKey;
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(loginResponse.data), secretKey).toString();

            localStorage.removeItem('MYPCKARTUSER');
            localStorage.setItem('MYPCKARTUSER', encryptedData);

            if (Array.isArray(loginResponse.data) && loginResponse.data.length === 0) {
                toast.error("Enter Valid username and password.");
                setIsLoading(false);
            } else {
                const storedData = localStorage.getItem('MYPCKARTUSER');
                if (storedData) {
                    const bytes = CryptoJS.AES.decrypt(storedData, secretKey);
                    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

                    // Fetch shop data and store it at index 0 of decryptedData
                    const shopDataResponse = await axios.get(`${hostlink}/shopmas/${decryptedData[0].shopid}`);
                    decryptedData[0] = shopDataResponse.data;

                    // Store loginResponse data at index 1 of decryptedData
                    decryptedData[1] = loginResponse.data;
                    decryptedData[2] = "Waiter";

                    // Encrypt the modified data and store it in localStorage
                    const encryptedCompleteData = CryptoJS.AES.encrypt(JSON.stringify(decryptedData), secretKey).toString();
                    localStorage.setItem('MYPCKARTUSER', encryptedCompleteData);


                }
                toast.success("User Found.");
                setTimeout(() => {
                    window.location.assign("/");
                }, 1000);
            }
        } catch (error) {
            console.error("Error during user check:", error);
            toast.error("An error occurred while logging in.");
            setIsLoading(false);
        }
    };

    const checkuserAdmin = async () => {
        const inputValue = document.getElementById("exampleDropdownFormEmail1").value.trim();
        if (inputValue === "") {
            toast.error("Please Enter UserName...")
            document.getElementById("exampleDropdownFormEmail1").focus();
            return false
        }

        const inputValue2 = document.getElementById("exampleDropdownFormPassword1").value.trim();
        if (inputValue2 === "") {
            toast.error("Please Enter Password...")
            document.getElementById("exampleDropdownFormPassword1").focus();
            return false
        }

        try {

            const nameQuery = `select * from shopmas where email = '${document.getElementById("exampleDropdownFormEmail1").value}' and userstatus = 0 limit 1`;
            const nameResponse = await axios.get(`${hostlink}/api/executeQuery?sqlQuery=${encodeURIComponent(nameQuery)}`);

            if (nameResponse.data.length == 0) {
                toast.error("Invalid Username..."); setIsLoading(false); document.getElementById("exampleDropdownFormEmail1").focus();
                return false;
            }

            const nameQueryPassword = `select * from shopmas where password = '${document.getElementById("exampleDropdownFormPassword1").value}' limit 1`;
            const nameQueryPasswordResp = await axios.get(`${hostlink}/api/executeQuery?sqlQuery=${encodeURIComponent(nameQueryPassword)}`);
            if (nameQueryPasswordResp.data.length == 0) {
                toast.error("Enter Valid Password..."); setIsLoading(false); document.getElementById("exampleDropdownFormPassword1").focus();
                return false;
            }

            await axios.get(`${hostlink}/shopmas/${encodeURIComponent(document.getElementById("exampleDropdownFormEmail1").value)}/${encodeURIComponent(document.getElementById("exampleDropdownFormPassword1").value)}`)
                .then((res) => {

                    localStorage.removeItem('MYPCKARTUSER');
                    const secretKey = softwaresecretKey;
                    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(res.data), secretKey).toString();
                    localStorage.setItem('MYPCKARTUSER', encryptedData);

                    // localStorage.setItem('MYPCKARTUSER', JSON.stringify(res.data));
                    if (Array.isArray(res.data) && res.data.length === 0 && res.data.every(item => item === "black")) {
                        toast.error("Enter Valid username and password..")
                        setIsLoading(false);
                        document.getElementById("exampleDropdownFormEmail1").focus();
                    }
                    else {
                        toast.success("User Found..")
                        setInterval(() => {
                            window.location.assign("/")
                        }, 1000);
                    }
                })
                .catch(() => {
                    console.log("Data Not Found")
                })

        } catch (error) {

        }
    }

    const checkuser = async () => {
        const usertype = document.getElementById("userType").value.trim();

        const inputValue = document.getElementById("exampleDropdownFormEmail1").value.trim();
        if (inputValue === "") {
            toast.error("Please Enter UserName...")
            document.getElementById("exampleDropdownFormEmail1").focus();
            return false
        }

        const inputValue2 = document.getElementById("exampleDropdownFormPassword1").value.trim();
        if (inputValue2 === "") {
            toast.error("Please Enter Password...")
            document.getElementById("exampleDropdownFormPassword1").focus();
            return false
        }

        if (usertype == 0) {
            checkuserAdmin()
        } else if (usertype == 1) {
            checkuserUser()
        } else if (usertype == 2) {
            checkuserWaiter()
        } else {
            checkuserCleaner()
        }

    }

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const logoutdevice = () => {
        localStorage.removeItem('MYPCKARTUSER');
        window.location.assign("/")
    }

    useEffect(() => {
        const adjustPadding = () => {
            if (navbarRef.current && contentRef.current) {
                const navbarHeight = navbarRef.current.offsetHeight;
                contentRef.current.style.paddingTop = `${navbarHeight}px`;
            }
        };

        adjustPadding();
        window.addEventListener('resize', adjustPadding);

        return () => {
            window.removeEventListener('resize', adjustPadding);
        };
    }, []);


    useEffect(() => {

        const myPckartUser = localStorage.getItem('MYPCKARTUSER');
        if (Array.isArray(myPckartUser) && myPckartUser.length === 0) {
            localStorage.removeItem('MYPCKARTUSER');
        }
        setIsLoggedIn(!!myPckartUser);
        const pathname = window.location.pathname;
        const parts = pathname.split('/');
        const pageName = parts[parts.length - 1];
        if (pageName == "Shop_Create") { }
        else if (pageName == "RecoveryyPassword") { }
        else if (pageName == "SelfOrder") {
            setisSoftwareMode(false)
         }
        else {
            if (!myPckartUser) {
                handleOpenModal("1", "1");
            }
        }

    }, []);

    return (
        <div id={css.menubar} ref={contentRef}>

        {isSoftwareMode ? (
                <nav ref={navbarRef} className="navbar navbar-expand-lg navbar-light bg-light shadow-sm" style={{ position: "fixed", top: 0, width: "100%", zIndex: 1000 }}>
                    <div className="container-fluid bg-body-secondary shadow-lg" >
                        <a className="navbar-brand" href="/"><i class="fa-solid fa-house" ></i> <a className="show-on-mobile font-monospace" style={{ textDecoration: "none", color: "black" }} href='#' onClick={(e) => { handleOpenModal() }}> {usershopidCname}</a></a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            {userType != 3 ? (

                                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                    {userType != 2 ? (
                                        <li className="nav-item dropdown active">
                                            <a className="nav-link dropdown-toggle active" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Master
                                            </a>
                                            <ul className="dropdown-menu">
                                                {/* <li><a className="dropdown-item" href="/ItemMaster"><i class="fa-solid fa-sitemap" style={{ width: "20px" }}></i> Item Master</a></li> */}
                                                {usershopidref === 0 ? (
                                                    <li>
                                                        <a className="dropdown-item" href="/ItemMaster">
                                                            <i className="fa-solid fa-sitemap" style={{ width: "20px" }}></i> Item Master
                                                        </a>
                                                    </li>
                                                ) : (
                                                    <li>
                                                        <a className="dropdown-item" href="/ItemMasterStore">
                                                            <i className="fa-solid fa-sitemap" style={{ width: "20px" }}></i> Item Master
                                                        </a>
                                                    </li>
                                                )}
                                                {/* {usershopidref === 0 ? (<li><a className="dropdown-item" href="/ItemUnit_View"><i class="fa-solid fa-cart-flatbed-suitcase" style={{ width: "20px" }}></i > Item Unit Master</a></li>) : null} */}
                                                {(userType === 0 || appState[1]?.[0]?.itemunitmaster?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/ItemUnit_View"><i class="fa-solid fa-cart-flatbed-suitcase" style={{ width: "20px" }}></i > Item Unit Master</a></li>) : null}

                                                {(userType === 0 || appState[1]?.[0]?.itemgroupmaster?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/ItemGroupMaster"><i class="fa-solid fa-layer-group" style={{ width: "20px" }}></i> Item Group Master</a></li>) : null}
                                                {/* <li><hr className="dropdown-divider" /></li> */}

                                                {(userType === 0 || appState[1]?.[0]?.dishtypemaster?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/DishTypeMaster"><i class="fa-solid fa-bag-shopping" style={{ width: "20px" }}></i> Dish Type Master </a></li>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.dishheadmaster?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/DishHeadMaster"><i class="fa-solid fa-building-flag" style={{ width: "20px" }}></i> Dish Head Master </a></li>) : null}
                                                {/* <li><a className="dropdown-item" href="/ItemCompanyMaster"><i class="fa-regular fa-building" style={{ width: "20px" }}></i> Item Company Master </a></li> */}

                                                {(userType === 0 || appState[1]?.[0]?.floormaster?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/FloorMaster"><i class="fa-solid fa-tree" style={{ width: "20px" }}></i> Floor Master</a></li>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.roomtype?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/RoomTypeMaster"><i class="fa-solid fa-filter" style={{ width: "20px" }}></i> Room Type</a></li>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.roommaster?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/RoomMaster"><i class="fa-solid fa-person-shelter" style={{ width: "20px" }}></i> Room Master</a></li>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.foodpackage?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/FoodPackage"><i class="fa-solid fa-box-open" style={{ width: "20px" }}></i> Food Package</a></li>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.tablemaster?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/TableMaster"><i class="fa-solid fa-table-cells-large" style={{ width: "20px" }}></i> Table Master</a></li>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.waitermaster?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/WaiterMas"><i class="fa-solid fa-hand-holding-droplet" style={{ width: "20px" }}></i> Waiter Master</a></li>) : null}
                                                {(userType === 0) ? (<li><a className="dropdown-item" href="/CleanerMas"><i class="fa-solid fa-person-digging" style={{ width: "20px" }}></i> Cleaner Master</a></li>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.banquethall?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/BanquetHall"><i class="fa-solid fa-warehouse" style={{ width: "20px" }}></i> Banquet Hall</a></li>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.commentmaster?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/CommentMas"><i class="fa-solid fa-comment" style={{ width: "20px" }}></i> Comment Master</a></li>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.departmentmaster?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/DepartmentMas"><i class="fa-solid fa-boxes-stacked" style={{ width: "20px" }}></i> Department Master</a></li>) : null}

                                                {(userType === 0 || appState[1]?.[0]?.accountmaster?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/AccountMaster"><i class="fa-regular fa-address-card" style={{ width: "20px" }}></i> Account Master</a></li>) : null}
                                                {/* <li><a className="dropdown-item" href="/"><i class="fa-regular fa-address-book" style={{width:"20px"}}></i> H.D. Customer</a></li> */}
                                                {/* <li><hr className="dropdown-divider" /></li> */}
                                                {(userType === 0 || appState[1]?.[0]?.kitchenmaster?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/KitchenMaster"><i class="fa-solid fa-kitchen-set" style={{ width: "20px" }}></i> Kitchen Master</a></li>) : null}
                                                {(userType === 0) ? (<li><a className="dropdown-item" href="/UserPermission"><i class="fa-solid fa-user-gear" style={{ width: "20px" }}></i> User Master</a></li>) : null}
                                                {(userType === 0) ? (<li><a className="dropdown-item" href="/CustomerMaster"><i class="fa-solid fa-user-tie" style={{ width: "20px" }}></i> Customer Master</a></li>) : null}
                                                {(appState?.[0]?.empsalary == 1) ? (<li><a className="dropdown-item" href="/EmployeeMaster"><i class="fa-solid fa-users-between-lines" style={{ width: "20px" }}></i> Employee Master</a></li>) : null}
                                                
                                                
                                            </ul>
                                        </li>) : null}

                                    {(appState?.[0]?.cardsale == '1') ? (<li className="nav-item"><a href='/CardView' className="nav-link active"> <i class="fa-regular fa-credit-card"></i> Issue/Return Card </a></li>) : null}
                                    {(userType === 0 || appState[1]?.[0]?.reservation?.charAt(0) === '1') ? (<li className="nav-item"><a href='/Reservation' className="nav-link active"> <i class="fa-solid fa-book"></i> Reservation </a></li>) : null}
                                    {(userType === 0 || appState[1]?.[0]?.checkin?.charAt(0) === '1') ? (<li className="nav-item"><a className="nav-link active" href='/CheckIn'><i class="fa-solid fa-door-open"></i> Check in</a></li>) : null}
                                    {(userType === 0 || appState[1]?.[0]?.service?.charAt(0) === '1') ? (<li className="nav-item"> <a className="nav-link active" href='/RoomService'><i class="fa-solid fa-utensils"></i> Service</a> </li>) : null}
                                    {/* {(userType === 0 || appState[1]?.[0]?.placeorder?.charAt(0) === '1') ? (<li className="nav-item"> <a className="nav-link active" href='/FoodOrder'><i class="fa-solid fa-bowl-food"></i> Place Order</a> </li>) : null} */}
                                    {(userType === 0 || userType === 2 || appState[1]?.[0]?.placeorder?.charAt(0) === '1') ? (<li className="nav-item"> <a className="nav-link active" href='/FoodOrder'><i class="fa-solid fa-bowl-food"></i> Place Order</a> </li>) : null}




                                    {(userType === 0 || appState[1]?.[0]?.checkout?.charAt(0) === '1') ? (<li className="nav-item"> <a className="nav-link active" href='/CheckOut'><i class="fa-solid fa-right-from-bracket"></i> Check out</a> </li>) : null}

                                    <li className="nav-item dropdown active">
                                        <a className="nav-link dropdown-toggle active" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa-solid fa-chart-bar"></i> View</a>
                                        <ul className="dropdown-menu">
                                            {(userType === 0 || userType === 2 || appState[1]?.[0]?.kotview?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/KotView"><i class="fa-solid fa-bowl-food" style={{ width: "20px" }}></i> Kot View</a></li>) : null}
                                            {(userType === 0 || appState[1]?.[0]?.billview?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/RestBillView"><i class="fa-solid fa-database" style={{ width: "20px" }}></i> Bill View</a></li>) : null}
                                            {(userType === 0 || appState[1]?.[0]?.transferbill?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/TransferBill"><i class="fa-solid fa-money-bill-transfer" style={{ width: "20px" }}></i> Transfer Bill</a></li>) : null}
                                            {(userType === 0 || appState[1]?.[0]?.hotelbillview?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/SaleBill"><i class="fa-solid fa-right-from-bracket" style={{ width: "20px" }}></i> Hotel Bill View</a></li>) : null}
                                            {(userType === 0 || appState[1]?.[0]?.kotview?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/KitchenDisplay"><i class="fa-solid fa-kitchen-set" style={{ width: "20px" }}></i> Kitchen Display</a></li>) : null}
                                            {(userType === 0 || appState[1]?.[0]?.kotview?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/KitchenDisplayStatus"><i class="fa-solid fa-kitchen-set" style={{ width: "20px" }}></i> Kitchen Status</a></li>) : null}
                                            {(userType === 0 || appState[1]?.[0]?.kotview?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/Housekeeping-Report"><i class="fa-solid fa-person-digging" style={{ width: "20px" }}></i> Housekeeping Report</a></li>) : null}

                                            {(userType === 0) && usershopidShowcommission == 1 && (
                                                <li><a className="dropdown-item" href="/Check_in_Commission"><i class="fa-solid fa-door-open" style={{ width: "20px" }}></i> Check-in Commission</a></li>
                                            )}

                                            {(userType === 0 || appState[1]?.[0]?.kotview?.charAt(0) === '1') ? (<li><a href='/Reservation_view' className="dropdown-item"> <i class="fa-solid fa-book" style={{ width: "20px" }}></i> Reservation View</a></li>) : null}
                                            {/* <li><a className="dropdown-item" href="/MaintenanceRoom"><i class="fa-solid fa-road-barrier" style={{ width: "20px" }}></i> Maintenance Room</a></li> */}
                                        </ul>
                                    </li>

                                    {/* <li className="nav-item">
                                <a href='/' className="nav-link active"><i class="fa-solid fa-tent-arrow-left-right"></i> Transaction</a>
                            </li> */}
                                    {userType != 2 ? (<li className="nav-item dropdown active" >
                                        <a className="nav-link dropdown-toggle active" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="fa-solid fa-flag"></i> Report
                                        </a>

                                        <ul className="dropdown-menu" style={{ fontSize: "15px" }}>
                                            <li className="nav-item" style={{ display: "inline-block", whiteSpace: "nowrap", fontFamily: "Bahnschrift Condensed" }}>

                                                {(userType === 0) ? (<a style={{ height: "32px" }} href='/Logbook' className="nav-link active "> &nbsp; <i class="fa-solid fa-flag" style={{ width: "20px" }}></i> Logbook</a>) : null}
                                                {(userType === 0) ? (<a style={{ height: "32px" }} href='/Rpt_MIS_Report' className="nav-link active"> &nbsp; <i class="fa-solid fa-object-group" style={{ width: "20px" }}></i> MIS Report </a>) : null}
                                                {(userType === 0) ? (<a style={{ height: "32px" }} href='/Rpt_Room_availability' className="nav-link active"> &nbsp; <i class="fa-solid fa-check-to-slot" style={{ width: "20px" }}></i> Room-availability Report </a>) : null}
                                                {(userType === 0) ? (<a style={{ height: "32px" }} href='/Rpt_Room_availability_Roomtype' className="nav-link active"> &nbsp; <i class="fa-solid fa-check-to-slot" style={{ width: "20px" }}></i> Room-availability Room Type </a>) : null}


                                                {(userType === 0 || appState[1]?.[0]?.roomstatus?.charAt(0) === '1') ? (<a style={{ height: "32px" }} href='/Rpt_Room_status' className="nav-link active"> &nbsp; <i class="fa-solid fa-check-to-slot" style={{ width: "20px" }}></i> Room Status </a>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.hotelreservationreport?.charAt(0) === '1') ? (<a style={{ height: "32px" }} href='/Rpt_Hotel_ReservationReport' className="nav-link active" > &nbsp; <i class="fa-solid fa-door-open" style={{ width: "20px" }}></i> Hotel Reservation Report </a>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.banquetreservationreport?.charAt(0) === '1') ? (<a style={{ height: "32px" }} href='/Rpt_Banquet_ReservationReport' className="nav-link active" > &nbsp; <i class="fa-solid fa-warehouse" style={{ width: "20px" }}></i> Banquet Reservation Report </a>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.checkinoutreport?.charAt(0) === '1') ? (<a style={{ height: "32px" }} href='/Rpt_CheckInReport' className="nav-link active" > &nbsp; <i class="fa-solid fa-door-open" style={{ width: "20px" }}></i> Check-In/Out Report </a>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.hotelbillreport?.charAt(0) === '1') ? (<a style={{ height: "32px" }} href='/Rpt_BillReport' className="nav-link active"> &nbsp;  <i class="fa-solid fa-right-from-bracket" style={{ width: "20px" }}></i> Hotel Bill Report </a>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.hoteltrashbill?.charAt(0) === '1') ? (<a style={{ height: "32px" }} href='/SaleBillTrash' className="nav-link active"> &nbsp; <i class="fa-solid fa-trash-can" style={{ width: "20px" }}></i> Hotel-Trash Bill </a>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.hotelcancelledbill?.charAt(0) === '1') ? (<a style={{ height: "32px" }} href='/SaleBillCancel' className="nav-link active"> &nbsp; <i class="fa-solid fa-ban" style={{ width: "20px" }}></i> Hotel-Cancelled Bill </a>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.cancelleditemkot?.charAt(0) === '1') ? (<a style={{ height: "32px" }} href='/Rpt_Kot_Item_Cancel' className="nav-link active"> &nbsp; <i class="fa-solid fa-utensils" style={{ width: "20px" }}></i> Cancelled Item (KOT) </a>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.tablestatus?.charAt(0) === '1') ? (<a style={{ height: "32px" }} href='/Rpt_TableStatus' className="nav-link active"> &nbsp; <i class="fa-solid fa-table-cells-large" style={{ width: "20px" }}></i> Table Status </a>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.salereportbillwise?.charAt(0) === '1') ? (<a style={{ height: "32px" }} href='/Rpt_SaleReport' className="nav-link active"> &nbsp;  <i class="fa-solid fa-right-from-bracket" style={{ width: "20px" }}></i> Sale Report Bill-wise </a>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.salereportitemwise?.charAt(0) === '1') ? (<a style={{ height: "32px" }} href='/Rpt_SaleReportItem_wise' className="nav-link active"> &nbsp;  <i class="fa-solid fa-right-from-bracket" style={{ width: "20px" }}></i> Sale Report Item-wise </a>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.salereportitemsummary?.charAt(0) === '1') ? (<a style={{ height: "32px" }} href='/RptSaleItemSummary' className="nav-link active"> &nbsp;  <i class="fa-regular fa-rectangle-list" style={{ width: "20px" }}></i> Sale Report Item-Summary </a>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.saletrashbill?.charAt(0) === '1') ? (<a style={{ height: "32px" }} href='/RestBillViewTrash' className="nav-link active"> &nbsp; <i class="fa-solid fa-trash-can" style={{ width: "20px" }}></i> Sale-Trash Bill </a>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.salecancelledbill?.charAt(0) === '1') ? (<a style={{ height: "32px" }} href='/RestBillViewCancelled' className="nav-link active"> &nbsp; <i class="fa-solid fa-ban" style={{ width: "20px" }}></i> Sale-Cancelled Bill </a>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.stockreport?.charAt(0) === '1') ? (<a style={{ height: "32px" }} href='/Rpt_Stock_Report' className="nav-link active"> &nbsp;  <i class="fa-solid fa-bars-staggered" style={{ width: "20px" }}></i> Stock Report </a>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.purchasereport?.charAt(0) === '1') ? (<li><a style={{ height: "32px" }} className="dropdown-item" href="/Rpt_PurchaseReport"><i class="fa-solid fa-cart-shopping" style={{ width: "20px" }}></i> Purchase Report</a></li>) : null}
                                            </li>
                                        </ul>
                                    </li>) : null}

                                    {userType != 2 ? (
                                        <li className="nav-item dropdown active">
                                            <a className="nav-link dropdown-toggle active" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa-solid fa-angles-right" style={{ width: "20px" }}></i> Other </a>
                                            <ul className="dropdown-menu">
                                                {(userType === 0 || appState[1]?.[0]?.purchaseentry?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/Purchase"><i class="fa-solid fa-cart-shopping" style={{ width: "20px" }}></i> Purchase </a></li>) : null}
                                                <li><a className="dropdown-item" href="/Rpt_Stock_By_Purchase"><i class="fa-solid fa-cart-shopping" style={{ width: "20px" }}></i> Stock By Purchase</a></li>
                                                {(userType === 0 || appState[1]?.[0]?.transaction?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/TransactionDetail"><i class="fa-solid fa-money-bill-transfer" style={{ width: "20px" }}></i> Transaction</a></li>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.ledgerview?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/LedgerView"><i class="fa-solid fa-book-open" style={{ width: "20px" }}></i> Ledger View</a></li>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.wastageentry?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/StockTRFWastage"><i class="fa-solid fa-trash-can-arrow-up" style={{ width: "20px" }}></i> Wastage Entry</a></li>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.wastagereport?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/Rpt_Stockwastage"><i class="fa-solid fa-trash-can-arrow-up" style={{ width: "20px" }}></i> Wastage Report</a></li>) : null}
                                                {(userType === 0 || userType === 1) ? (<li><a className="dropdown-item" href="/Rpt_Item_Statement_report"><i class="fa-solid fa-tent-arrow-left-right" style={{ width: "20px" }}></i> Item Statement Report</a></li>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.stocktransfer?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/StockTRF"><i class="fa-solid fa-right-left" style={{ width: "20px" }}></i> Stock Transfer</a></li>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.stocktransferreport?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/Rpt_StockTRf"><i class="fa-solid fa-right-left" style={{ width: "20px" }}></i> Stock Transfer Report </a></li>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.purchaseorderpo?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/PoEntry"><i class="fa-solid fa-note-sticky" style={{ width: "20px" }}></i> Purchase order (PO)</a></li>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.productionentry?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/Production"><i class="fa-solid fa-arrows-to-dot" style={{ width: "20px" }}></i> Production Entry</a></li>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.costingreport?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/Rpt_CostingReport"><i class="fa-solid fa-chart-simple" style={{ width: "20px" }}></i> Costing Report</a></li>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.billofmaterialsbom?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/Bom"><i class="fa-solid fa-sliders" style={{ width: "20px" }}></i> Bill of Materials (BOM)</a></li>) : null}
                                                {(userType === 0 || appState[1]?.[0]?.bookbanquethall?.charAt(0) === '1') ? (<li><a className="dropdown-item" href="/BanquetBook"><i class="fa-solid fa-warehouse" style={{ width: "20px" }}></i> Book Banquet Hall</a></li>) : null}

                                                {(appState?.[0]?.cardsale == '1') ? (<li><a style={{ height: "32px" }} href='/Rpt_Card_Recharge_Report' className="nav-link active" > &nbsp; <i class="fa-regular fa-credit-card" style={{ width: "20px" }}></i> Card Recharge Report </a></li>) : null}
                                                {(appState?.[0]?.cardsale == '1') ? (<li><a style={{ height: "32px" }} href='/Rpt_Wallet_history' className="nav-link active" > &nbsp; <i class="fa-regular fa-credit-card" style={{ width: "20px" }}></i> Wallet History </a></li>) : null}
                                                {(appState?.[0]?.empsalary == 1) ? (<li><a className="dropdown-item" href="/EmpAttandance"><i class="fa-solid fa-users-between-lines" style={{ width: "20px" }}></i> Employee Attandance</a></li>) : null}
                                                {(appState?.[0]?.empsalary == 1) ? (<li><a className="dropdown-item" href="/Rpt_Emp_Salry"><i class="fa-solid fa-users-between-lines" style={{ width: "20px" }}></i> Employee Salary List</a></li>) : null}
                                                

                                            </ul>
                                        </li>) : null}
                                    {(userType === 0 && appState?.[0]?.cardsale == '1') ? (<li className="nav-item"><a onClick={() => { setIsModalOpen_DayClose(true); }} className="nav-link active"> <i class="fa-solid fa-shop-lock"></i> Day Close </a></li>) : null}


                                    <Modal isOpen={isModalOpen_DayClose} onRequestClose={handleCloseModal_DayClose} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                                        <div>
                                            <h1 className='text-center'>Day Close ?</h1>
                                            <div style={{ display: "flex", width: "100%" }}>

                                                <button onClick={() => { setIsModalOpen_DayClose(false); }} style={{ width: "50%" }} className='btn btn-info'><i class="fa-solid fa-xmark"></i> No</button>&nbsp;
                                                <button onClick={() => { dayclosecard() }} style={{ width: "50%" }} className='btn btn-danger'>  Yes <i class="fa-solid fa-angles-right"></i></button> &nbsp;
                                            </div>
                                        </div>
                                    </Modal>

                                    {userType != 2 ? (
                                        <li className="nav-item dropdown active">
                                            <a className="nav-link dropdown-toggle active" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa-solid fa-screwdriver-wrench" style={{ width: "20px" }}></i> Tools </a>
                                            <ul className="dropdown-menu">
                                                {/* <li><a className="dropdown-item" href='/ToolPass'><i class="fa-solid fa-gear" style={{ width: "20px" }}></i> Configuration </a></li> */}
                                                {userType === 0 ? (<li><a className="dropdown-item" href="/LocationItemDisplay"><i class="fa-solid fa-right-left" style={{ width: "20px" }}></i> Store Item Display </a></li>) : null}
                                                {userType === 0 ? (<li><a className="dropdown-item" href="/LocationDishheadDisplay"><i class="fa-solid fa-right-left" style={{ width: "20px" }}></i> Store Dish-head Display </a></li>) : null}
                                                {userType === 0 ? (<li><a className="dropdown-item" href="/LocationDishTypeDisplay"><i class="fa-solid fa-right-left" style={{ width: "20px" }}></i> Store Dish-Type Display </a></li>) : null}
                                                {/* <li><a className="dropdown-item" href="/BluetoothTerminal"><i class="fa-brands fa-bluetooth-b" style={{ width: "20px" }}></i> Check Port Bluetooth  </a></li> */}

                                            </ul>
                                        </li>
                                    ) : null}
                                    <li className="nav-item">
                                        <a className="nav-link active hide-on-mobile" onClick={toggleFullScreen}><i className="fa-solid fa-expand"></i></a>
                                    </li>

                                </ul>

                            ) : null}

                            <div className="d-flex" role="search">
                                <a style={{ textDecoration: "none", color: "blue" }} href='#' onClick={(e) => { handleOpenModal() }}> <i class="fa-solid fa-user"></i> {usershopidCname}</a>
                            </div>

                            <Modal isOpen={isModalOpen} id={css.signinmodellogin} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content " overlayClassName="modal-overlay">
                                <div>
                                    {isLoggedIn ? (
                                        <div id="logoutuser">
                                            <h1 className=''><i class="fa-solid fa-right-from-bracket"></i> Logout </h1>
                                            <br />
                                            <h3 className='text-center'  >
                                                <a href='/Passwordchange' class="btn btn-outline-dark"><i class="fa-solid fa-key"></i> Change Password </a> <br /><br />
                                                <button onClick={() => { logoutdevice() }} class="btn btn-primary"><i class="fa-solid fa-lock"></i> Logout </button> &nbsp;&nbsp;
                                                <button onClick={handleNoClick} class="btn btn-outline-dark"><i class="fa-solid fa-right-from-bracket"></i> Cancel</button>
                                            </h3>
                                        </div>
                                    ) : (
                                        <div id="loginuser">
                                            <h1 className=''><i class="fa-solid fa-circle-user"></i> Log in </h1>
                                            <div className="px-4 py-3 text-start" >
                                                <div class="mb-3">
                                                    <label for="exampleDropdownFormEmail1" class="form-label">Login As</label>
                                                    <select class="form-control" id="userType" name="userType">
                                                        <option value="0" selected>Admin</option>
                                                        <option value="1">User</option>
                                                        <option value="2">Waiter</option>
                                                        <option value="3">Cleaner</option>
                                                    </select>
                                                </div>
                                                <div class="mb-3">
                                                    <label for="exampleDropdownFormEmail1" class="form-label">User Name</label>
                                                    <input type="email" class="form-control" id="exampleDropdownFormEmail1" placeholder="email@example.com" />
                                                </div>
                                                <div class="mb-3">
                                                    <label for="exampleDropdownFormPassword1" class="form-label">Password</label>
                                                    <input type="password" class="form-control" id="exampleDropdownFormPassword1" placeholder="Password" />
                                                </div>
                                                <div class="mb-3" style={{ display: "flex" }}>
                                                    <div class="form-check w-25">
                                                        <input type="checkbox" class="form-check-input" id="dropdownCheck" />
                                                        <label class="form-check-label" for="dropdownCheck">
                                                            Remember
                                                        </label>
                                                    </div>
                                                    <div class="form-check text-end w-75">
                                                        <a href="/RecoveryyPassword" className='btn btn-sm btn  btn-secondary'><i class="fa-solid fa-key"></i> forgot password</a>
                                                    </div>

                                                </div>
                                                <h3 className='text-center'  >
                                                    <button onClick={() => { checkuser() }} class="btn btn-primary"><i class="fa-solid fa-lock-open"></i> Log in </button> &nbsp;&nbsp;
                                                    <button onClick={handleNoClick} class="btn btn-outline-dark"><i class="fa-solid fa-right-from-bracket"></i> Cancel</button>

                                                </h3>
                                            </div>
                                            <div className="d-flex justify-content-center align-items-center">
                                                <h3 className='text-center'>
                                                    <a href="/Shop_Create" className="btn btn-secondary"><i className="fa-solid fa-plus"></i> Create new account </a>
                                                </h3>
                                            </div>

                                        </div>
                                    )}
                                </div>
                            </Modal>
                        </div>
                    </div>
                </nav>
            ) : null}
            <ToastContainer autoClose={1000}></ToastContainer>
            {isLoading && <Loading />}
        </div>
    )
}

export default Navbar
