import React, { useState, useEffect } from "react";
import axios from "axios";
import hostlink from "../Hostlink/hostlink";
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
import Loading from "./Loading";
import CryptoJS from 'crypto-js';
const SelfOrder = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCanOrder, setCanOrder] = useState(false);
  const [isTableStatus, setisTableStatus] = useState(0);
  const [isDishheadFiest, setisDishheadFiest] = useState(0);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const link_shopid = queryParams.get('shopid');
  const link_tablecode = queryParams.get('tablecode');
  const link_tablename = queryParams.get('tablename');

  const [custdata, setcustdata] = useState({
    custname: "",
    custmob: "",
  })
  const { custname, custmob } = custdata;
  const onChangevalue = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
    setcustdata({ ...custdata, [name]: sanitizedValue });
  }

  const [messagedata, setMessagedata] = useState({
    deleteid: "",
    deletemsg: ""
  })

  const { deleteid, deletemsg } = messagedata;
  const [isModalOpenCustomer, setIsModalOpenCustomer] = useState(true);
  const handleOpenModalCustomer = (e, flname) => {
    setMessagedata({ ...messagedata, deleteid: e, deletemsg: flname })
    setIsModalOpenCustomer(true);
  };
  const handleCloseModalCustomer = () => { setIsModalOpenCustomer(false); setCanOrder(false); };
  const handleNoClickCustomer = () => { console.log('User clicked No'); handleCloseModalCustomer(); };

  const [categories, setCategories] = useState([]);
  const [items, setItems] = useState([]);
  const [Historyitems, setHistoryitems] = useState([]);
  const [Allitems, setItemsAll] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [cart, setCart] = useState({});
  const [showModal, setShowModal] = useState(false); // Controls modal visibility
  const [showModalRecent, setShowModalRecent] = useState(false); // Controls modal visibility


  useEffect(() => {
    if (link_shopid != 0) {
      axios.get(`${hostlink}/${link_shopid}/item`)
        .then((res) => {
          setItemsAll(res.data);
        })
        .catch((err) => console.error("Error fetching categories:", err));
      axios.get(`${hostlink}/${link_shopid}/dhmas`)
        .then((res) => {
          const formattedCategories = res.data.map((group) => ({
            id: group[0].id,
            name: group[0].dhname,
            image: group[0].imagename
              ? `https://app.billhost.co.in/dishhead/${group[0].imagename}`
              : "https://app.billhost.co.in/dishhead/NA.png",
          }));
          setCategories(formattedCategories);
        })
        .catch((err) => console.error("Error fetching categories:", err));
    }
  }, [link_shopid]);

  const searchData = async (e) => {

    const searchValue = document.getElementById("search").value.trim();
    if (searchValue == "") {
      await axios.get(`${hostlink}/ItemSearchBydtcode/${link_shopid}/${selectedCategory}`)
        .then((res) => {
          const formattedItems = res.data.map((item) => ({
            id: item.id,
            name: item.itname,
            rate: item.restrate,
            image: item.imagename
              ? `https://app.billhost.co.in/item/${item.imagename}`
              : "https://app.billhost.co.in/item/NA.png",
          }));
          setItems(formattedItems);
          setIsLoading(false);
        })
        .catch((err) => console.error("Error fetching items:", err));
    } else {
      await axios.get(`${hostlink}/${link_shopid}/item/search/${document.getElementById("search").value}`)
        .then((res) => {
          const formattedItems = res.data.map((item) => ({
            id: item.id,
            name: item.itname,
            rate: item.restrate,
            image: item.imagename
              ? `https://app.billhost.co.in/item/${item.imagename}`
              : "https://app.billhost.co.in/item/NA.png",
          }));
          setItems(formattedItems);
          setIsLoading(false);
        })
        .catch((err) => console.error("Error fetching items:", err));
    }
  }
  const handleCategoryClick = async (categoryId) => {
    setIsLoading(true);
    setSelectedCategory(categoryId);
    await axios.get(`${hostlink}/ItemSearchBydtcode/${link_shopid}/${categoryId}`)
      .then((res) => {
        const formattedItems = res.data.map((item) => ({
          id: item.id,
          name: item.itname,
          rate: item.restrate,
          image: item.imagename
            ? `https://app.billhost.co.in/item/${item.imagename}`
            : "https://app.billhost.co.in/item/NA.png",
        }));
        setItems(formattedItems);
        setIsLoading(false);
      })
      .catch((err) => console.error("Error fetching items:", err));
    setIsLoading(false);
  };

  const addItem = (itemId) => {
    setCart((prevCart) => ({
      ...prevCart,
      [itemId]: (prevCart[itemId] || 0) + 1,
    }));
  };

  const removeItem = (itemId) => {
    setCart((prevCart) => {
      const updatedCart = { ...prevCart };
      if (updatedCart[itemId] > 1) {
        updatedCart[itemId] -= 1;
      } else {
        delete updatedCart[itemId];
      }
      return updatedCart;
    });
  };




  const VeryfyCustomer = async (e) => {

    if (!custmob || custmob.length < 10 || custmob.length > 12 || !/^\d{10,12}$/.test(custmob)) {
      alert("Please enter a valid mobile number");
      document.getElementById("custmob").focus();
      return;
    }
    if (!custname || custname.length < 3) {
      alert("Please enter a valid Name.");
      document.getElementById("custname").focus();
      return;
    }

    setIsLoading(true)
    try {
      const sqlQuery = `select status,custname,custmob from tablemas where shopid = ${link_shopid} and id = ${link_tablecode}`;
      const billResponse = await axios.post(`${hostlink}/api/executeQueryNew`, { sqlQuery });
      // const billData = billResponse.data;
      console.log(billResponse.data)
      console.log(billResponse.data[0][0]);
      setisTableStatus(billResponse.data[0][0]);
      if (billResponse.data[0][0] == 0) {
        setCanOrder(true);
        setIsModalOpenCustomer(false);
      } else if (billResponse.data[0][0] == 1) {
        if (custmob == billResponse.data[0][2]) {
          setcustdata({ ...custdata, custname: billResponse.data[0][1], custmob: billResponse.data[0][2] });
          setCanOrder(true);
          setIsModalOpenCustomer(false);
        } else {
          alert("Please Enter Last Order Mobile No.");
        }
      } else if (billResponse.data[0][0] == 2) {
        alert("Table Running Please Scan another table");
      }
      else {
        alert("Please Enter Last Order Mobile No.");
      }
      // setData(billResponse.data)
      setIsLoading(false)
    } catch (error) {
      console.log("Data Not Found...");
      setIsLoading(false);
      alert("Please Scan valid QR..");
    }
  };

  const HistoryItem = async (e) => {

    setIsLoading(true)
    try {
      const sqlQuery = `select shopvno,itname,qty from kotmas where shopid = ${link_shopid} and tablecode = ${link_tablecode} and blno = 0`;
      const billResponse = await axios.post(`${hostlink}/api/executeQueryNew`, { sqlQuery });
      console.log(sqlQuery);
      setHistoryitems(billResponse.data)
      setIsLoading(false);
      setShowModalRecent(true)
    } catch (error) {
      console.log("Data Not Found...");
      setShowModalRecent(false)
      setIsLoading(false);
      alert("Something Else...");
    }
  };

  const getTotalAmount = () => {
    return items.reduce((total, item) => {
      return total + (cart[item.id] || 0) * item.rate;
    }, 0);
  };
  const generateOTP = () => {
    // Generate a random number (10 digits)
    const randomNumber = Math.floor(Math.random() * 9000000000) + 1000000000;

    // Add some unique string (e.g., timestamp or user ID) for uniqueness
    const timestamp = Date.now().toString();
    const otp = CryptoJS.HmacSHA256(randomNumber.toString(), timestamp).toString(CryptoJS.enc.Base64);

    // Ensure the OTP is exactly 10 digits
    return otp.slice(0, 10);
  };

  const placeOrder = async () => {
    document.getElementById("placeorder").disabled = true;
    document.getElementById("placeordercancel").disabled = true;

    const time = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    const timeotp = generateOTP();
    const totalQty = Object.values(cart).reduce((sum, qty) => sum + qty, 0);
    const totalApproxAmt = getTotalAmount();


    const orderItems = Object.keys(cart).map((itemId) => {
      const item = Allitems.find(i => i.id.toString() === itemId.toString()); // Ensure type match
      return {
        rawcode: itemId,
        barcode: item ? item.barcode : "",
        qty: cart[itemId],
        itname: item ? item.itname : "",
        rate: item ? item.restrate : 0,
        gst: item ? item.gst : 0,
        vat: item ? item.vat : 0,
        cess: item ? item.cess : 0,
        shopid: link_shopid,
        kdsstatus: 1,
        timeotp: timeotp,
        kottime: time,
        tablecode: link_tablecode,
        tablename: link_tablename,
        wcode: 0,
        wname: '',
        nop: 1,
        kottype: 0,
        bltype: 0,
        discperc: 0,
        discamt: 0,
        bldiscperc: 0,
        bldiscamt: 0,
        taxableamt: 0,
        servicechperc: 0,
        servicechamt: 0,
        itcomment: '',
        ittotal: 0,
        havetopack:0,
        bldlvchperc: 0,
        bldlvchamt: 0,
        bldlvchamount: 0,
        totqty: totalQty,
        totaltaxableamt: 0,
        totgst: 0,
        totcess: 0,
        totdiscamt: 0,
        totbldiscamt: 0,
        totblamt: totalApproxAmt,
        totordamt: totalApproxAmt,
        custname: custname,
        custmob: custmob,
      };
    });

    if (orderItems.length === 0) {
      alert("Please add items to place an order!");
      document.getElementById("placeorder").disabled = false;
      document.getElementById("placeordercancel").disabled = false;
      return;
    }
    await axios.post(`${hostlink}/DineinKOT`, orderItems)
      .then((res) => {
        alert(`Order placed successfully! Your Ord No : ${res.data}`);
        setCart({});
        setShowModal(false); // Close modal
        document.getElementById("placeorder").disabled = false;
        document.getElementById("placeordercancel").disabled = false;
      })
      .catch((err) => {
        console.error("Error placing order:", err);
        alert("Failed to place order!");
        document.getElementById("placeorder").disabled = false;
        document.getElementById("placeordercancel").disabled = false;
      });
  };

  return (
    <div className="container-fluid vh-100" style={{ marginTop: "-50px" }}>
      <div className="container">
        {/* Table Number */}
        <div className="row bg-primary text-white rounded p-2 shadow-sm">
          <div className="col-12 text-center fw-bold fs-10">🪑 Table No : <span className="fw-bold">{link_tablename}</span></div>
        </div>

        {/* Customer Details */}
        <div className="row bg-light rounded shadow-sm mt-2">
          <div className="col-6">
            <label className="fw-semibold">👤 {custname}</label>
          </div>
          <div className="col-6">
            <label className="fw-semibold">📞 {custmob}</label>
          </div>
        </div>

        {/* Search Bar */}
        <div className="row mt-1">
          <div className="col-12">
            <input
              type="text"
              placeholder="🔍 Search for items..."
              className="form-control shadow-sm border rounded-pill p-2"
              autoComplete="off"
              onChange={(e) => { searchData(e) }} name="search" id="search"
            />
          </div>
        </div>
      </div>
      {/* Categories & Items */}
      <div className="row h-80">
        {/* Left - Categories */}
        <div className="col-3 col-md-3 bg-light p-2">
          <h6 className="text-center">Categories</h6>

          {/* Scrollable container */}
          <div
            className="scroll-container"
            style={{
              maxHeight: "80vh",   // Adjust height as needed
              overflowY: "auto",
              paddingRight: "0px",
            }}
          >
            <div className="d-flex flex-column align-items-center gap-2">
              {categories.map((category) => (
                <button
                  key={category.id}
                  onClick={() => handleCategoryClick(category.id)}
                  className="border-0 bg-transparent text-center"
                >
                  <img
                    src={category.image}
                    alt={category.name}
                    className="rounded-circle"
                    style={{ width: "80px", height: "80px", objectFit: "cover" }}
                  />
                  <p className="small mt-1">{category.name}</p>
                </button>
              ))}
            </div>
          </div>
        </div>


        {/* Right - Items */}
        <div className="col-9 col-md-9  p-2">
          {/* Scrollable container */}
          <div
            className="scroll-container"
            style={{
              maxHeight: "80vh",  // Adjust height as needed
              overflowY: "auto",
              paddingRight: "0px"
            }}
          >
            <div className="row p-2">
              {items.map((item) => (
                <div key={item.id} className="col-4 col-md-3 p-1 text-center">

                  {/* Product Image */}
                  <img
                    src={item.image}
                    alt={item.name}
                    className="shadow rounded"
                    style={{ width: "80px", height: "80px", objectFit: "cover" }}
                  />

                  {/* Item Name + Price */}
                  <div
                    style={{
                      minHeight: "60px",  // FIXED HEIGHT TO ENSURE ALIGNMENT
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center"
                    }}
                  >
                    <span className="text-danger font-weight-bold">₹{item.rate}</span>
                    <p
                      className="small mt-1"
                      style={{
                        width: "100px",
                        fontSize: "12px",
                        margin: "0 auto",
                        textAlign: "center",
                        display: "-webkit-box",
                        WebkitLineClamp: 2, // Ensures max 2 lines
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        wordBreak: "break-word",
                        minHeight: "30px", // ENSURE FIXED HEIGHT FOR NAME
                        lineHeight: "15px", // KEEP SPACING CONSISTENT
                      }}
                      title={item.name}
                    >
                      {(() => {
                        let text = item.name.padEnd(50, "\u00A0"); // Fill spaces if short
                        let firstRow = text.substring(0, 25).trim();
                        let secondRow = text.substring(25, 50).trim();
                        return `${firstRow}\n${secondRow}`;
                      })()}
                    </p>
                  </div>

                  {/* Add / Remove Buttons */}
                  <div
                    className="d-flex justify-content-center align-items-center border p-1 w-100"
                    style={{ minHeight: "35px" }} // FIXED HEIGHT TO ALIGN BUTTONS
                  >
                    {cart[item.id] > 0 ? (
                      <>
                        <button className="btn btn-sm btn-danger" onClick={() => removeItem(item.id)}>−</button>
                        <span className="small mx-2">{cart[item.id]}</span>
                        <button className="btn btn-sm btn-success" onClick={() => addItem(item.id)}>+</button>
                      </>
                    ) : (
                      <button className="btn btn-sm btn-primary w-100" onClick={() => addItem(item.id)}>Add</button>
                    )}
                  </div>

                </div>
              ))}
            </div>

          </div>
        </div>

      </div>

      {/* Order Button */}
      {isCanOrder && (
        <div className="row p-1 fixed-bottom bg-white border-top">
          <div className="col-4 text-center"><button className="btn btn-outline-dark w-100" onClick={() => HistoryItem()}><i class="fa-solid fa-clock-rotate-left"></i> History</button></div>
          <div className="col-3 text-center">
            {/* <button className="btn btn-outline-danger w-100"> <i class="fa-solid fa-bell"></i> Bill</button> */}
          </div>
          <div className="col-5 text-center"><button className="btn btn-primary w-100 " onClick={() => setShowModal(true)}><i class="fa-solid fa-angles-right"></i> Order ₹{getTotalAmount()}</button></div>
        </div>
      )}
      {/* Order Summary Modal */}

      {showModal && (
        <div className="modal fade show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Order Summary</h5>
                <button type="button" className="close" onClick={() => setShowModal(false)}>&times;</button>
              </div>
              <div className="modal-body">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Item Name</th>
                      <th>Qty</th>
                      <th>Rate</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.filter(item => cart[item.id]).map(item => (
                      <tr key={item.id}>
                        <td>{item.name}</td>
                        <td>{cart[item.id]}</td>
                        <td>₹{item.rate * cart[item.id]}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th colSpan="2">Approx Total</th>
                      <th>₹{getTotalAmount()}</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" id="placeordercancel" onClick={() => setShowModal(false)}>Close</button>
                <button className="btn btn-primary" id="placeorder" onClick={placeOrder}>Place Order</button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showModalRecent && (
        <div className="modal fade show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Recent Order</h5>
                <button type="button" className="close" onClick={() => setShowModalRecent(false)}>&times;</button>
              </div>
              <div className="modal-body">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Ord No</th>
                      <th>Item Name</th>
                      <th>Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Historyitems.map(item => (
                      <tr key={item.id}>
                        <td>{item[0]}</td>
                        <td>{item[1]}</td>
                        <td>{item[2]}</td>
                      </tr>
                    ))}
                  </tbody>



                </table>
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" id="placeordercancel" onClick={() => setShowModalRecent(false)}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}


      <Modal isOpen={isModalOpenCustomer} onRequestClose={handleCloseModalCustomer} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
        <div>
          <h4><u className="text-primary">Fill Details for Order..</u></h4>
          <div className="row  p-1">
            <div className="col-4">Mobile No:</div>
            <div className="col-8"><input type="number" class="form-control" onChange={(e) => { const value = e.target.value.slice(0, 12); if (/^\d{0,12}$/.test(value)) onChangevalue({ target: { name: "custmob", value } }); }} name="custmob" value={custmob} id="custmob" /></div>
          </div>
          <div className="row p-1">
            <div className="col-4">Name :</div>
            <div className="col-8"><input type="text" class="form-control" onChange={(e) => { onChangevalue(e) }} name="custname" value={custname} id="custname" /></div>
          </div>
          <p></p>
          <div className="row p-1">
            <div className="col-6"><button className="no-button w-100" onClick={handleNoClickCustomer}> <i class="fa-solid fa-xmark"></i> Cancel</button></div>
            <div className="col-6"> <button className="yes-button w-100" onClick={(e) => { VeryfyCustomer(e) }} >Proceed <i class="fa-solid fa-circle-chevron-right"></i></button></div>
          </div>
        </div>
      </Modal>

      <ToastContainer autoClose={1000}></ToastContainer>
      {isLoading && <Loading />}
    </div>

  );
};

export default SelfOrder;

