
import React, { useState, useEffect } from "react";
import axios from "axios";
import hostlink from "../Hostlink/hostlink";

const SalaryList = () => {
  const [salaryData, setSalaryData] = useState([]);
  const [month, setMonth] = useState("April");
  const [year, setYear] = useState(2025);

  useEffect(() => {
    fetchSalaries();
  }, [month, year]);

  const fetchSalaries = async () => {
    try {
      const response = await axios.get(`${hostlink}/salary/calculate-all`, {
        params: { monthName: month, year: year },
      });
      setSalaryData(response.data);
    } catch (error) {
      console.error("Error fetching salary data", error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-xl font-bold mb-4">Employee Salary List</h2>
      
      {/* Month & Year Selection */}
      <div className="mb-4 flex gap-4">
        <select value={month} onChange={(e) => setMonth(e.target.value)} className="border p-2">
          {["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"].map((m) => (
            <option key={m} value={m}>{m}</option>
          ))}
        </select>

        <input type="number" value={year} onChange={(e) => setYear(e.target.value)} className="border p-2 w-20" />
        
        <button onClick={fetchSalaries} className="bg-blue-500  px-4 py-2">Fetch Salaries</button>
      </div>

      {/* Table Display */}
      <table className="w-full border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-200">
            <th className="border p-2">Employee Name</th>
            <th className="border p-2">Total Days</th>
            <th className="border p-2">Present</th>
            <th className="border p-2">Absent</th>
            <th className="border p-2">Holidays</th>
            <th className="border p-2 text-end">Final Salary</th>
          </tr>
        </thead>
        <tbody>
          {salaryData.length === 0 ? (
            <tr>
              <td colSpan="6" className="text-center p-4">No salary data available</td>
            </tr>
          ) : (
            salaryData.map((employee, index) => (
              <tr key={index} className="border">
                <td className="border p-2">{employee.employeeName}</td>
                <td className="border p-2">{employee.totalDays}</td>
                <td className="border p-2">{employee.presentDays}</td>
                <td className="border p-2">{employee.absentDays}</td>
                <td className="border p-2">{employee.holidays}</td>
                <td className="border p-2 font-bold text-end">₹ {employee.finalSalary.toFixed(2)}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default SalaryList;

