import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import hostlink from '../Hostlink/hostlink';

import axios from 'axios';
import Modal from 'react-modal';
import PrintKot from './PrintKot';
import { UserLoginDetails } from '../Hostlink/UserLoginDetails'
const KotView = () => {
    const [isBluetoothConnected, setIsBluetoothConnected] = useState(false);
    const [printerDevice, setPrinterDevice] = useState(null);
    const [printCharacteristic, setPrintCharacteristic] = useState(null);
    const [deviceName, setDeviceName] = useState("");
    const [characteristicUUID, setCharacteristicUUID] = useState("");
    const [isPrinterConnected, setIsPrinterConnected] = useState(false);
    const [inputText, setInputText] = useState("");
    const checkBluetoothConnectionOnPrint = async () => {
        return new Promise(async (resolve, reject) => {
            if (printCharacteristic) {
                resolve(true); // Bluetooth is already connected
            } else {
                try {
                    // Open Bluetooth list if not connected
                    await openBluetoothList(); // Wait for Bluetooth list to open

                    const interval = setInterval(() => {
                        if (printCharacteristic) {
                            clearInterval(interval); // Stop checking once connected
                            resolve(true); // Bluetooth is now connected
                        }
                    }, 1000); // Check every second
                } catch (error) {
                    console.error("Error during Bluetooth connection:", error);
                    reject(false); // Reject the promise if something goes wrong
                }
            }
        });
    };


    // Helper: Find a writable characteristic
    const findWritableCharacteristic = async (server) => {
        const services = await server.getPrimaryServices();
        for (let service of services) {
            const characteristics = await service.getCharacteristics();
            for (let characteristic of characteristics) {
                if (characteristic.properties.write) {
                    setIsBluetoothConnected(true);
                    toast.success("Bluetooth Connected")
                    console.log("Writable characteristic found:", characteristic.uuid);
                    return characteristic;
                }
            }
        }
        return null;
    };

    // Open Bluetooth list for device selection
    const openBluetoothList = async () => {
        try {
            const device = await navigator.bluetooth.requestDevice({
                acceptAllDevices: true,
                optionalServices: ["00001800-0000-1000-8000-00805f9b34fb"],
            });

            const server = await device.gatt.connect();
            const writableCharacteristic = await findWritableCharacteristic(server);

            if (writableCharacteristic) {
                savePrinterDetails(device.name, writableCharacteristic.uuid);
                setPrinterDevice(device);
                setPrintCharacteristic(writableCharacteristic);
                setIsPrinterConnected(true);

                // alert(`Connected to printer: ${device.name}`);
            } else {
                alert("Writable characteristic not found! Try again.");
            }
        } catch (error) {
            alert("Failed to open Bluetooth list: " + error.message);
        }
    };

    // Save printer details to localStorage
    const savePrinterDetails = (deviceName, characteristicUUID) => {
        localStorage.setItem("deviceName", deviceName);
        localStorage.setItem("characteristicUUID", characteristicUUID);
        setDeviceName(deviceName);
        setCharacteristicUUID(characteristicUUID);
    };

    const extractTextFromHTML = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || doc.body.innerText || '';
    };

    const formatTextForPrinting = (text) => {
        // You can format the text further, like adding line breaks, headers, etc.
        // For example:
        const lines = text.split('\n');
        return lines.map(line => `${line}\n`).join('');
    };

    const printTextUSB = async (data) => {
        try {
            const response = await fetch(`${hostlink}/printKOT/${Number(appState?.[0]?.printertypesize || 48)}/${encodeURIComponent(data[0].KitchenPrinter)}`, {
                // const response = await fetch(`${hostlink}/printKOT/${Number(appState?.[0]?.printertypesize || 48)}/${encodeURIComponent("NGX_TP900")}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                body: JSON.stringify(data)
            });
            if (response.ok) {
                console.log('Print command sent successfully');
            } else {
                console.error('Failed to send print command:', response.statusText);
            }
        } catch (error) {
            console.error('Error sending print command:', error);
        }
    };

    const printTextBLUETOOTH = async (data) => {

        // Collecting data and formatting
        const formattedKotNo = `Kot No: ${data[0].kotMasDTO.shopvno}`;
        const tableInfo = data.length > 0 && data[0].tablename !== null ? `Table : ${data[0].tablename}` : "";
        const formattedTime = `Time : ${data[0].kotMasDTO.kottime}`;
        const formattedDate = `Date : ${data[0]?.kotMasDTO?.kotdate ? new Date(data[0]?.kotMasDTO?.kotdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }) : "No data"}`;
        const formattedNOP = data.length > 0 && data[0]?.kotMasDTO?.nop !== null ? `NOP : ${data[0]?.kotMasDTO?.nop}` : "";
        const formattedWaiter = data.length > 0 && data[0]?.kotMasDTO?.wname !== null ? `Waiter : ${data[0]?.kotMasDTO?.wname}` : "Waiter : NA";

        // Header labels for the item table
        const formattedSRNO = "SN";
        const formattedITEMDETAIL = "Item Name";
        const formattedQTY = "QTY";
        const TotalFooter = "Total :";

        // Calculating total quantity
        const totalQty = data.reduce((total, resp) => total + parseFloat(resp.kotMasDTO.qty), 0).toFixed(2);

        // Ensure printer connection
        if (!printCharacteristic) {
            alert("No writable characteristic found! Please connect the printer.");
            openBluetoothList();
            return;
        }

        try {
            // Encoder for converting text to bytes
            const encoder = new TextEncoder();
            const MAX_LINE_WIDTH = Number(appState?.[0]?.printertypesize || 48);  //  3 inch 48, 2 inch 32

            const dashedLine = '-'.repeat(MAX_LINE_WIDTH);  // Create dashed line for separator

            // Format each section based on the max width available
            const KOTNO_TABLE = `${formattedKotNo.padEnd(MAX_LINE_WIDTH / 2, ' ')}${tableInfo.padStart(MAX_LINE_WIDTH / 2, ' ')}`;
            const DATE_TIME = `${formattedDate.padEnd(MAX_LINE_WIDTH / 2, ' ')}${formattedTime.padStart(MAX_LINE_WIDTH / 2, ' ')}`;
            const WAITER_NOP = `${formattedWaiter.padEnd(MAX_LINE_WIDTH / 2, ' ')}${formattedNOP.padStart(MAX_LINE_WIDTH / 2, ' ')}`;
            const THEADER = `${formattedSRNO.padEnd(3, ' ')}${formattedITEMDETAIL.padEnd(MAX_LINE_WIDTH - 3 - 8, ' ')}${formattedQTY.padStart(8, ' ')}`;
            const TOTAL_FOOTER = `${TotalFooter.padStart(MAX_LINE_WIDTH - 8, ' ')}${totalQty.padStart(8, ' ')}`;


            // ESC/POS Commands for formatting
            const alignCenter = new Uint8Array([0x1b, 0x61, 0x01]); // Align center
            const alignLeft = new Uint8Array([0x1b, 0x61, 0x00]); // Align left
            const alignRight = new Uint8Array([0x1b, 0x61, 0x02]);
            const boldOn = new Uint8Array([0x1b, 0x45, 0x01]); // Bold ON
            const boldOff = new Uint8Array([0x1b, 0x45, 0x00]); // Bold OFF
            const doubleSize = new Uint8Array([0x1d, 0x21, 0x11]); // Double height & width
            const normalSize = new Uint8Array([0x1d, 0x21, 0x00]); // Normal size

            // Print Company Name (Centered, Bold, Double Size)
            await printCharacteristic.writeValue(alignCenter);
            await printCharacteristic.writeValue(boldOn);
            await printCharacteristic.writeValue(doubleSize);
            await printCharacteristic.writeValue(encoder.encode(`${appState?.[0]?.cname || '-'}` + "\n"));

            // Print KOT Type (Centered, Bold, Normal Size)
            await printCharacteristic.writeValue(normalSize);
            await printCharacteristic.writeValue(boldOff);
            await printCharacteristic.writeValue(encoder.encode(`${data[0].KottypeName || '-'}` + "\n"));

            // Print Dashed Line
            await printCharacteristic.writeValue(encoder.encode(dashedLine + "\n"));

            // Print Kitchen Name (Normal, Left-aligned)
            await printCharacteristic.writeValue(alignLeft);
            await printCharacteristic.writeValue(encoder.encode(`Kitchen : ${data[0].Kitchenname || '-'}` + "\n"));

            // Print Dashed Line
            await printCharacteristic.writeValue(encoder.encode(dashedLine + "\n"));

            // Print KOT No & Table info
            await printCharacteristic.writeValue(encoder.encode(KOTNO_TABLE + "\n"));

            // Print Date & Time info
            await printCharacteristic.writeValue(encoder.encode(DATE_TIME + "\n"));

            // Print Waiter & NOP info
            await printCharacteristic.writeValue(encoder.encode(WAITER_NOP + "\n"));

            // Print Dashed Line
            await printCharacteristic.writeValue(encoder.encode(dashedLine + "\n"));

            // Print Header for Item Table (SN, Item Name, Qty)
            await printCharacteristic.writeValue(encoder.encode(THEADER + "\n"));

            // Print Dashed Line
            await printCharacteristic.writeValue(encoder.encode(dashedLine + "\n"));

            // Reset to default alignment
            await printCharacteristic.writeValue(alignLeft);

            for (let i = 0; i < data.length; i++) {
                const item = data[i];

                // Format each row (SN, Item Name, QTY)
                const formattedRow = `${(i + 1).toString().padEnd(3, ' ')}${(usershopiditemprintname === 0 ? item.kotMasDTO.itname : item.ItemPrintname).padEnd(MAX_LINE_WIDTH - 3 - 8, ' ')}${item.kotMasDTO.qty.toFixed(2).padStart(8, ' ')}`;

                // Send the formatted row to the printer
                await printCharacteristic.writeValue(encoder.encode(formattedRow + "\n"));
            }
            // Print Dashed Line
            await printCharacteristic.writeValue(encoder.encode(dashedLine + "\n"));

            await printCharacteristic.writeValue(alignRight);
            await printCharacteristic.writeValue(encoder.encode(TOTAL_FOOTER + "\n"));

            await printCharacteristic.writeValue(encoder.encode(dashedLine + "\n"));
            // Reset to default alignment
            await printCharacteristic.writeValue(alignLeft);


            // ESC/POS Cut Command
            const cutCommand = new Uint8Array([0x1d, 0x56, 0x00]); // Full cut
            await printCharacteristic.writeValue(cutCommand);


        } catch (error) {
            console.error("Failed to send print command:", error);
            alert("Failed to send print command: " + error.message);
        }
    };

    const { appState, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const fs_cname = appState?.[0]?.fs_cname || 36;
    const usershopidref = appState?.[0]?.hotelshopid || 0;
    const usershopiditemprintname = appState?.[0]?.otheritemprintname || 0;
    const queryConditionkitchen = usershopidref !== 0 ? usershopidref : usershopid;
    const [PrintClicked, setPrintClicked] = useState(false);
    const [isModalOpenCancel, setIsModalOpenCancel] = useState(false);
    const handleOpenModalCancel = (shopvno, tablecode) => { setMessagedata({ ...messagedata, shopvno: shopvno, tablecode: tablecode, reason: "" }); setIsModalOpenCancel(true); };
    const handleCloseModalCancel = () => { setIsModalOpenCancel(false); };
    const handleNoClickCancel = () => { console.log('User clicked No'); handleCloseModalCancel(); };
    const cancelBill = (e) => {
        console.log(reason)
        if (reason === "") { toast.error("Please Enter Reason..."); return false }
        console.log(`${hostlink}/CancelOrder/${usershopid}/${shopvno}/${tablecode}/${reason}`)

        axios.post(`${hostlink}/CancelOrder/${usershopid}/${shopvno}/${tablecode}/${reason}`)
            .then((Response) => {
                printdata(shopvno);
                toast.success("Cancelled Sucessfull")
                setTimeout(() => {
                    setitemdetailGridRefresh(true)
                    setIsModalOpenCancel(false);

                }, 1000);
            })
            .catch(() => {
                toast.error("Something Else...");
            })
    }

    const printdata = async (e) => {

        try {
            if (appState?.[0]?.printertype == 1) {
                if (!isBluetoothConnected) {
                    await checkBluetoothConnectionOnPrint();
                    return false
                }
            }

            // Fetch the data from the server
            const response = await axios.get(`${hostlink}/KotviewOneKitchen/${usershopid}/${e}/${queryConditionkitchen}`);

            if (response && response.data && response.data.length > 0) {
                // Group the items by kitchen
                const kitchens = response.data.reduce((acc, item) => {
                    const kitchen = item.Kitchenname;
                    if (!acc[kitchen]) acc[kitchen] = [];
                    acc[kitchen].push(item);
                    return acc;
                }, {});

                // Iterate over each kitchen and print one by one
                for (const kitchen in kitchens) {

                    const printContent = generatePrintContent(kitchens[kitchen]); // Generate content for this kitchen
                    const data = `1,${hostlink}/KotviewOne/${usershopid}/${e},${printContent}`;

                    if (appState?.[0]?.printertype == 1) {
                        await printTextBLUETOOTH(kitchens[kitchen]); // Bluetooth printer is selected, print using Bluetooth
                    } else if (appState?.[0]?.printertype == 2) {
                        await printTextUSB(kitchens[kitchen]); // Print USB
                    } else if (window.ReactNativeWebView) {
                        window.ReactNativeWebView.postMessage(data); // Print using WebView
                    } else {
                        // Print using iframe method (fallback)
                        await printForKitchen(printContent);
                    }

                    // Check if ReactNativeWebView is available
                    // if (appState?.[0]?.printertype == 1) {
                    //     await printText(kitchens[kitchen]);
                    // } else {
                    //     window.ReactNativeWebView.postMessage(data);
                    // }
                    // if (window.ReactNativeWebView) {
                    //     window.ReactNativeWebView.postMessage(data);
                    // } else {
                    //     // Print using iframe method
                    //     await printForKitchen(printContent);
                    // }
                }
            } else {
                console.error("Empty or invalid response received.");
            }
        } catch (error) {
            console.error("Error fetching or printing data:", error);
        }
    };

    // Helper function to handle printing for each kitchen
    const printForKitchen = async (printContent) => {
        return new Promise((resolve, reject) => {
            // Create a new iframe for each kitchen print job
            const printWindow = document.createElement('iframe');
            printWindow.style.position = 'absolute';
            printWindow.style.width = '0';
            printWindow.style.height = '0';
            printWindow.style.border = 'none';
            document.body.appendChild(printWindow);

            const doc = printWindow.contentWindow.document;
            doc.open();
            doc.write(printContent);
            doc.close();

            // Set CSS for printing
            const style = doc.createElement('style');
            style.innerHTML = `
                @media print {
                    body, html {
                        width: 80mm;
                        margin: 0;
                        padding: 0;
                    }
                    * {
                        box-sizing: border-box;
                    }
                    @page {
                        margin: 0;
                        size: 80mm 297mm;
                    }
                }
            `;
            doc.head.appendChild(style);

            // Ensure the content is fully loaded before printing
            printWindow.onload = () => {
                try {
                    const contentHeight = doc.body.scrollHeight;
                    console.log(`Content height for kitchen:`, contentHeight);
                    printWindow.contentWindow.print();

                    if (contentHeight > 210) {
                        doc.body.innerHTML += '\x1D\x56\x00'; // Manual cut command for large content
                    }
                    resolve();
                } catch (error) {
                    reject(error);
                }
            };
        });
    };


    const generatePrintContent = (data) => {
        const tableInfo = data.length > 0 && data[0].tablename !== null ? `<span style="text-align: end; width:50%;"><p1 style="text-align: end; width:50%;">Table : ${data[0].tablename}</p1></span>` : '';
        return `<style>*{margin: 0;padding: 0;box-sizing: border-box;}div{width: 2.7in;}hr{width: 2.7in;}</style><div style="font-family: 'Bahnschrift Condensed'; width: 2.7in;"><p1 style="display: block; margin: 0 auto;text-align: center;"> <h1>${data[0].kotMasDTO.status == 2 ? `Cancelled` : ''}</h1><h5>${data[0].kotMasDTO.status == 2 ? `Reason : ${data[0].kotMasDTO.cancelreason}` : ''}</h5> ${data.length > 0 ? data[0].KottypeName : ''}</p1>
        <h1 style="text-align: center;font-Size:${fs_cname}px;">${appState?.[0]?.cname || '-'}</h1>       
        <hr style="borderTop: 1px solid black; width: 2.7in;" /><span style="display:flex;"><p1 style="text-align: start; width:100%;">Kitchen : ${data.length > 0 ? data[0].Kitchenname : ''}</span>
        <hr style="borderTop: 1px solid black; width: 2.7in;" /><span style="display:flex;"><p1 style="text-align: start; width:50%;">Kot No : ${data.length > 0 ? data[0].kotMasDTO.shopvno : ''}</p1>${tableInfo}</p1></span>
        <span style="display:flex; width: 2.7in;"><p1 style="text-align: start; width:50%;">Date : ${data[0].kotMasDTO.kotdate ? new Date(data[0].kotMasDTO.kotdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"}</p1><p1 style="text-align: end; width:50%;"> Time : ${data[0].kotMasDTO.kottime}</p1></span>
        <span style="display:flex; width: 2.7in;"><p1 style="text-align: start; width:50%;">Waiter : ${data[0].kotMasDTO.kotdate ? data[0].kotMasDTO.wname : "NA"}</p1><p1 style="text-align: end; width:50%;"> NOP : ${data[0].kotMasDTO.kotdate ? data[0].kotMasDTO.nop : "NA"}</p1></span>       
        <table style="border-Top: 1px solid black; width: 2.7in;"><tr style="border-bottom: 1px solid black;"><td style="width: 10%;border-bottom: 1px solid black;">SN</td><td style="width: 80%;border-bottom: 1px solid black;">Item Name</td><td style="width: 10%;border-bottom: 1px solid black; text-align: end;">Qty</td></tr>
          ${data.map((x, index) => `<tr style="width: 100%"><td style="width: 10%">${index + 1}</td><td style="width: 80%">
        ${usershopiditemprintname === 0 ? (
                x.kotMasDTO.itname
            ) : (
                x.ItemPrintname
            )}

            ${x.kotMasDTO.itcomment !== '' ? `<br />Comment: ${x.kotMasDTO.itcomment}` : ''} 
            ${x.kotMasDTO.havetopack === 1 ? `<br />(Have to Pack)` : ''}  
            
            </td><td style="width: 10%; text-align: end;">${x.kotMasDTO.qty.toFixed(2)}</td></tr>`).join('')}</table><hr style="borderTop: 1px solid black; width: 2.7in;" /><span style="display:flex;"><p1 style="text-align: end; width:100%;">Total Qty : ${Number(data.reduce((total, resp) => total + parseFloat(resp.kotMasDTO.qty), 0)).toFixed(2)}</p1></span><hr style="borderTop: 1px solid black; width: 2.7in;" />      
        </div>`;
    };

    const [itemdetailGridRefresh, setitemdetailGridRefresh] = useState(false);
    const [srvdata, setsrvData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalBluetoth, setisModalBluetoth] = useState(false);
    const handleCloseModalBluetoth = () => { setisModalBluetoth(false); };
    const handleNoClickBluetoth = () => { console.log('User clicked No'); handleCloseModalBluetoth(); };

    const [messagedata, setMessagedata] = useState({
        deleteid: "",
        status: "",
        tablecode: "",
        shopvno: "",
        reason: "",
        deletemsg: ""
    })
    const { deleteid, deletemsg, tablecode, shopvno, reason } = messagedata;
    const handleOpenModal = (id, Message, status) => {
        setMessagedata({ ...messagedata, deleteid: id, deletemsg: Message, status: status })
        setIsModalOpen(true);
    };
    const handleCloseModal = () => { setIsModalOpen(false); };
    const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); };
    const today = new Date();
    const [frmdata, setfrmdata] = useState({
        fromdate: today.toISOString().split('T')[0],
        todate: today.toISOString().split('T')[0],
        status: 3
    });
    const { fromdate, todate, status } = frmdata;
    const onChangevalueloaddata = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setfrmdata({ ...frmdata, [name]: sanitizedValue });
        setitemdetailGridRefresh(true)
    }
    const onChangeCancelReason = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setMessagedata({ ...messagedata, [name]: sanitizedValue });

    }
    const searchData = (e) => {
        const searchValue = document.getElementById("searchroom").value.trim();
        console.log(searchValue)
        if (searchValue === "") {
            axios.get(`${hostlink}/kotview/${usershopid}/${fromdate}/${todate}`)
                .then((Response) => {
                    setsrvData(Response.data)
                    setitemdetailGridRefresh(false)
                })
                .catch(() => { })
        } else {
            axios.get(`${hostlink}/kotviewsearch/${usershopid}/${fromdate}/${todate}/${searchValue}`)
                .then((Response) => {
                    setsrvData(Response.data)
                    setitemdetailGridRefresh(false)
                })
                .catch(() => { })
        }
    }
    const cmdDelete = (vno, status) => {
        if (status === 'No') {
            toast.error("Bill Generated, You Cant't Delete..")
            return false
        }
        axios.delete(`${hostlink}/kotdelete/${usershopid}/${vno}`)
            .then((resp) => {
                toast.success("Delete Sucessfull")
                setTimeout(() => {
                    setitemdetailGridRefresh(true)
                    setIsModalOpen(false);
                }, 1000);
            })
            .catch((resp) => {
                console.log("Something Else")
            })

    }

    useEffect(() => {
        if (usershopid != 0) {
            axios.get(`${hostlink}/kotview/${usershopid}/${fromdate}/${todate}`)
                .then((Response) => {
                    setsrvData(Response.data)
                    setitemdetailGridRefresh(false)
                })
                .catch(() => { })
        }
    }, [itemdetailGridRefresh, usershopid])
    return (
        <div>
            {/* {PrintClicked && <PrintKot arrayToPrint={srvdata} />}  */}

            {PrintClicked && <PrintKot arrayToPrint={srvdata} />}
            <div id='viewform'>
                <div className='container p-2'>
                    <div className='row p-1'>
                        <div className='col-md-2 p-1'>
                            <h5 className='font-monospace'><i class="fa-solid fa-bowl-food"></i> KOT View</h5>
                        </div>
                        <div className='col-md-2 rounded' style={{ display: "flex", alignItems: "center", background: "rgb(245,245,246)", height: "40px" }}>
                            <i class="fa-solid fa-magnifying-glass " ></i> &nbsp;&nbsp; <input type="text" class="" name="searchroom" onChange={(e) => { searchData(e) }} id="searchroom" placeholder="Search Kot" style={{ background: "rgb(245,245,246)", outline: "none", border: "0", width: "100%" }} /> <br />
                        </div>

                        <div className='col-md-6 align-self-end' style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                            <span className="f-size-on-mobile align-self-end" style={{ verticalAlign: 'middle', height: '100%', }}>
                                <label htmlFor="todate" className="form-label text-center">From : &nbsp;</label>
                            </span>
                            <span> <input type="date" id="fromdate" name="fromdate" onChange={(e) => { onChangevalueloaddata(e) }} value={fromdate} className="form-control" /></span>


                            <span className="f-size-on-mobile align-self-end">
                                <label htmlFor="todate" className="form-label text-center">&nbsp; To : &nbsp;</label>
                            </span>
                            <span>
                                <input type="date" id="todate" name="todate" onChange={(e) => { onChangevalueloaddata(e) }} value={todate} className="form-control" />
                            </span>
                        </div>


                        <div className='col-md-2 text-end'>

                        </div>
                        <div style={{ maxHeight: "80vh", overflow: "auto" }} className='col-12 '>
                            <table className="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col" className="f-size-on-mobile"><span className='hide-on-mobile'>Date</span> <span className='show-on-mobile'>Details</span></th>
                                        <th scope="col" className="hide-on-mobile">Time</th>
                                        <th scope="col" className="hide-on-mobile">Kot No</th>
                                        <th scope="col" className="hide-on-mobile">Table</th>
                                        <th scope="col" className="hide-on-mobile">Ord Type</th>
                                        <th scope="col" className="hide-on-mobile">BlNo</th>
                                        {/* <th scope="col" className="hide-on-mobile">Item</th> */}
                                        <th scope="col" className="hide-on-mobile">Ord Qty</th>
                                        <th scope="col" className="hide-on-mobile">Ord Amt</th>
                                        <th scope="col" className="hide-on-mobile">Is Bill</th>
                                        <th scope="col" className="f-size-on-mobile text-center">Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {srvdata.map((res, x) => (
                                        <tr style={{ verticalAlign: 'middle', height: '100%' }}>
                                            <td className="f-size-on-mobile" style={{ wordWrap: "break-word" }}> <span className='show-on-mobile'><b>Date :</b> </span> {res.kotMasDTO.kotdate ? new Date(res.kotMasDTO.kotdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"} <br />
                                                <div className="detail-on-mobile text-start align-self-start" style={{ fontSize: "12px", color: "blue", alignItems: "start", wordWrap: "break-word" }}>
                                                    {res.kotMasDTO.kottime !== 0 && (<>Time : {res.kotMasDTO.kottime ? new Date(`2022-01-01T${res.kotMasDTO.kottime}`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) : "-"} <br /></>)}

                                                    {res.kotMasDTO.itemview !== '' && (<>Item : {res.kotMasDTO.itemview} <br /></>)}
                                                    {res.kotMasDTO.totordamt !== 0 && (<>Order Amount : {res.kotMasDTO.totordamt} <br /></>)} <span style={{ color: "blue" }}> </span>
                                                    {res.KottypeName !== '' && (<>Type : {res.KottypeName} <br /></>)}
                                                    <span className='text-bg-danger'>{res.kotMasDTO.tablename !== '' && (<>Table Name : {res.kotMasDTO.tablename} <br /></>)}</span>
                                                </div>
                                            </td>

                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.kotMasDTO.kottime}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.kotMasDTO.shopvno}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.tablename}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.KottypeName}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.kotMasDTO.blno}</td>
                                            {/* <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.kotMasDTO.itemview}</td> */}
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.kotMasDTO.totqty}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.kotMasDTO.totordamt}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.statusname}</td>
                                            <td className='text-center' style={{ verticalAlign: 'middle', height: '100%' }}>
                                                {(userType === 0 || appState[1]?.[0]?.kotview?.charAt(3) === '1') ? (<Link className='btn btn-sm btn-danger' onClick={(e) => { handleOpenModal(res.kotMasDTO.shopvno, res.kotMasDTO.itemview, res.statusname) }} > <i class="fa-solid fa-trash"></i> <span className="hide-on-mobile"> Delete</span></Link>) : null} &nbsp;
                                                <Link className='btn btn-sm btn-outline-primary' onClick={(e) => { printdata(res.kotMasDTO.shopvno) }}><i class="fa-solid fa-print"></i> <span className="hide-on-mobile">Print</span></Link> &nbsp;
                                                <Link onClick={(e) => { handleOpenModalCancel(res.kotMasDTO.shopvno, res.kotMasDTO.tablecode) }} className='btn btn-sm btn-outline-dark'><i class="fa-solid fa-ban"></i>  <span className="hide-on-mobile"> Cancel </span></Link>&nbsp;
                                                {/* <Link className='btn btn-sm btn-outline-primary' onClick={(e)=>{printdata()}}><i class="fa-solid fa-print"></i> <span className="hide-on-mobile">Print</span></Link> &nbsp; */}
                                                {/* <button onClick={printdata}>Print</button> */}

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                <div><p>Are you sure you want to delete this <br /> Kot, Item : {deletemsg} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { cmdDelete(deleteid, status) }}>Yes</button> <button className="no-button" onClick={handleNoClick}>No</button></div></div>
            </Modal>
            <Modal isOpen={isModalBluetoth} onRequestClose={handleCloseModalBluetoth} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                <div><p> <i class="fa-brands fa-bluetooth-b"></i> Bluetooth is disconnected do you want to connect?</p><div className="button-container"><button className="yes-button" onClick={(e) => { cmdDelete(deleteid, status) }}>Yes</button> <button className="no-button" onClick={handleNoClick}>No</button></div></div>
            </Modal>

            <Modal isOpen={isModalOpenCancel} onRequestClose={handleCloseModalCancel} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                <div clas><p className='text-bg-danger'>Are you sure you want to Cancel this Kot No : {shopvno} ?</p>
                    Reason : <input value={reason} name='reason' id='reason' onChange={(e) => { onChangeCancelReason(e) }} type="text" />
                    {/* <span> <input type="date" id="fromdate" name="fromdate" onChange={(e) => { onChangevalueloaddata(e) }} value={fromdate} className="form-control" /></span> */}
                    <div className="button-container"><button className="yes-button" onClick={(e) => { cancelBill(1) }}>Conform Cancel</button> <button className="no-button" onClick={handleNoClickCancel}>Cancel</button></div></div>
            </Modal>
            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    )
}

export default KotView