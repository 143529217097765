import React, { useEffect, useRef, useState, useContext } from 'react'
import hostlink from '../Hostlink/hostlink';
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { UserLoginDetails } from '../Hostlink/UserLoginDetails';
import Modal from 'react-modal';
import Loading from './Loading';

const TransferBill = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = (e, flname, trfno, trfbillamt,trfbldate) => {
        setMessagedata({ ...messagedata, deleteid: e, deletemsg: flname, trfid: e, blno: trfno, blamt: trfbillamt,bldate:trfbldate })
        setIsModalOpen(true);
    };
    const handleCloseModal = () => { setIsModalOpen(false); };
    const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); };
    const [messagedata, setMessagedata] = useState({
        deleteid: "",
        deletemsg: "", trfid: "", blno: "", blamt: "",bldate:""
    })
    const { deleteid, deletemsg, trfid, blno, blamt,bldate } = messagedata;

    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const usershopidref = appState?.[0]?.roomshopid || 0;
    const today = new Date();
    const [frmdata, setfrmdata] = useState({
        fromdate: today.toISOString().split('T')[0],
        todate: today.toISOString().split('T')[0],
        status: 3
    });
    const { fromdate, todate, status } = frmdata;


    useEffect(() => {
        if (usershopid != 0) {
            const queryCondition = usershopidref == 0
                ? `where shopid = ${usershopid} and entrydate between '${fromdate}' and '${todate}'`
                : `where fromshopid = ${usershopid} and entrydate between '${fromdate}' and '${todate}'`;


            axios.get(`${hostlink}/api/executeQuery?sqlQuery=select restbilltrfmas.*,(select rname from roommas where id = restbilltrfmas.roomcode) as roomname from restbilltrfmas ${queryCondition}`)
                .then((Response) => {
                    setsrvData(Response.data);
                })
                .catch(() => {
                    console.log("Data Not Fetched");
                });
        }

    }, [usershopid, usershopidref, fromdate, todate, hostlink]);




    const [srvdata, setsrvData] = useState([]);

    const trfbill = async (e) => {

        const queryParams = new URLSearchParams({
            bldate,
            trfid,
            blno,
            blamt
        }).toString();
        window.location.assign(`/EntryTrfBillUpdate?${queryParams}`);
    };

    const onChangevalueloaddata = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setfrmdata({ ...frmdata, [name]: sanitizedValue });

    }

    return (
        <div>
            <div id='viewform'>
                <div className='container p-2'>
                    <div className='row p-1'>
                        <div className='col-md-4 p-1'>
                            <h5 className='font-monospace'><i class="fa-solid fa-money-bill-transfer"></i> Transfer Bill</h5>
                        </div>


                        <div className='col-md-6 align-self-end' style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                            <span className="f-size-on-mobile align-self-end" style={{ verticalAlign: 'middle', height: '100%', }}>
                                <label htmlFor="todate" className="form-label text-center">From : &nbsp;</label>
                            </span>
                            <span> <input type="date" id="fromdate" name="fromdate" onChange={(e) => { onChangevalueloaddata(e) }} value={fromdate} className="form-control" /></span>


                            <span className="f-size-on-mobile align-self-end">
                                <label htmlFor="todate" className="form-label text-center">&nbsp; To : &nbsp;</label>
                            </span>
                            <span>
                                <input type="date" id="todate" name="todate" onChange={(e) => { onChangevalueloaddata(e) }} value={todate} className="form-control" />
                            </span>
                        </div>


                        <div className='col-md-2 text-end'>

                        </div>
                        <div style={{ maxHeight: "80vh", overflow: "auto" }} className='col-12 '>
                            <table className="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col" className="f-size-on-mobile"><span className='hide-on-mobile'>Bill Date</span> <span className='show-on-mobile'>Details</span></th>
                                        <th scope="col" className="hide-on-mobile">Time</th>
                                        <th scope="col" className="hide-on-mobile">Bill No </th>
                                        <th scope="col" className="hide-on-mobile">Room No</th>
                                        <th scope="col" className="hide-on-mobile">Trf Fro</th>
                                        <th scope="col" className="hide-on-mobile">Guest Name</th>
                                        <th scope="col" className="hide-on-mobile">Mob No</th>
                                        <th scope="col" className="hide-on-mobile">Bill Amount</th>
                                        {/* <th scope="col" className="f-size-on-mobile text-center">Action</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {srvdata.map((res, x) => (
                                        <tr style={{ verticalAlign: 'middle', height: '100%' }}>
                                            <td className="f-size-on-mobile" style={{ wordWrap: "break-word" }}> <span className='show-on-mobile'><b>Bill Date :</b> </span> {res[5] ? new Date(res[5]).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"} <br />
                                                <div className="detail-on-mobile text-start align-self-start" style={{ fontSize: "12px", color: "blue", alignItems: "start", wordWrap: "break-word" }}>
                                                    {res[6] !== 0 && (<>Time : {res[6] ? new Date(`2022-01-01T${res[6]}`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) : "-"} <br /></>)}
                                                    {res[10] !== 0 && (<>Bill No : {res[2]} <br /></>)} <span style={{ color: "blue" }}> </span>
                                                    {res[10] !== 0 && (<>Bill Amount : {res[12]} <br /></>)} <span style={{ color: "blue" }}> </span>
                                                    <span className='text-bg-danger'>{res[0] !== '' && (<>from : {res[4]} <br /></>)}</span>
                                                </div>
                                            </td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[6]}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[2]}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[13]}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[4]}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[9]}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[8]}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[12]}</td>
                                            <td className='text-center' style={{ verticalAlign: 'middle', height: '100%' }}>
                                                <Link className='btn btn-sm btn-outline-dark' onClick={(e) => { handleOpenModal(res[0], res[13], res[2], res[12], res[5]) }} > <i class="fa-solid fa-arrow-right-arrow-left"></i> <span className="hide-on-mobile"> Change Room</span></Link> &nbsp;
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                <div><p>Are you sure you want to Change <br /> Room No : {deletemsg} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { trfbill() }}>Yes</button> <button className="no-button" onClick={handleNoClick}>No</button></div></div>
            </Modal>
            <ToastContainer autoClose={1000}></ToastContainer>
            {isLoading && <Loading />}
        </div>
    )
}

export default TransferBill