import React, { useContext, useEffect, useRef, useState } from "react";
import { UserLoginDetails } from "../Hostlink/UserLoginDetails";
import axios from "axios";
import hostlink from "../Hostlink/hostlink";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from "./Loading";
const CardReturn = () => {
    const [isLoading, setIsLoading] = useState(false);
    
    const { appState } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const usershopidref = appState?.[0]?.hotelshopid || 0;
    const queryCondition = usershopidref !== 0 ? usershopidref : usershopid;
    const User_cardsecurityAmt = appState?.[0]?.cardsecurityamt || 0;
    const fs_cname = appState?.[0]?.fs_cname || 36;  
    const wallethistoryID = appState?.[0]?.walletreportid || 0;  
    const nameInputRef = useRef(null);
    const mobileInputRef = useRef(null);
    const rechargeInputRef = useRef(null);
    const mop1SelectRef = useRef(null);
    const mop1AmountRef = useRef(null);
    const mop2SelectRef = useRef(null);
    const mop2AmountRef = useRef(null);
    const saveButtonRef = useRef(null);

    const [AccCode, setAccCode] = useState([]);
    const [cmbAccdataBank, setcmbAccdataBank] = useState([]);

    const [CardBalance, setCardBalance] = useState(0);
    const [callcardbalance, setcallcardbalance] = useState(false);

    const [savedata, setSavedata] = useState({
        cardno: "",
        mobno: "",
        custname: "",
        rechamt: "",
        secamt: "",
        cardbalance: "",
        pay1acc: 0,
        pay1amt: 0,
        pay2acc: 0,
        pay2amt: 0,
        narration: "",
        remarks:"",
        noofperson:1,

    });

    const { cardno, mobno, custname, rechamt, cardbalance, secamt, pay1acc, pay1amt, pay2acc, pay2amt,remarks,noofperson } = savedata;

    const onChangeValue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, "");
        setSavedata({ ...savedata, [name]: sanitizedValue });
    };

    const handleKeyDown = (event, nextRef) => {
        if (event.key === "Enter") {
            event.preventDefault();
            if (nextRef && nextRef.current) {
                nextRef.current.focus();
            }
        }
    };

    useEffect(() => {
        if (usershopid !== 0) {

            console.log(User_cardsecurityAmt);
            axios.get(`${hostlink}/${usershopid}/accmas/0`)
                .then((resp) => {
                    setAccCode(resp.data);
                    setSavedata({ ...savedata, pay1acc: resp.data[0].id });
                })
                // .then((response) => setAccCode(response.data))
                .catch(() => console.log("Data Not Found"));

            axios.get(`${hostlink}/${usershopid}/accmas/1`)
                .then((response) => setcmbAccdataBank(response.data))
                .catch(() => console.log("Data Not Found"));
            document.getElementById("cardno").focus();
        }
    }, [usershopid]);


    // useEffect(() => {
    //     if (usershopid !== 0) {
    //         setSavedata({ ...savedata, pay1amt: Number(rechamt) - Number(User_cardsecurityAmt), cardbalance: Number(rechamt) - Number(secamt) });
    //     }
    // }, [rechamt]);

    useEffect(() => {
        setSavedata({ ...savedata, pay2amt: rechamt - pay1amt });
    }, [pay1amt])

    useEffect(() => {
        setSavedata({ ...savedata, pay1amt: rechamt - pay2amt });
    }, [pay2amt])

    useEffect(() => {
        if (cardno?.trim() !== "") {
            axios.get(`${hostlink}/api/executeQuery`, {
                params: { 
                    sqlQuery: `SELECT SUM(cardbalance), status, custname, mobno, noofperson,remarks 
                               FROM cardmaster 
                               WHERE shopid in (${wallethistoryID})
                               AND cardno = '${cardno}' 
                               AND status = 1 and cardstatus = 1`
                }
            })
            .then((resp) => {
                const result = resp.data[0] || [0, 0, "", ""]; // Default values to avoid undefined errors
                
                setCardBalance(result[0]); // Safe handling of balance
            
                setSavedata(prev => ({
                    ...prev,
                    secamt: Number(result[1]) === 1 ? User_cardsecurityAmt : 0,
                    rechamt: Number(result[1]) === 1 ? User_cardsecurityAmt + Number(result[0]) : Number(result[0]),
                    custname: result[2] || "",
                    cardbalance: result[0] || 0,
                    mobno: result[3] || "",
                    noofperson: result[4] || "",
                    remarks: result[5] || "",
                    pay1amt:Number(result[1]) === 1 ? User_cardsecurityAmt + Number(result[0]) : Number(result[0])

                }));
            })
            
            .catch((error) => {
                console.error("Error fetching card data:", error);
                setCardBalance(0); // Reset balance on error
            })
            .finally(() => {
                setcallcardbalance(false); // Ensures it's reset only after the request finishes
            });
        }
    }, [callcardbalance, usershopid]);
    
    
    const SaveData = async (e) => {
        setIsLoading(true);
        const inputValue = document.getElementById("cardno").value.trim();
        if (inputValue === "") {
            document.getElementById("cardno").focus();
            toast.error("Please Scan Card..")
            setIsLoading(false);
            return false
        }
        const inputValue1 = document.getElementById("custname").value.trim();
        if (inputValue1 === "") {
            document.getElementById("custname").focus();
            toast.error("Please Enter Customer Name")
            setIsLoading(false);
            return false
        }
        const inputValue2 = document.getElementById("mobno").value.trim();
        if (inputValue2 === "") {
            document.getElementById("mobno").focus();
            toast.error("Please Enter Customer Mobile No")
            setIsLoading(false);
            return false
        }
        const inputValue3 = document.getElementById("rechamt").value.trim();
        if (Number(inputValue3) == "0") {
            document.getElementById("rechamt").focus();
            toast.error("Please Enter Recharge Amount")
            setIsLoading(false);
            return false
        }

        if (Number(pay1amt) < 0) {
            toast.error("Please select a valid Amount...");
            document.getElementById("cmdsave").disabled = false
            document.getElementById("pay1amt").focus();
            setIsLoading(false);
            return false;
        }

        if (Number(pay2amt) < 0) {
            toast.error("Please select a valid Amount...");
            document.getElementById("cmdsave").disabled = false
            document.getElementById("pay2amt").focus();
            setIsLoading(false);
            return false;
        }

        if (Number(pay1amt) > 0) {
            if (Number(pay1acc) === 0) {
                document.getElementById("pay1acc").focus();
                toast.error("Please select a valid Paymode...");
                document.getElementById("cmdsave").disabled = false
                setIsLoading(false);
                return false;
            }
        }

        if (Number(pay2amt) > 0) {
            if (Number(pay2acc) === 0) {
                document.getElementById("pay2acc").focus();
                document.getElementById("cmdsave").disabled = false
                toast.error("Please select a valid Paymode...");
                setIsLoading(false);
                return false;
            }
        }

        if (Number(pay1amt) + Number(pay2amt) !== Number(rechamt)) {
            toast.error("Please Enter a Valid Amount...");

            document.getElementById("cmdsave").disabled = false
            setIsLoading(false);
            return false;
        }

        if (Number(pay1acc) === Number(pay2acc)) {

            document.getElementById("cmdsave").disabled = false
            toast.error("Please select Valid Paymode...");
            setIsLoading(false);
            return false;
        }
        const time = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
        var ld = { ...savedata, rechamt:rechamt*-1, pay2amt:pay2amt*-1, pay1amt:pay1amt*-1, shopid: usershopid,status:0, cardstatus : 0, entrytime: time, narration: usershopid + cardno + time }

        const myPckartUser = localStorage.getItem('MYPCKARTUSER'); if (!myPckartUser) { toast.error("Please Login First.."); return false }

        document.getElementById("cmdsave").disabled = true
        try {
            await axios.post(`${hostlink}/CardMasterReturn`, ld)
                .then((Response) => {
                    toast("Save Sucessfull")
                    printdata(Response.data)
                    setTimeout(() => {
                        window.location.assign("/CardView")
                    }, 1000);
                })
                .catch(() => {
                    toast.error("Name already exists.")
                    document.getElementById("cmdsave").disabled = false
                })


        } catch (error) {
        }
    }
    const printdata = async (e) => {
        try {
            // const response = await axios.get(`${hostlink}/CardMaster/${e}`);
            if (e) {
                const printContent = generatePrintContent(e);
                const data = `1,${hostlink}/CardMaster/${e},${printContent}`;
                
                // Check if ReactNativeWebView is available
                if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(data);
                    return false;
                }
                // Create a new print window (iframe instead of new window)
                const printWindow = document.createElement('iframe');
                printWindow.style.position = 'absolute';
                printWindow.style.width = '0';
                printWindow.style.height = '0';
                printWindow.style.border = 'none';
                document.body.appendChild(printWindow);
                const doc = printWindow.contentWindow.document;
                doc.open();
                doc.write(printContent);
                doc.close();

                // Set CSS for printing
                const style = doc.createElement('style');
                style.innerHTML = `
                    @media print {
                        body, html {
                            width: 210mm;
                            margin: 2mm; /* Remove margins */
                            padding: 0; /* Remove padding */
                        }
                        * {
                            box-sizing: border-box; /* Include padding and border in element's width and height */
                        }
                        @page {
                            margin: 0; /* Remove margin */
                            size: 210mm 297mm; /* Set size to 80mm width */
                        }
                    }
                `;
                doc.head.appendChild(style);

                // Ensure the content is fully loaded before printing
                printWindow.onload = () => {
                    const contentHeight = doc.body.scrollHeight;
                    console.log("Content height:", contentHeight);
                    printWindow.contentWindow.print();
                    if (contentHeight > 210) {
                        doc.body.innerHTML += '\x1D\x56\x00'; // Manual cut command
                    }
                };
            } else {
                console.error("Empty or invalid response received.");
            }
        } catch (error) {
            console.error("Error fetching or printing data:", error);
        }
    };
    const generatePrintContent = (data) => {
        const billprintline1 = appState?.[0]?.billprintline1 || ""; const billprintline1Html1 = billprintline1 !== null ? `<p style="text-align: center; width:2.7in;">${billprintline1}</p>` : '';
        const billprintline2 = appState?.[0]?.billprintline2 || ""; const billprintline1Html2 = billprintline2 !== null ? `<p style="text-align: center; width:2.7in;">${billprintline2}</p>` : '';
        const billprintline3 = appState?.[0]?.billprintline3 || ""; const billprintline1Html3 = billprintline3 !== null ? `<p style="text-align: center; width:2.7in;">${billprintline3}</p>` : '';
        const billprintline4 = appState?.[0]?.billprintline4 || ""; const billprintline1Html4 = billprintline4 !== null ? `<p style="text-align: center; width:2.7in;">${billprintline4}</p>` : '';
        const billprintline5 = appState?.[0]?.billprintline5 || ""; const billprintline1Html5 = billprintline5 !== null ? `<p style="text-align: center; width:2.7in;">${billprintline5}</p>` : '';
        return `<style>*{margin: 0;padding: 0;box-sizing: border-box;}div{width: 2.7in;}hr{width: 2.7in;}</style><div style="font-family: 'Bahnschrift Condensed'; width: 2.7in;"><p1 style="display: block; margin: 0 auto;text-align: center;"> <h1>${data.cardstatus == 1 ? `Card Recharge` : 'Card Return'}</h1></p1>
        <h1 style="text-align: center;font-Size:${fs_cname}px;">${appState?.[0]?.cname || '-'}</h1>
        ${billprintline1Html1}        ${billprintline1Html2}        ${billprintline1Html3}        ${billprintline1Html4}        ${billprintline1Html5} 
        
        <hr style="borderTop: 1px solid black; width: 2.7in;" /><span style="display:flex;"></p1></span>
        <span style="display:flex; width: 2.7in;"><p1 style="text-align: start; width:50%;">Date : ${data.entrydate ? new Date(data.entrydate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"}</p1><p1 style="text-align: end; width:50%;"> Time : ${data.entrytime}</p1></span>
        <hr style="borderTop: 1px solid black; width: 2.7in;" />
        <span style="display:flex; width: 2.7in;"><p1 style="text-align: start; width:45%;">Card No </p1><p1 style="text-align: start; width:55%;">: ${data.cardno}</p1></span>
        <span style="display:flex; width: 2.7in;"><p1 style="text-align: start; width:45%;">Cust Name </p1><p1 style="text-align: start; width:55%;">: ${data.custname}</p1></span>
        <span style="display:flex; width: 2.7in;"><p1 style="text-align: start; width:45%;">Mob No </p1><p1 style="text-align: start; width:55%;">: ${data.mobno}</p1></span>
        <span style="display:flex; width: 2.7in;"><p1 style="text-align: start; width:45%;">${data.cardstatus == 1 ? `Recharge Amt` : 'Return Amt'} </p1><p1 style="text-align: start; width:55%;">: ${data.rechamt}</p1></span>

                <span style="display:flex; width: 2.7in;"><p1 style="text-align: start; width:45%;">No Of Person </p1><p1 style="text-align: start; width:55%;">: ${data.noofperson}</p1></span>
        <span style="display: flex; flex-wrap: wrap; width: 2.7in;">
    <p1 style="text-align: start; width: 45%;">Remarks</p1>
    <p1 style="text-align: start; width: 55%; white-space: pre-wrap; word-break: break-word;">: ${data.remarks}</p1>
</span>



        <hr style="borderTop: 1px solid black; width: 2.7in;" />
        <span style="display:flex; width: 2.7in;"><p1 style="text-align: center; width:100%;">*** THANK YOU !! ***</p1></span>
        </p1></span><hr style="borderTop: 1px solid black; width: 2.7in;" />      
        </div>`;
    };
    return (
        <div className="container mt-4">
            <div className="card shadow-lg p-4 rounded">
                <h2 className="text-center text-primary mb-4"><u>Card Return</u></h2>

                <div className="row">
                    {/* Scan Card */}
                    <div className="col-md-3">
                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <span style={{ textAlign: "start" }}>
                                <label className="form-label">Scan Card</label>
                            </span>
                            <span className="text-danger">Card Balance: {Number(CardBalance).toFixed(2)}</span>
                        </div>


                        <input
                            type="text"
                            id="cardno"
                            className="form-control border-primary"
                            name="cardno"
                            autoComplete="off"
                            value={cardno}
                            onChange={onChangeValue}
                            onKeyDown={(e) => handleKeyDown(e, mop1AmountRef)}
                            onFocus={() => setSavedata({ ...savedata, cardno: "",custname: "", mobno: "",rechamt:"",pay1amt:0,pay2amt:0 })}
                            onBlur={() => setcallcardbalance(true)}
                        />
                    </div>

                    {/* Customer Name */}
                    <div className="col-md-6">
                        <label className="form-label">Customer Name</label>
                        <input
                            type="text"
                            className="form-control border-primary"
                            name="custname"
                            id="custname"
                            disabled
                            autoComplete="off"
                            value={custname}
                            onChange={onChangeValue}
                            
                            onKeyDown={(e) => handleKeyDown(e, mobileInputRef)}
                            ref={nameInputRef}
                        />
                    </div>

                    {/* Mobile No */}
                    <div className="col-md-3 ">
                        <label className="form-label">Mobile No</label>
                        <input
                            type="text"
                            className="form-control border-primary"
                            name="mobno"
                            autoComplete="off"
                            disabled
                            value={mobno}
                            id="mobno"
                            onChange={onChangeValue}
                            onKeyDown={(e) => handleKeyDown(e, rechargeInputRef)}
                            ref={mobileInputRef}
                        />
                    </div>
                </div>

                {/* Amount Section */}
                <div className="row mt-3">
                    <div className="col-md-3 col-6">
                        <label className="form-label">Returnable Amount</label>
                        <input
                            type="number"
                            className="form-control border-success"
                            name="rechamt"
                            autoComplete="off"
                            id="rechamt"
                            disabled
                            value={rechamt}
                            onChange={onChangeValue}
                            onKeyDown={(e) => handleKeyDown(e, mop1SelectRef)}
                            ref={rechargeInputRef}
                        />
                    </div>
                    <div className="col-md-3 col-6">
                        <label className="form-label">Security Amount</label>
                        <input type="text" className="form-control bg-light" id="secamt" name="secamt" value={secamt} disabled />
                    </div>
                    <div className="col-md-3">
                        <label className="form-label">Card Balance</label>
                        <input type="text" className="form-control bg-light" value={cardbalance} name="cardbalance" id="cardbalance" disabled />
                    </div>

                    <div className="col-md-3 col-6">
                        <label className="form-label">No Of Person</label>
                        <input type="text" className="form-control" id="noofperson" onChange={onChangeValue} name="noofperson" value={noofperson}  />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Remarks</label>
                        <input type="text" className="form-control" onChange={onChangeValue} value={remarks} name="remarks" id="remarks" />
                    </div>

                </div>

                {/* Payment Section */}
                <div className="row mt-3">
                    <div className="col-md-3 col-6">
                        <label className="form-label">MOP - 1</label>
                        <select
                            name="pay1acc"
                            className="form-select border-info"
                            value={pay1acc}
                            id="pay1acc"
                            onChange={onChangeValue}
                            onKeyDown={(e) => handleKeyDown(e, mop1AmountRef)}
                            ref={mop1SelectRef}
                        >
                            <option value="">Choose...</option>
                            {AccCode.map((x) => (
                                <option key={x.id} value={x.id}>{x.accname}</option>
                            ))}
                        </select>
                    </div>

                    <div className="col-md-3 col-6">
                        <label className="form-label">Amount</label>
                        <input
                            type="number"
                            name="pay1amt"
                            className="form-control border-info"
                            autoComplete="off"
                            id="pay1amt"
                            value={pay1amt}
                            onChange={onChangeValue}
                            onKeyDown={(e) => handleKeyDown(e, mop2SelectRef)}
                            ref={mop1AmountRef}
                        />
                    </div>

                    <div className="col-md-3 col-6">
                        <label className="form-label">MOP - 2</label>
                        <select
                            name="pay2acc"
                            className="form-select border-info"
                            value={pay2acc}
                            id="pay2acc"
                            onChange={onChangeValue}
                            onKeyDown={(e) => handleKeyDown(e, mop2AmountRef)}
                            ref={mop2SelectRef}
                        >
                            <option value="">Choose...</option>
                            {cmbAccdataBank.map((x) => (
                                <option key={x.id} value={x.id}>{x.accname}</option>
                            ))}
                        </select>
                    </div>

                    <div className="col-md-3 col-6">
                        <label className="form-label">Amount</label>
                        <input
                            type="number"
                            name="pay2amt"
                            className="form-control border-info"
                            autoComplete="off"
                            value={pay2amt}
                            id="pay2amt"
                            onChange={onChangeValue}
                            onKeyDown={(e) => handleKeyDown(e, saveButtonRef)}
                            ref={mop2AmountRef}
                        />
                    </div>
                </div>

                {/* Buttons */}
                <div className="text-center mt-4">
                    <button className="btn btn-success px-4 mx-2" onClick={(e) => (SaveData(e))} id='cmdsave' ref={saveButtonRef}>Save</button>
                    <button className="btn btn-outline-dark px-4" onClick={()=>{window.location.assign("/CardView")}}>Cancel</button>
                </div>
            </div>
            {isLoading && <Loading />}
            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    );
};

export default CardReturn