import axios from 'axios'

import React, { useEffect, useState, useContext } from 'react'
import hostlink from '../Hostlink/hostlink'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import usercontrol from '../Hostlink/usercontrol'
import Modal from 'react-modal';
import Loading from './Loading'
import { UserLoginDetails } from '../Hostlink/UserLoginDetails'

const CustomerMaster = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [category, setCategory] = useState("item"); // Default category
    const [imageUrl, setImageUrl] = useState("");

    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const fileName = (appState?.[0]?.id || 0) + "it";


 


    const api = "/CustomerMaster";
    const usershopiditemprintname = appState?.[0]?.otheritemprintname || 0;

    const [pagemode, setPagemode] = useState(0)
    const [data, setData] = useState([])
    const [DishType, setDishType] = useState([])
    const [Unitmas, setUnitmas] = useState([])

    const [Itg, setItg] = useState([])
    const [Itc, setItc] = useState([])
    const [Kitchen, setKitchen] = useState([])
    const [messagedata, setMessagedata] = useState({
        deleteid: "",
        deletemsg: ""
    })
    const { deleteid, deletemsg } = messagedata;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = (e, flname) => {
        setMessagedata({ ...messagedata, deleteid: e, deletemsg: flname })
        setIsModalOpen(true);
    };
    const handleCloseModal = () => { setIsModalOpen(false); };
    const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); };

    const [logdetail, setlogdetail] = useState({
        onform: "CustomerMaster",
        operation: "",
        newdesc: "",
        olddesc: "",
        shopid: usershopid,
        usercode: usercontrol.user_code,
        computername: usercontrol.user_computer
    })
    const Closeform = () => {
        window.location.assign("/CustomerMaster")
    }

    const searchData = (e) => {

        const searchValue = document.getElementById("search").value.trim();
        if (searchValue === "") {
            axios.get(`${hostlink}/${usershopid}${api}`)
                .then((Response) => { setData(Response.data) })
                .catch(() => { })
        } else {
            axios.get(`${hostlink}/${usershopid}${api}/search/${document.getElementById("search").value}`)
                .then((Response) => { setData(Response.data) })
                .catch(() => { })
        }
    }


    const SaveData = async (e) => {

        if (!custmob || custmob.length < 10 || custmob.length > 12 || !/^\d{10,12}$/.test(custmob)) {
            alert("Please enter a valid mobile number");
            setIsLoading(false);
            document.getElementById("cmdsave").disabled = false
            document.getElementById("custmob").focus();
            return false
        }


        document.getElementById("cmdsave").disabled = true
        setIsLoading(true);
        try {

            if (pagemode == '0') {
                // Check item name
              
                var ld = { ...savedata, shopid: usershopid, narration2: usershopid + document.getElementById("custmob").value.trim(), narration: usershopid + document.getElementById("custmob").value.trim() }

                await axios.post(`${hostlink}${api}`, ld)
                    .then((Response) => {
                        toast("Save Sucessfull");
                        
                        setTimeout(() => {
                            window.location.assign("/CustomerMaster")
                        }, 1000);
                    })
                    .catch(() => {
                        toast.error("Mobile No already exists.")
                        document.getElementById("cmdsave").disabled = false
                    })
            } else {
                
                var ld = { ...savedata, narration2: usershopid + document.getElementById("custmob").value.trim(), narration: usershopid + document.getElementById("custmob").value.trim() }
                await axios.put(`${hostlink}${api}`, ld)
                    .then((Response) => {
                        toast("Save Sucessfull")
                        setTimeout(() => {
                            window.location.assign("/CustomerMaster")
                        }, 1000);
                    })
                    .catch(() => {
                        toast.error("Mobile No already exists.")
                        setIsLoading(false);
                        document.getElementById("cmdsave").disabled = false
                    })
            }
        } catch (error) {
        }
    }
    const [savedata, setSavedata] = useState({
        id: "",
        custmob : "",custadd2 : "",custadd1 : "",custname : "",custcompanyname : "",custgstno : "",custemail : "",custref : "",custrefmobileno : "",remarks : ""
    })
    const { id, custmob,custadd2,custadd1,custname,custcompanyname,custgstno,custemail,custref,custrefmobileno,remarks } = savedata;

    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,<>?=]/g, '');
        setSavedata({ ...savedata, [name]: sanitizedValue });
    }
    const cmdEdit = (e) => {
        setIsLoading(true);
        setPagemode(1);
        console.log(`${hostlink}${api}/${e}`)
        axios.get(`${hostlink}${api}/${e}`)
            .then((Response) => {
                document.getElementById("pagemode").innerHTML = " Customer [ EDIT ]"
                let x = document.getElementById("frm_controll_Add_Edit")
                x.style.display = "block"
                document.getElementById("custmob").focus();
                document.getElementById("cmdnew").disabled = true

                let y = document.getElementById("dataviewgrid")
                y.style.display = "none"
                setIsLoading(false);
                setSavedata(Response.data);
            })
            .catch((res) => { console.log("Data Not find") })
    }
    const cmdCopy = (e) => {
        setIsLoading(true);
        setPagemode(0);
        axios.get(`${hostlink}${api}/${e}`)
            .then((Response) => {
                Response.data.id = '';
                let y = document.getElementById("dataviewgrid")
                y.style.display = "none"
                var ld = { ...logdetail, newdesc: "Name : " + deletemsg, operation: "New" }
                setlogdetail(ld);
                document.getElementById("pagemode").innerHTML = " Item [ NEW ]"
                let x = document.getElementById("frm_controll_Add_Edit")
                x.style.display = "block"
                document.getElementById("frm_controll_Add_Edit")
                document.getElementById("custmob").focus();
                setSavedata(Response.data);
                setIsLoading(false);
            })
            .catch((res) => { console.log("Data Not find") })
    }
    const cmdNew = () => {

        setPagemode(0);
        let y = document.getElementById("dataviewgrid")
        y.style.display = "none"
        var ld = { ...logdetail, newdesc: "Name : " + deletemsg, operation: "New" }
        setlogdetail(ld);
        document.getElementById("pagemode").innerHTML = " Item [ NEW ]"
        let x = document.getElementById("frm_controll_Add_Edit")
        x.style.display = "block"
        document.getElementById("frm_controll_Add_Edit")
        document.getElementById("custmob").focus();
    }
    const saveLog = (ld) => {
        axios.post(`${hostlink}/logbook`, ld)
        console.log(`${hostlink}/logbook`)
        console.log(ld)
        console.log("Log Saved...")
    }
    const deleteData = async (e) => {
        try {
            await axios.delete(`${hostlink}${api}/${e}`)
            var ld = { ...logdetail, newdesc: "Name : " + deletemsg, operation: "Delete" }
            setlogdetail(ld);
            saveLog(ld)
            toast.success("Delete Sucessfull")
            setInterval(() => {
                window.location.assign("/CustomerMaster")
            }, 1000);
        } catch (error) { }
    }

    useEffect(() => {
        if (usershopid != 0) {


            axios.get(`${hostlink}/${usershopid}${api}`)
                .then((Response) => {
                    console.log("Data Fatched")
                    setData(Response.data)
                })
                .catch((Response) => {
                    console.log("Data Not Fatched")
                })

        }
    }, [usershopid])
    return (
        <div className='p-1'>
            <div id='frm_controll_Add_Edit' className='container shadow rounded' style={{ display: "none" }} ><br />
                <h5 className='font-monospace text-primary'> <i class="fa-solid fa-sitemap"></i> <span id='pagemode' > Customer [ New ]</span></h5>
                {/* <form class="row g-3"> */}
                <div className='row p-2 m-1'>
                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label"> Mobile No</label>
                        <input type="tel" class="form-control" onChange={(e) => { const value = e.target.value.slice(0, 11); if (/^\d{0,11}$/.test(value)) onChangevalue({ target: { name: "custmob", value } }); }} name="custmob" value={custmob} id="custmob" autoComplete='off' />
                    </div>
                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label"> Name </label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='custname' value={custname} class="form-control" id="custname"  autoComplete='off' />
                    </div>
                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label"> Add-1 </label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='custadd2' value={custadd2} class="form-control" id="custadd2"  autoComplete='off' />
                    </div>

                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label"> Add-2 </label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='custadd1' value={custadd1} class="form-control" id="custadd1"  autoComplete='off' />
                    </div>

                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label"> Company Name </label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='custcompanyname' value={custcompanyname} class="form-control" id="custcompanyname"  autoComplete='off' />
                    </div>
                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label"> GST No </label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='custgstno' value={custgstno} class="form-control" id="custgstno"  autoComplete='off' />
                    </div>

                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label"> Email </label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='custemail' value={custemail} class="form-control" id="custemail"  autoComplete='off' />
                    </div>
                   
                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label"> Refrence Name </label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='custref' value={custref} class="form-control" id="custref"  autoComplete='off' />
                    </div>
                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label"> Refrence Mobile No </label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='custrefmobileno' value={custrefmobileno} class="form-control" id="custrefmobileno"  autoComplete='off' />
                    </div>
                    <div class="col-md-6">
                        <label className='font-monospace' class="form-label"> Remarks </label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='remarks' value={remarks} class="form-control" id="remarks"  autoComplete='off' />
                    </div>

                   

                </div>

              
                <div class="col-md-12 text-end">
                    <br />
                    <button onClick={(e) => (SaveData(e))} id='cmdsave' class="btn btn-primary">Save</button>  &nbsp;
                    <button onClick={Closeform} type="submit" class="btn btn-primary">Cancel</button>
                    <br />
                    <br />
                </div>
                {/* </form> */}
            </div>
            {/* ----------------------- */}
            <div className='container p-2' id='dataviewgrid'>
                <div className='row p-1'>
                    <div className='col-md-4 p-1'>
                        <h5 className='font-monospace'><i class="fa-solid fa-user-tie"></i> Customer Master</h5>
                    </div>
                    <div className='col-md-4 rounded' style={{ display: "flex", alignItems: "center", background: "rgb(245,245,246)" }}>
                        &nbsp; <i class="fa-solid fa-magnifying-glass " ></i> &nbsp;&nbsp; <input type="text" class="" onChange={(e) => { searchData(e) }} name="search" id="search" placeholder="Search" style={{ background: "rgb(245,245,246)", outline: "none", border: "0", width: "100%" }} /> <br />
                    </div>
                    <div className='col-md-4 text-end'>
                        {(userType === 0 || appState[1]?.[0]?.itemmaster?.charAt(1) === '1') ? (<button id='cmdnew' onClick={cmdNew} className='btn btn-outline-primary'> <i class="fa-solid fa-plus"></i> &nbsp; New </button>) : null}
                    </div>
                    <p></p>
                    <div style={{ maxHeight: "580px", overflow: "auto" }} className='col-12 '>
                        <table className="table table-hover table-striped" style={{}}>
                            <thead>
                                <tr>
                                    <th scope="col" className="f-size-on-mobile"><span className="hide-on-mobile" >Mobile No</span> <span className="detail-on-mobile">Cust Details</span></th>
                                    <th scope="col" className="hide-on-mobile">Cust Name</th>
                                    <th scope="col" className="hide-on-mobile">Add</th>
                                    <th scope="col" className="hide-on-mobile">Remarks</th>
                                    <th scope="col" className="f-size-on-mobile text-end">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((res, x) => (
                                    <tr>
                                        <td className="f-size-on-mobile" style={{ wordWrap: "break-word" }}>
                                            <b> <span className='show-on-mobile'>Cust Mob : </span>  </b>{res.custmob} <br />
                                            <div className="detail-on-mobile text-start align-self-start" style={{ fontSize: "12px", color: "blue", alignItems: "start" }}>
                                                {res.custname !== 0 && (<>Name : {res.custname} <br /></>)} {res.custadd1 !== '' && (<>Add : {res.custadd1} <br /></>)} {res.remarks !== '' && (<>Remarks : {res.remarks} <br /></>)} 
                                            </div>
                                        </td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.custname}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.custadd2}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.remarks}</td>
                                        <td className="f-size-on-mobile text-end" style={{ wordWrap: "break-word",width: "200px" }}>
                                            {(userType === 0 || appState[1]?.[0]?.itemmaster?.charAt(2) === '1') ? (<Link to={"/CustomerMaster"} onClick={(e) => { cmdEdit(res.id) }} className='btn btn-sm btn-outline-dark'><i class="fa-solid fa-pen-to-square"></i><span className="hide-on-mobile" > Edit</span></Link>) : null}&nbsp; <span className="detail-on-mobile"><br /> <br /></span>
                                            {(userType === 0 || appState[1]?.[0]?.itemmaster?.charAt(3) === '1') ? (<Link onClick={(e) => { handleOpenModal(res.id, res.custname) }} className='btn btn-sm btn-danger'> <i class="fa-solid fa-trash"></i> <span className="hide-on-mobile"> Delete</span></Link>) : null}&nbsp;
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                <div><p>Are you sure you want to delete this <br /> Customer : {deletemsg} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { deleteData(deleteid) }}>Yes</button> <button className="no-button" onClick={handleNoClick}>No</button></div></div>
            </Modal>
            <ToastContainer autoClose={1000}></ToastContainer>
            {isLoading && <Loading />}
        </div>
    )
}
export default CustomerMaster