import axios from 'axios';
import React, { useEffect, useRef, useState, useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import hostlink from '../Hostlink/hostlink';
import { useLocation } from 'react-router-dom';
import { UserLoginDetails } from '../Hostlink/UserLoginDetails'
import Loading from './Loading';
const EntryTrfBillUpdate = () => {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const queryParams = new URLSearchParams(location.search);
    const paybldate = queryParams.get('bldate');
    const payblId = queryParams.get('trfid');
    const payblno = queryParams.get('blno');
    const payblAmt = queryParams.get('blamt');

    const roomCodeRef = useRef(null);
    
    const usershopidref = appState?.[0]?.hotelshopid || 0;
        
    const queryCondition = usershopidref == 0
        ? `${usershopid}`
        : `${usershopidref}`;

        const userroomshopid = appState?.[0]?.roomshopid || 0;
        

    const queryConditionRoomServ = userroomshopid !== 0 ? userroomshopid : usershopid; 
    const [Room, setRoom] = useState([]);
    const [guestdata, setguestdata] = useState({
        c_Gname: "",
        c_GMob: "",
        c_GAdd: "",
        c_Grcode: "",
        c_Grcodename: "",
        c_GCHID: ""
    });
    const { c_Gname, c_GMob, c_GAdd, c_Grcode, c_Grcodename, c_GCHID } = guestdata;

    const onChangevalueRoom = (e, f) => {
        const existingItem = Room.find(item => item[0] === f);
        if (existingItem) {
            Room.forEach(roomdata => {
                if (roomdata[0] === f) {
                    setguestdata({
                        ...guestdata,
                        c_GCHID: roomdata[1],
                        c_Grcodename: f,
                        c_Gname: roomdata[2],
                        c_GMob: roomdata[3],
                        c_GAdd: roomdata[4],
                        c_Grcode: roomdata[5]
                    });
                    toast.success("Confirm Guest Detail.");
                }
            });
        } else {
            setguestdata({
                ...guestdata,
                c_Gname: "",
                c_GMob: "",
                c_GAdd: "",
                c_Grcode: ""
            });
            toast.error("Select Room");
        }
    };
    
    const [trfDetail, settrfDetail] = useState({
        bldate: paybldate,
        blno: payblno,
        guestmob: c_GMob,
        guestname: c_Gname,
        cname: appState?.[0]?.cname || '-',
        chid: 0,
        fromshopid: usershopid,
        entrytime: "",
        roomcode: 0,
        blamt: 0,
        shopid: queryConditionRoomServ
    });
    const paybill = async (e) => {
        document.getElementById("savebutton").disabled = true;
        if (c_Grcode == "0" || c_Grcode === undefined || c_Grcode === '') {
            toast.error("Please Select Room...")
            document.getElementById("savebutton").disabled = false;
            document.getElementById("roomcode").focus();
            return false
        }
        const nameQuery = `update restbilltrfmas set chid = ${c_GCHID},roomcode = ${c_Grcode}, guestname = '${c_Gname}',guestmob = '${c_GMob}' where id = '${payblId}'`;
        const nameResponse = await axios.post(`${hostlink}/api/executeUpdate?sqlQuery=${encodeURIComponent(nameQuery)}`);
        if (nameResponse.data == 'Rows affected: 1') {
                        toast.success("Room change success..")
                        setTimeout(() => {
                            window.location.assign("/TransferBill")
                        }, 1000);
                        return false;
                    }
                    setIsLoading(false);
                    toast.error("Something went wrong..");
                    return false
    };
    const Closeform = () => {
        window.location.assign("/TransferBill")
    }
    useEffect(() => {
        if(usershopid!=0){
            console.log(`${hostlink}/${queryConditionRoomServ}/roomservice`);
        axios.get(`${hostlink}/${queryConditionRoomServ}/roomservice`)
            .then((Response) => {
                setRoom(Response.data);
                console.log(Response.data)
            })
            .catch(() => {
                console.log("Data Not Fetched");
            });
        }
    }, [usershopid]);

    return (
        <div>
            <div className='container-fluid p-1'>
                <div className='row py-3 shadow rounded mb-4'
                    style={{ backgroundImage: 'linear-gradient(to right, #4DD6F4, #ff9999, #4DD6F4)', color: 'white' }}>
                    <div className="col-md-3 align-self-center">
                        <h4>
                            <i className="fa-solid fa-money-bill-transfer"></i> Transfer Bill change Room
                        </h4>
                    </div>
                    <div className="col-4 text-end align-self-center">
                        <label htmlFor="inputState" className="form-label">Select Room</label>
                    </div>
                    <div className="col-2 align-self-center">
                        <select id="roomcode" ref={roomCodeRef}
                            onChange={(e) => onChangevalueRoom(e.target.value, e.target.selectedOptions[0].text)}
                            className="form-select rounded-pill"
                            style={{ width: "200px" }}>
                            <option selected key={0} value={0}>Choose...</option>
                            {Room.map((x) => (
                                <option key={x[1]} value={x[1]} title={x[0]}>
                                    {x[0]}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="row bg-dark align-self-center text-center text-bg-dark border border-1 rounded shadow p-3 mb-4">
                    <div className="col-md-4">Guest Name: {c_Gname}</div>
                    <div className="col-md-4">Guest Mob: {c_GMob}</div>
                    <div className="col-md-4">Guest Add: {c_GAdd}</div>
                </div>

                <div className="row justify-content-center align-items-center text-center border border-1 rounded p-4 mt-4 shadow-lg bg-white">
                    <div className="col-md-8">
                        <table className="table table-borderless">
                            <tbody>
                                <tr>
                                    <td className="text-start fw-bold fs-5">Bill Date</td>
                                    <td className="fs-5">:</td>
                                    <td className="text-end fs-5">{paybldate ? new Date(paybldate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</td>
                                </tr>
                                <tr>
                                    <td className="text-start fw-bold fs-5">Bill No</td>
                                    <td className="fs-5">:</td>
                                    <td className="text-end fs-5">{payblno}</td>
                                </tr>
                                <tr>
                                    <td className="text-start fw-bold fs-5">Bill Amount</td>
                                    <td className="fs-5">:</td>
                                    <td className="text-end fs-5">{Number(payblAmt).toFixed(2)}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="d-flex justify-content-center mt-5">
                            <button id='savebutton' onClick={() => { paybill(); }} className="btn btn-info me-4 px-5 py-3 rounded-pill shadow">Confirm Change</button>
                            <button onClick={Closeform} className="btn btn-outline-dark px-5 py-3 rounded-pill shadow"> Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            
            <ToastContainer autoClose={1000}></ToastContainer>
            {isLoading && <Loading />}
        </div>
    );
};

export default EntryTrfBillUpdate;
