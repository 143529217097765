import { UserLoginDetails } from '../Hostlink/UserLoginDetails'
import React, { useEffect, useState, useContext, useRef } from 'react'
import axios from 'axios';
import hostlink from '../Hostlink/hostlink';
import * as XLSX from 'xlsx';
import { PDFDownloadLink, PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import Loading from '../Component/Loading';

const Rpt_Stock_By_Purchase = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const usershopdeptcode = appState?.[0]?.deptcode || 0;
    const usershopidref = appState?.[0]?.hotelshopid || 0;

    const queryCondition = usershopidref == 0
        ? `${usershopid}`
        : `${usershopidref}`;
    const [RoomType, setRoomType] = useState([]);
    const [Room, setRoom] = useState([]);
    const [deptdata, setdeptdata] = useState([])
    const [data, setData] = useState([]);
    const today = new Date();

    const [frmdata, setfrmdata] = useState({
        fromdate: today.toISOString().split('T')[0],
        todate: today.toISOString().split('T')[0],
        deptcode: usershopdeptcode,
        status: 0
    });
    const [totalSums, setTotalSums] = useState({});

    const { fromdate, todate, status, deptcode } = frmdata;
    const tableRef = useRef(null);
    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setfrmdata({ ...frmdata, [name]: sanitizedValue });
    };
    const loaddata = async (e) => {
        setIsLoading(true)
        try {

            //         const sqlQuery = `select 
            // itname,(select unitname from unitmas where id = itemmas.unitcode) AS Item_Unit,sum(qty) as In_Stock,rate,rawcode,
            // (select sum(qty) from stktrfmas where rawcode = purcmas.rawcode and rate = purcmas.rate and shopid = ${usershopid}) as out_Stock,
            // (SELECT COALESCE(SUM(qty), 0) 
            //  FROM purcmas AS opening 
            //  WHERE opening.rawcode = purcmas.rawcode 
            //    AND opening.rate = purcmas.rate 
            //    AND opening.partybldate < '${fromdate}' 
            //    AND opening.shopid = ${usershopid}) AS Opening_Stock
            // from purcmas,itemmas  where 1=1 and purcmas.rawcode = itemmas.id and purcmas.partybldate between '${fromdate}' and '${todate}' 
            // group by rate,rawcode order by purcmas.rawcode`;


            const sqlQuery = `
   SELECT 
    i.itname,
    u.unitname AS Item_Unit,
    SUM(p.qty) AS In_Stock,
    p.rate,
    p.rawcode,

    -- Out Stock (Before From Date)
    COALESCE(out_op.qty, 0) AS out_Stock_Op,

    -- Out Stock (Between From Date and To Date)
    COALESCE(out_bt.qty, 0) AS out_Stock,

    -- Opening Stock (Before From Date)
    COALESCE(op_stock.qty, 0) AS Opening_Stock,

    -- Received Stock (Between From Date and To Date)
    COALESCE(instk_stock.qty, 0) AS InStk_Stock  

FROM purcmas p
JOIN itemmas i ON p.rawcode = i.id
LEFT JOIN unitmas u ON i.unitcode = u.id

-- Out Stock (Before From Date)
LEFT JOIN (
    SELECT rawcode, rate, SUM(qty) AS qty
    FROM stktrfmas 
    WHERE shopid IN (${usershopid}, ${usershopidref})  and fromdeptcode in (${usershopdeptcode})
      AND entrydate < '${fromdate}'
    GROUP BY rawcode, rate
) out_op ON p.rawcode = out_op.rawcode AND p.rate = out_op.rate

-- Out Stock (Between From Date and To Date)
LEFT JOIN (
    SELECT rawcode, rate, SUM(qty) AS qty
    FROM stktrfmas 
    WHERE shopid IN (${usershopid}, ${usershopidref})  and fromdeptcode in (${usershopdeptcode})
      AND entrydate BETWEEN '${fromdate}' AND '${todate}'
    GROUP BY rawcode, rate
) out_bt ON p.rawcode = out_bt.rawcode AND p.rate = out_bt.rate

-- Opening Stock (Before From Date)
LEFT JOIN (
    SELECT rawcode, rate, SUM(qty) AS qty
    FROM purcmas 
    WHERE shopid IN (${usershopid}, ${usershopidref})  and deptcode in (${usershopdeptcode})
      AND partybldate < '${fromdate}'
    GROUP BY rawcode, rate
) op_stock ON p.rawcode = op_stock.rawcode AND p.rate = op_stock.rate

-- Received Stock (Between From Date and To Date)
LEFT JOIN (
    SELECT rawcode, rate, SUM(qty) AS qty
    FROM purcmas 
    WHERE shopid IN (${usershopid}, ${usershopidref})   and deptcode in (${usershopdeptcode})
      AND partybldate BETWEEN '${fromdate}' AND '${todate}'
    GROUP BY rawcode, rate
) instk_stock ON p.rawcode = instk_stock.rawcode AND p.rate = instk_stock.rate

WHERE p.partybldate <= '${todate}' 
  AND p.shopid IN (${usershopid}, ${usershopidref}) and p.deptcode in (${usershopdeptcode})
GROUP BY i.itname, u.unitname, p.rate, p.rawcode
ORDER BY p.rawcode;

    `;

            // console.log(sqlQuery);

            const encodedSqlQuery = encodeURIComponent(sqlQuery);
            // console.log(`${hostlink}/api/executeQuery?sqlQuery=${encodedSqlQuery}`)
            const billResponse = await axios.post(`${hostlink}/api/executeQueryNew`, { sqlQuery });
            const billData = billResponse.data;
            setData(billResponse.data)
            // console.log(billResponse.data)
            setIsLoading(false)
        } catch (error) {
            console.log("Data Not Found...");
            setIsLoading(false)
        }
    };
    useEffect(() => {
        axios.get(`${hostlink}/${queryCondition}/deptmas`)
            .then((Response) => { setdeptdata(Response.data) })
    }, [usershopid])

    const exportToExcel = () => {
        const ws = XLSX.utils.table_to_sheet(tableRef.current);
        const boldAndColorStyle = {
            font: { bold: true },
            fill: { fgColor: { rgb: "FFFF00" } }
        };
        const headerRow = XLSX.utils.decode_range(ws['!ref']).e.c;
        for (let c = 0; c <= headerRow; c++) {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c });
            if (ws[cellAddress].v === 'Sr No') {
                ws[cellAddress].s = boldAndColorStyle;
                for (let r = 1; r <= XLSX.utils.decode_range(ws['!ref']).e.r; r++) {
                    const dataCellAddress = XLSX.utils.encode_cell({ r, c });
                    if (ws[dataCellAddress]) {
                        ws[dataCellAddress].s = boldAndColorStyle;
                    }
                }
                break;
            }
        }
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, 'Stock By Purchase.xlsx');
    };

    return (
        <div className='m-1'>
            <div className='container p-3 shadow rounded' style={{ transition: 'box-shadow 0.3s' }}>
                <div className='row'>
                    <div className='col-md-6 align-self-center'>
                        <h5 className='font-monospace text-primary'> <i class="fa-solid fa-cart-shopping"></i> <span id='pagemode'> Stock By Purchase</span></h5>
                    </div>
                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="fromdate" className="form-label">From Date:</label>
                    </div>
                    <div className='col-md-2'>
                        <input type="date" id="fromdate" name='fromdate' onChange={(e) => { onChangevalue(e) }} value={fromdate} className="form-control" />
                    </div>
                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="todate" className="form-label">To Date:</label>
                    </div>
                    <div className='col-md-2'>
                        <input type="date" id="todate" name='todate' onChange={(e) => { onChangevalue(e) }} value={todate} className="form-control" />
                    </div>
                    {/* <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="roomtype" className="form-label">Department:</label>
                    </div>
                    <div className="col-2 text-start-0 align-self-center">
                            <select id="deptcode" name='deptcode' value={deptcode} onChange={(e) => { onChangevalue(e) }} class="form-select">
                                
                                {deptdata.map((x) => {
                                    return (
                                        <option key={x.id} value={x.id}> {x.deptname} </option>
                                    )
                                })}
                            </select>
                        </div> */}
                    <div className='col-md-12 align-self-center text-end mt-2'>
                        {/* <button onClick={() => { loaddata(0); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-magnifying-glass-chart"></i> Department-wise </button> */}
                        <button onClick={() => { loaddata(1); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-magnifying-glass-chart"></i> Show</button>
                        <button onClick={() => { exportToExcel(); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-table"></i> Excel</button>

                    </div>
                </div>
            </div>


            <br />
            {/* maxHeight: "400px", */}
            <div className='container-fluid' style={{ overflow: "auto" }}>
                <table className="table table-hover table-striped " ref={tableRef}>

                    <thead className='text-center' >
                        <tr >
                            <th scope="col">Sr No</th>
                            <th className='text-start' scope="col">Name of The Product</th>
                            <th className='text-start' scope="col">Unit</th>
                            <th className='text-end' scope="col">Rate</th>
                            <th className='text-end' scope="col">Opening</th>
                            <th className='text-end' scope="col">Received Quantity</th>
                            <th className='text-end' scope="col">Received Value</th>
                            <th className='text-end' scope="col">Sale Quantity</th>
                            <th className='text-end' scope="col">Sale Value</th>
                            <th className='text-end' scope="col">Cl. Quantity</th>
                            <th className='text-end' scope="col">Cl. Value</th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {data.map((res, x) => (
                            <tr key={x}>
                                <td>{x + 1}</td>
                                <td className='text-start'>{res[0]}</td>
                                <td className='text-start'>{res[1]}</td>
                                <td className='text-end'>{Number(res[3]).toFixed(2)}</td>
                                <td className='text-end'>{Number(res[7] - res[5]).toFixed(2)}</td>
                                <td className='text-end'>{res[8]}</td>
                                <td className='text-end'>{Number(Number(res[3]) * Number(res[8]).toFixed(2))}</td>
                                <td className='text-end'>{res[6]}</td>
                                <td className='text-end'>{Number(Number(res[6]) * Number(res[3])).toFixed(2)}</td>
                                <td className='text-end'>{Number((Number(res[8]) - Number(res[6])) + Number(res[7] - res[5])).toFixed(2)}</td>
                                <td className='text-end'>{Number(Number((Number(res[8]) - Number(res[6])) + Number(res[7] - res[5])).toFixed(2) * res[3]).toFixed(2)}</td>


                            </tr>
                        ))}
                        <tr class="table-info">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {isLoading && <Loading />}
        </div>
    )
}


export default Rpt_Stock_By_Purchase